import React from 'react'
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles
} from '@material-ui/core'
import { red, green } from '@material-ui/core/colors'
import { ExpandMore, Check, Close, Navigation } from '@material-ui/icons'
import Signal from '../Utils/Signal'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  dataGIO: {
    display: 'block'
  },
  timestamp: {
    marginTop: 10
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  content: {
    flex: 1
  },
  update: {
    padding: 10,
    marginTop: 'auto'
  }
}))

const BarraDatos = ({ dataGIO, configs }) => {
  const ubicacion = configs ? configs['ubicacion'] : []
  const parametros = configs ? configs['parametros'] : []
  const tecnicos = configs ? configs['tecnicos'] : []
  const horometros = configs ? configs['horometros'] : []
  const listAlarmas = Boolean(dataGIO) && Boolean(dataGIO.alarmas) && Array.isArray(dataGIO.alarmas)
    ? dataGIO.alarmas.map(i => i.Variable)
    : []
  const classes = useStyles()
  const showValue = (value) => {
    if (typeof value === "boolean") {
      return value ? <Check style={{color: green[500]}} /> : <Close style={{color: red[500] }} />
    } else if (typeof value === 'string') {
      return value || '--'
    } else {
      return value || '--'
    }
  }
  return <div id="barra-datos" className={classes.root}>
    <div className={classes.container}>
      <div className={classes.content}>
        {
          Boolean(dataGIO) && dataGIO.isOffline && <Typography
            variant="subtitle1"
            style={{
              color: red['500'],
              fontWeight: 'bold'
            }}
          >
            Actualmente fuera de línea
          </Typography>
        }
        <Typography variant="h6">
          <strong>Código:</strong> {dataGIO.codigo}
        </Typography>
        <Typography variant="subtitle1">
          <strong>{
            dataGIO.operacion && dataGIO.isStoped
              ? 'Motivo de parada: '
              : 'Operación: ' }
          </strong>{
            dataGIO.operacion
            ? `${dataGIO.operacion.nombre_operacion}`
            : '--'
            }
        </Typography>
        <Typography variant="subtitle1">
          <strong>Operador: </strong>{dataGIO.nombre_operador || '--'}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Frente: </strong>{dataGIO.frente || '--'}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Tiempo transcurrido: </strong>{dataGIO.operacion ? dataGIO.operacion.tiempo_transcurrido : '--'}
        </Typography>
        {
          parametros.filter(i => dataGIO.hasOwnProperty(i.variable) &&
            listAlarmas.includes(i.variable))
          .map(i =>
            <Typography
              variant="subtitle1"
              style={{
                color: listAlarmas.includes(i.variable) ? red[500] : '#000'
              }}
            >
              <strong>{i.label}: </strong>{dataGIO[i.variable] || '--'} {i.unit}
            </Typography>
          )
        }
        {
          listAlarmas.includes('señal_calidad') && <Typography variant="subtitle1">
            <strong>Intensidad de señal: </strong>{
              dataGIO.hasOwnProperty('señal_calidad')
              ? <Signal value={dataGIO['señal_calidad']} />
              : '--'
            }
          </Typography>
        }
        {
          tecnicos.filter(i => i.variable != 'señal_calidad')
          .filter(i => dataGIO.hasOwnProperty(i.variable) &&
            listAlarmas.includes(i.variable))
          .map(i =>
            <Typography
              variant="subtitle1"
              component="p"
              style={{
                color: red[500]
              }}
            >
              <strong>{i.label}: </strong>{showValue(dataGIO[i.variable])}
            </Typography>
          )
        }
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.heading}>Ubicación</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.dataGIO}>
            {
              ubicacion.filter(i => dataGIO.hasOwnProperty(i.variable))
              .map(i =>
                <Typography key={i.label} variant="subtitle1">
                  <strong>{i.label}: </strong>{showValue(dataGIO[i.variable])}
                </Typography>
              )
            }
            {
              (Boolean(dataGIO['latitud']) && Boolean(dataGIO['longitud'])) &&
              <Button
                target="_blank"
                href={`http://www.google.com/maps/place/${dataGIO['latitud']},${dataGIO['longitud']}`}
                startIcon={<Navigation />}
                color="primary"
              >
                Navegar
              </Button>
            }
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.heading}>Parametros de operación</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.dataGIO}>
            {
              parametros.filter(i => dataGIO.hasOwnProperty(i.variable)).map(i =>
                <Typography
                  key={i.label}
                  variant="subtitle1"
                  style={{
                    color: listAlarmas.includes(i.variable) ? red[500] : '#000'
                  }}
                >
                  <strong>{i.label}: </strong>{showValue(dataGIO[i.variable])} {i.unit}
                </Typography>
              )
            }
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.heading}>Horómetros</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.dataGIO}>
            {
              horometros.filter(i => dataGIO.hasOwnProperty(i.variable)).map(i =>
                <Typography
                  key={i.label}
                  variant="subtitle1"
                >
                  <strong>{i.label}: </strong>{showValue(dataGIO[i.variable])} {i.unit}
                </Typography>
              )
            }
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography className={classes.heading}>Parametros técnicos</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.dataGIO}>
            <Typography variant="subtitle1">
              <strong>Intensidad de señal: </strong>{
                dataGIO.hasOwnProperty('señal_calidad')
                ? <Signal value={dataGIO['señal_calidad']} />
                : '--'
              }
            </Typography>
            {
              tecnicos.filter(i => i.variable != 'señal_calidad')
              .filter(i => dataGIO.hasOwnProperty(i.variable))
              .map(i =>
                <Typography
                  key={i.label}
                  variant="subtitle1"
                  style={{
                    color: listAlarmas.includes(i.variable) ? red[500] : '#000'
                  }}
                >
                  <strong>{i.label}: </strong>{showValue(dataGIO[i.variable])} {i.unit}
                </Typography>
              )
            }
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={classes.update}>
        <Typography variant="caption" className={classes.update}>
          <strong> Última actualización: </strong>{dataGIO.fecha_actualizacion || '--'}
        </Typography>
      </div>
    </div>
  </div>
}

export default BarraDatos
