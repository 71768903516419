import { action, thunk } from "easy-peasy"
import http from "../Utils/http"
import dayjs from "dayjs"

export default {
  zafraActual: null,
  setZafraActual: action((state, payload) => {
    state.zafraActual = payload
  }),
  getZafraActual: thunk(async (actions, _) => {
    const now = dayjs()
    const data = await http.get('/maestros/catalogo/zafras')
    const zafras = data.map(i => ({...i, FechaInicio: dayjs(i.FechaInicio), FechaTermina: dayjs(i.FechaTermina)}))
    const actual = zafras.find(i => now.isAfter(i.FechaInicio) && now.isBefore(i.FechaTermina))
    if (actual) {
      actions.setZafraActual(actual)
    } else {
      const fechaActual = new Date();
      actions.setZafraActual({Codigo: fechaActual.getFullYear()})
    }
  })
}
