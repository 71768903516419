import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Paper
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Alert } from '@material-ui/lab'
import { toast } from 'react-toastify'
import http from '../../Utils/http'
import SelectVariable from '../Selects/SelectVariable'
import ParamEdit from './ParamEdit'
import ParametroStringEdit from './ParametroStringEdit'
import ParametroBoolEdit from './ParametroBoolEdit'
import LoadingBackdrop from '../Utils/LoadingBackdrop'

const initParam = {
  TieneAlerta: false,
  Inferior: '',
  InferiorIncluyente: true,
  Superior: '',
  SuperiorIncluyente: false,
  Parametro: '',
  Fijo: null,
  Icono: 'marker1.svg'
}
const initError = {
  Inferior: false,
  Superior: false,
  Parametro: false
}
const initParamStr = {
  TieneAlerta: false,
  Parametro: '',
  Valor: '',
  Icono: 'marker1.svg'
}
const initErrorStr = {
  Valor: false,
  Parametro: false
}
const initParamBool = {
  TieneAlerta: false,
  Parametro: '',
  Valor: false,
  Icono: 'marker1.svg'
}
const initErrorBool = {
  Parametro: false
}

const VariablesEdit = ({ onClose, maquinaria }) => {
  const [vars, setVars] = useState([])
  const [variable, setVariable] = useState(null)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listParams, setListParams] = useState([])
  const validate = () => {
    const paramsTmp = [...listParams]
    const results = []
    const tipoFunc = {
      'num': validateParam,
      'str': validateParamStr,
      'bool': validateParamBool
    }
    const tipoVariable = variable.type
    for (let i=0; i<paramsTmp.length; i++) {
      const [result, errors] = tipoFunc[tipoVariable](paramsTmp[i].value)
      results.push(result)
      paramsTmp[i].errors = errors
    }
    setListParams(paramsTmp)
    return results.every(i => i)
  }
  const validateParam = (param) => {
    let sup = false, inf = false, nom = false
    if (!param.Inferior.trim().length) inf = true
    if (!param.Superior.trim().length) sup = true
    if (!param.Parametro.trim().length) nom = true
    const errors = {
      Inferior: inf,
      Superior: sup,
      Parametro: nom
    }
    const result = ![sup, inf, nom].every(i => i)
    return [result, errors]
  }
  const validateParamStr = (param) => {
    let val = false, nom = false
    if (!param.Valor.trim().length) val = true
    if (!param.Parametro.trim().length) nom = true
    const errors = {
      Valor: val,
      Parametro: nom
    }
    const result = ![val, nom].every(i => i)
    return [result, errors]
  }
  const validateParamBool = (param) => {
    let nom = false
    if (!param.Parametro.trim().length) nom = true
    const errors = {
      Parametro: nom
    }
    const result = ![nom].every(i => i)
    return [result, errors]
  }

  const changeParamItem = (key, value, index) => {
    const items = [...listParams]
    const item = {...items[index]}
    item.value[key] = value
    items.splice(index,1,item)
    setListParams(items)
  }
  const pushParam = () => {
    const items = [...listParams]
    if (variable.type === 'num') {
      items.push({
        value: {...initParam},
        errors: {...initError}
      })
    }
    if (variable.type === 'str') {
      items.push({
        value: {...initParamStr},
        errors: {...initErrorStr}
      })
    }
    if (variable.type === 'bool') {
      items.push({
        value: {...initParamBool},
        errors: {...initErrorBool}
      })
    }
    setListParams(items)
  }
  const removeItem = (itemIndex) => {
    const items = listParams.filter((x, idx) => idx !== itemIndex)
    setListParams(items)
  }
  const saveParametros = async () => {
    if (!listParams.length) {
      toast.error('Agrega los parametro de operación')
      return
    }
    if (validate()) {
      try {
        setSaving(true)
        const items = listParams.map(i => i.value)
          .map(i => {
            if (variable.type === 'num') {
              return {
                ...i,
                Superior: parseFloat(i['Superior']),
                Inferior: parseFloat(i['Inferior'])
              }
            } else {
              return { ...i }
            }
          })
        const key = variable.variable
        const body = {
          Codigo: maquinaria.Codigo,
          ...vars
        }
        body[key] = items
        await http.post(`/maestros/alarma/${maquinaria.Codigo}`, body)
        await getVariables(maquinaria.Codigo)
        toast.success('Registro guardado')
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setSaving(false)
      }
    }
  }
  const getVariables = async (codigo) => {
    try {
      setLoading(true)
      const body = await http.get(`/maestros/alarma/${codigo}`)
      setVars(body)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (variable) {
      if (vars && vars.hasOwnProperty(variable.variable)) {
        const items = vars[variable.variable]
          .map(i => {
            if (variable.type === 'num') {
              return {
                ...i,
                Superior: String(i['Superior']),
                Inferior: String(i['Inferior'])
              }
            } else {
              return { ...i }
            }
          })
          .map(i => ({
            value: {...i},
            errors: {...initError}
          }))
        setListParams(items)
      } else {
        setListParams([])
      }
    }
  }, [variable, vars])
  useEffect(() => {
    getVariables(maquinaria.Codigo)
  }, [])
  return <>
    <DialogTitle>Parametros por variable</DialogTitle>
    <DialogContent>
      <SelectVariable
        label="Variable"
        value={variable}
        onChange={(_, val) => setVariable(val)}
      />
      <Paper
        style={{
          height: '40vh',
          width: '100%',
          overflowY: 'auto',
          padding: 10,
          marginTop: 10
        }}
      >
        {
          !variable && <Alert severity="info" variant="filled">Selecciona una variable</Alert>
        }
        {
          (variable && !listParams.length) &&
            <Alert severity="info" variant="filled">
              Agrega los parametros para la variable
            </Alert>
        }
        {
          (variable && variable.type === 'num') && listParams.map((i, idx) => <ParamEdit
            key={idx}
            index={idx}
            value={i.value}
            onChange={changeParamItem}
            errors={i.errors}
            onDelete={() => removeItem(idx)}
          />)
        }
        {
          (variable && variable.type === 'str') && listParams.map((i, idx) => <ParametroStringEdit
            key={idx}
            index={idx}
            value={i.value}
            onChange={changeParamItem}
            errors={i.errors}
            onDelete={() => removeItem(idx)}
          />)
        }
        {
          (variable && variable.type === 'bool') && listParams.map((i, idx) => <ParametroBoolEdit
            key={idx}
            index={idx}
            value={i.value}
            onChange={changeParamItem}
            errors={i.errors}
            onDelete={() => removeItem(idx)}
          />)
        }
      </Paper>
      <Box display="flex" justifyContent="space-around" style={{marginTop: 10}}>
        <Button
          disabled={!Boolean(variable)}
          color="primary"
          variant="contained"
          onClick={pushParam}
          style={{width: '40%', background: green[500]}}
        >
          Agregar parametro
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={saveParametros}
          style={{width: '40%'}}
        >
          Guardar
        </Button>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
    <LoadingBackdrop loading={loading} />
    <LoadingBackdrop loading={saving} />
  </>
}

export default VariablesEdit