import { isSignedIn, signOut } from "./auth";
import axios from "axios";

// let BASE_API = config.env[config.env.environment].apiGateway.URL;
let BASE_API = `${process.env.REACT_APP_API}/`;
export const httpRequest = axios.create({
  baseURL: BASE_API,
  // timeout: 5000,
});
httpRequest.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    signOut()
  }
  return error;
})

class Api {
  state = {
    token: {},
  };

  async baseApi() {
    return BASE_API;
  }

  async Login(user, pass, type) {
    const fetchEndpoint = `${BASE_API}auth`;
    var details = {
      userName: user,
      password: pass,
      auth_type: type,
      grant_type: "password",
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const query = await fetch(fetchEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    });

    const request = await query.json();
    return request;
  }

  //**METODOS GENERALES***********//

  getUrlWithParams(uri, params) {
    const urlParameters =
      params != null
        ? "?" +
        Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&")
        : "";

    return `${uri}${urlParameters}`;
  }

  async get(uri, params = null) {
    let auth = await isSignedIn();

    const endPoint = params ? this.getUrlWithParams(uri, params) : uri;

    const request = httpRequest.get(endPoint, {
      headers: { Authorization: "Bearer " + auth.token },
    });

    return new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data) {
          resolve(response.data);
        }
        resolve(response);
      }).catch((error) => {
        // errorHandler(error.response);
        if (error.response)
          reject(error.response.data);
        reject(error);
      });
    });
  }

  async post(uri, formData, isFormData=false) {
    //Usamos Axios
    let auth = await isSignedIn();
    const request = httpRequest.post(uri, formData, {
      headers: { Authorization: "Bearer " + auth.token,
                "Content-Type": isFormData ? "multipart/form-data" : "application/json" },
    });

    return new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data) {
          resolve(response.data);
        }
        resolve(response);
      }).catch((error) => {
        // errorHandler(error.response);
        if (error.response)
          reject(error.response.data);
        reject(error);
      });
    });
  }

  async put(uri, formData, isFormData=false) {
    //Usamos Axios
    let auth = await isSignedIn();

    const request = httpRequest.put(uri, formData, {
      headers: { Authorization: "Bearer " + auth.token,
        "Content-Type": isFormData ? "multipart/form-data" : "application/json" },
    });

    return new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data) {
          resolve(response.data);
        }
        resolve(response);
      }).catch((error) => {
        // errorHandler(error.response);
        if (error.response)
          reject(error.response.data);
        reject(error);
      });
    });
  }

  async delete(uri) {
    //Usamos Axios
    let auth = await isSignedIn();

    const request = httpRequest.delete(uri, {
      headers: { Authorization: "Bearer " + auth.token },
    });

    return new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data) {
          resolve(response.data);
        }
        resolve(response);
      }).catch((error) => {
        // errorHandler(error.response);
        if (error.response)
          reject(error.response.data);
        reject(error);
      });
    });
  }

  async realizarPeticionPostPut(fetchEndpoint, sendData, requestType) {
    fetchEndpoint = `${BASE_API}${fetchEndpoint}`;

    let auth = await isSignedIn();

    var formBody = [];
    for (var property in sendData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(sendData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const query = await fetch(fetchEndpoint, {
      method: requestType,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: "Bearer " + auth.token,
      },
      body: formBody,
    });

    const request = await this.processResponse(query);
    return request;
  }

  ////////////////////////Generales

  async processResponse(response) {
    const statusCode = response.status;
    let data;
    try {
      data = response.json();
    } catch (e) {
      data = {};
    }
    let promiseResponse = await Promise.all([statusCode, data]).then((res) => {
      return ({
        statusCode: res[0],
        data: res[1],
      })
    });

    if (promiseResponse.statusCode === 401) {
      await signOut();
    }

    return promiseResponse;
  }
}

export default new Api();
