import axios from 'axios'
import { toast } from 'react-toastify';

const base = process.env.REACT_APP_AWS
const http = axios.create({
  baseURL: base
})
http.interceptors.request.use(
  config => {
    const userData = JSON.parse(localStorage.getItem('authData'))
    if (userData) {
      config.headers.AuthorizationToken = userData ? `Bearer ${userData.Token}` : ''
    }
    return config;
  },
  error => Promise.reject(error)
);
http.interceptors.response.use(
  response => {
    if (Math.floor(response.data.statusCode / 100) == 2) {
      return response.data.body
    }
    if (response.data.statusCode == 401) {
      toast.error('Tu sesión ha caducado 🔒')
      const urlBase = window.location.protocol + "//" + window.location.host
      if (urlBase != window.location.href) {
        window.location.href = urlBase
      }
    }
    return Promise.reject(response.data)
  },
  error => {
    if(error){
      return Promise.reject(error)
    }
  }
)

export default http