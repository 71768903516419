import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  // ChartDataLabels
)


const StackedBarChart = ({ data, title }) => {
  const options = {
    plugins: {
      datalabels: {
        display: true,
      },
      title: {
        display: true,
        text: title
      },
      responsive: true
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      }
    }
  }
  return <Bar options={options} data={data} />;
}

export default StackedBarChart
