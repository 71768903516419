import React, { useState, useEffect } from 'react'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import LoadingButton from '../Utils/LoadingButton'
import { toast } from 'react-toastify'
import SelectRol from './SelectRol'
import { getIdentity } from '../../Utils/auth'

const UsuarioEdit = ({values=null, onClose, onRefresh, onSubmit}) => {
  const [form, setForm] = useState({
    Nombre: '',
    UsuarioAD: '',
    DPI: '',
    TienePassword: false,
    Password: '',
    Rol: null,
    Email: ''
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [rol, setRol] = useState(null)
  const isUpdate = Boolean(values)
  const handleChange = (ev) => {
    setForm({
      ...form,
      [ev.target.name]: ev.target.value
    })
  }
  const validate = () => {
    let tempErrors = {};

    if (!form.Nombre) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    if (!form.TienePassword && !form.UsuarioAD) {
      tempErrors.UsuarioAD = 'Este campo es requerido';
    }
    if (!form.Rol) {
      tempErrors.Rol = 'Este campo es requerido';
    }
    if (!form.Email) {
      tempErrors.Email = 'Este campo es requerido';
    } else if (!/\S+@\S+\.\S+/.test(form.Email)) {
      tempErrors.Email = 'El correo electrónico no es válido';
    }
    if (!form.DPI) {
      tempErrors.DPI = 'Este campo es requerido';
    } else if (!/^\d{13}$/.test(form.DPI)) {
      tempErrors.DPI = 'El DPI no es válido';
    }
    if (!isUpdate) {
      if (form.TienePassword && !form.Password) {
        tempErrors.Password = 'Este campo es requerido';
      } else if (form.TienePassword && form.Password.length < 6) {
        tempErrors.Password = 'La contraseña debe tener al menos 6 caracteres';
      }
    } else {
      if (form.TienePassword && form.Password && form.Password.length < 6) {
        tempErrors.Password = 'La contraseña debe tener al menos 6 caracteres';
      }
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const submit = async () => {
    try {
      setLoading(true)
      if (validate()) {
        const body = {
          ...form,
          IdRol: form.Rol.IdRol
        }
        delete body.Rol
        await onSubmit(body)
        onRefresh()
        toast.success('Registro guardado')
      }
      // onRefresh()
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (values) {
      setForm({
        Nombre: values.Nombre,
        DPI: values.DPI,
        Email: values.Email,
        TienePassword: values.TienePassword,
        UsuarioAD: values.UsuarioAD,
        Password: ''
      })
      setRol(values.IdRol)
    }
  }, [values])
  return <>
    <DialogTitle>Crear Usuario</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre"
        name="Nombre"
        value={form.Nombre}
        onChange={handleChange}
        error={Boolean(errors.Nombre)}
        helperText={errors.Nombre}
      />
      <TextField
        fullWidth
        label="DPI"
        name="DPI"
        value={form.DPI}
        onChange={handleChange}
        error={Boolean(errors.DPI)}
        helperText={errors.DPI}
      />
      <TextField
        fullWidth
        label="Correo electrónico"
        name="Email"
        value={form.Email}
        onChange={handleChange}
        error={Boolean(errors.Email)}
        helperText={errors.Email}
      />
      <SelectRol
        value={form.Rol}
        onChange={val => handleChange({
          target: {name: 'Rol', value: val}
        })}
        error={Boolean(errors.Rol)}
        helperText={errors.Rol}
        initial={rol}
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={Boolean(values)}
            checked={form.TienePassword}
            onChange={ev => handleChange({
              target: {name: 'TienePassword', value: ev.target.checked}
            })}
          />
        }
        label="Usuario con contraseña"
        labelPlacement="end"
      />
      {!form.TienePassword &&
        <TextField
          fullWidth
          label="Usuario de Active Directory"
          name="UsuarioAD"
          value={form.UsuarioAD}
          onChange={handleChange}
          error={Boolean(errors.UsuarioAD)}
          helperText={errors.UsuarioAD}
        />
      }
      {form.TienePassword &&
        <TextField
          fullWidth
          label="Contraseña"
          name="Password"
          type="password"
          value={form.Password}
          onChange={handleChange}
          error={Boolean(errors.Password)}
          helperText={errors.Password}
        />
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        variant="contained"
        loading={loading}
        onClick={submit}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default UsuarioEdit
