import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectRol = ({ value, onChange, error, helperText, initial=null }) => {
  const { roles } = useStoreState(state => ({
    roles: state.roles
  }))
  const { getRoles } = useStoreActions(actions => ({
    getRoles: actions.getRoles
  }))
  useEffect(() => {
    if (!roles.length) {
      getRoles()
    }
  }, [])
  useEffect(() => {
    if (initial && roles.length) {
      const item = roles.find(i => i.IdRol === initial)
      onChange(item)
    }
  }, [initial, roles])
  return <Autocomplete
    fullWidth
    defaultValue={null}
    options={roles}
    value={value}
    onChange={(ev, value) => onChange(value)}
    getOptionLabel={i => i.Nombre}
    renderInput={(params) => <TextField
      {...params}
      fullWidth
      label="Rol de usuario"
      error={error}
      helperText={helperText}
    />}
  />
}

export default SelectRol