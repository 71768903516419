import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {
    TextField,
    Grid,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoadingButton from '../../components/Utils/LoadingButton';

const LoginForm = ({ submit, loading, classes }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showPass, setShowPass] = useState(false);

    const handleClickShowPassword = () => {
        setShowPass(!showPass);
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    return (
        <div style={{ width: "100%", marginTop: "1rem" }}>
            <form onSubmit={handleSubmit(submit)} style={{ width: "100%" }}>
                <TextField
                    fullWidth
                    id="usuario"
                    name="usuario"
                    label="Usuario (CUI)"
                    inputProps={{ maxLength: 13, pattern: "[0-9]{13}" }}
                    {...register("usuario")}
                    className={classes.my2}
                />
                <TextField
                    fullWidth
                    id="password"
                    name="password"
                    type={showPass ? 'text' : 'password'}
                    label="Contraseña"
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...register("password")}
                    className={classes.my2}
                />
                <LoadingButton
                    fullWidth
                    type="submit"
                    loading={loading}
                    color="primary"
                    variant="contained"
                    className={classes.my2}
                >
                    Iniciar sesión
                </LoadingButton>
            </form>
        </div>
    )
}

export default LoginForm;
