import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import http from '../../Utils/http'

const SelectTipoMaquinaria = ({ value, onChange, error, helperText, initial=null }) => {
  const [items, setItems] = useState([])
  const getItems = async () => {
    const data = await http.get('/maestros/tipo_maquinaria')
    setItems(data)
  }
  useEffect(() => {
    getItems()
  }, [])
  useEffect(() => {
    if (initial && items.length) {
      const item = items.find(i => i.IdTipoMaquinaria == initial)
      onChange(item)
    }
  }, [initial, items])
  return <Autocomplete
    fullWidth
    defaultValue={null}
    options={items}
    value={value}
    onChange={(ev, value) => onChange(value)}
    getOptionLabel={i => i.Nombre}
    renderInput={(params) => <TextField
      {...params}
      fullWidth
      label="Tipo de maquinaria"
      error={error}
      helperText={helperText}
    />}
  />
}

export default SelectTipoMaquinaria
