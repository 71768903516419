import React, { useEffect, useState } from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { set } from 'lodash'
import { on } from 'devextreme/events'

const SelectMaquinariaGIO = ({
    value,
    onChange,
    error=false,
    helperText='',
    label='',
    multiple=false,
    disabled=false,
    frentes=null
  }) => {
  const { maquinaria } = useStoreState(state => ({
    maquinaria: state.maquinaria
  }))
  const { getMaquinaria } = useStoreActions(actions => ({
    getMaquinaria: actions.getMaquinaria
  }))
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (frentes && frentes.length) {
      setItems(maquinaria.filter(i => frentes.find(f => f.IdFrente === i.IdFrente)))
    } else {
      setItems(maquinaria)
    }
  }, [frentes, maquinaria])
  useEffect(() => {
    if (!maquinaria.length) {
      getMaquinaria()
    }
  }, [])
  return <FormControl
    fullWidth
  >
    <Autocomplete
      fullWidth
      multiple={multiple}
      options={items}
      disabled={disabled}
      getOptionLabel={option => option['Codigo']}
      renderInput={params => <TextField
        {...params}
        fullWidth
        error={error}
        label={label}
        margin="none"
        helperText={helperText}
      />}
      value={value}
      onChange={onChange}
    />
  </FormControl>
}

export default SelectMaquinariaGIO