import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectZona2 = ({ value, onChange, error=false, helperText='', defaultValue=null }) => {
  const { zonas } = useStoreState(state => ({
    zonas: state.zonas
  }))
  const { getZonas } = useStoreActions(actions => ({
    getZonas: actions.getZonas
  }))
  useEffect(() => {
    getZonas()
    if (defaultValue) {
      const item = zonas.find(i => i.codigo == defaultValue)
      onChange(item)
    }
  }, [zonas, defaultValue])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        fullWidth
        options={zonas}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option['nombre'] ? option['nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label="Zona"
          margin="none"
          helperText={helperText}
        />}
        value={value}
        onChange={(ev, val) => onChange(val)}
      />
    </div>
  )
}

export default SelectZona2
