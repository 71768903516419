import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "easy-peasy";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core";

import LoginForm from "./LoginForm";
import axios from "axios"
import http from "../../Utils/http";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: "#2B3C4D",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  logoImg: {
    width: 167,
  },
  my2: {
    marginTop: "1rem",
    marginBottom: "1rem",
  }
}));

const API_AWS = process.env.REACT_APP_AWS

const LoginContainer = (props) => {
  const authState = useStoreState((state) => state.auth);
  const authActions = useStoreActions((action) => action.auth);
  const { setAuthHeader, setPermisos } = useStoreActions(actions => ({
    setAuthHeader: actions.setAuthHeader,
    setPermisos: actions.setPermisos
  }))

  const classes = useStyles();

  const onClickLogin = async (values) => {
    authActions.setLoading(true);
    try {
      const bodyRequest = {
        Username: values.usuario,
        Password: values.password
      }
      const { data } = await axios.post(`${API_AWS}/maestros/auth/login`, bodyRequest)
      const { statusCode, body } = data
      if (statusCode == 200) {
        localStorage.setItem('authData', JSON.stringify(body))
        window.location.reload()
      } else {
        toast.error('No se pudo iniciar sesión, revisa tus credenciales')
      }
    } catch (e) {
      toast.error(
        "En este momento no se puede realizar tu solicitud, intentalo más tarde o llama a tu administrador"
      )
    } finally {
      authActions.setLoading(false)
    }
  }

  const getPermisos = async () => {
    const result = await http.get('/maestros/auth/permisos')
    // localStorage.setItem('permisos', JSON.stringify(result))
    setPermisos(result)
  }
  useEffect(() => {
    try {
      const authData = JSON.parse(localStorage.getItem('authData'))
      if (authData) {
        const now = Math.floor(Date.now()/1000)
        if (authData.Expira > now) {
          setAuthHeader(`Bearer ${authData.Token}`)
          props.history.push('/mapa-ubicacion')
          getPermisos()
        } else {
          localStorage.clear()
          window.location.reload()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [])
  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: "#2B3C4D" }}>
          <img
            src="./image-repository/logo.png"
            className={classes.logoImg}
            alt="logo ilu"
          />
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingreso a la aplicación
          </Typography>
          <LoginForm submit={onClickLogin} loading={authState.loading} classes={classes} />
        </Paper>
      </main>
    </Fragment>
  );
}

export default withRouter(LoginContainer);
