import React, { useState, useEffect } from 'react'
import {
  TextField,
  Box,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { Cached } from '@material-ui/icons'
import LoadingButton from '../Utils/LoadingButton'
import { toast } from 'react-toastify'
import { nanoid } from 'nanoid'

const GIOEdit = ({ onClose, onSubmit, values=null, title, onRefresh  }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    Codigo: '',
    Token: ''
  })
  const [errors, setErrors] = useState({})
  const validate = () => {
    let tempErrors = {};
    if (!form.Codigo) {
      tempErrors.Codigo = 'Este campo es requerido';
    } else if (!(/^GIO-[0-9]{4,}/.test(form.Codigo))) {
      tempErrors.Codigo = 'La estructura del codigo no es correcta';
    }
    if (!form.Token) {
      tempErrors.Token = 'Este campo es requerido';
    } else if (form.Token.length !== 20) {
      tempErrors.Token = 'El token debe tener 20 caracteres';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const handleChange = (ev) => {
    setForm({
      ...form,
      [ev.target.name]: ev.target.value
    })
  }
  const generateToken = () => {
    const token = nanoid(20)
    setForm({
      ...form,
      Token: token
    })
  }
  const submit = async () => {
    try {
      if (validate()) {
        setLoading(true)
        await onSubmit(form)
        onRefresh()
        toast.success('Registro guardado')
      }
    } catch (error) {
      if (error.statusCode === 409) {
        toast.error('El codigo ya esta registrado')
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (Boolean(values)) {
      setForm({
        Codigo: values.Codigo
      })
    }
  }, [])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Código GIO"
        name="Codigo"
        error={Boolean(errors.Codigo)}
        helperText={errors.Codigo}
        value={form.Codigo}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Token"
        name="Token"
        error={Boolean(errors.Token)}
        helperText={errors.Token}
        value={form.Token}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton onClick={generateToken}>
              <Cached />
            </IconButton>
          </InputAdornment>,
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancelar
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={submit}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default GIOEdit
