import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'

import LoadingButton from '../../components/Utils/LoadingButton'
import SelectZafra from '../../components/Selects/SelectZafra'
import SelectTipoArchivoGIO from '../../components/Selects/SelectTipoArchivoGIO'
import SelectZona from '../../components/Selects/SelectZona'
import SelectFinca from '../../components/Selects/SelectFinca'
import SelectGIO from '../../components/Selects/SelectGIO'
import { getIdentity } from '../../Utils/auth'
import http from '../../Utils/http'
import SelectMaquinariaGIO from '../../components/Selects/SelectMaquinariaGIO'

const ArchivoTelemetriaEdit = ({ title, onClose, onSubmit, onRefresh }) => {
  const [archivo, setArchivo] = useState([])
  const [form, setForm] = useState({
    Nombre: '',
    Zafra: null,
    IdTipoArchivo: '',
    ListaMaquinaria: [],
    Zona: null,
    Descripcion: '',
    Finca: null
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    Nombre: false,
    Zafra: false,
    IdTipoArchivo: false,
    ListaMaquinaria:  false,
    Zona: false,
    Finca: false
  })
  const changeForm = (prop, value) => {
    const tmp = {...form}
    tmp[prop] = value
    setForm(tmp)
  }
  const validate = () => {
    const errs = {
      Nombre: form.Nombre.trim().length === 0,
      Zafra: form.Zafra === null,
      IdTipoArchivo: !Boolean(form.IdTipoArchivo),
      ListaMaquinaria: form.ListaMaquinaria.length === 0,
      Zona: form.Zona === null,
      Finca: form.Finca === null
    }
    setErrors(errs)
    if (archivo.length === 0) {
      toast.error('El archivo es requerido')
      return false
    }
    for (const key in errs) {
      if (errs[key]) {
        return false
      }
    }
    return true
  }
  const getUploadData = async () => {
    const data = await http.get('/maestros/archivo/carga')
    return data
  }
  const uploadFile = async (file, signedData) => {
    const { url, fields } = signedData
    const form = new FormData()
    for (const key in fields) {
      form.append(key, fields[key])
    }
    form.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    await axios.post(url, form, config)
  }
  const submitForm = async () => {
    // console.log(form)
    try {
      if (validate()) {
        setLoading(true)
        const {Key, ...URL} = await getUploadData()
        const body = {
          Nombre: form.Nombre,
          NombreFinca: form.Finca.Nombre,
          CodigoFinca: form.Finca.Codigo,
          Zona: parseInt(form.Zona),
          Zafra: parseInt(form.Zafra.Codigo),
          IdTipoArchivo: form.IdTipoArchivo,
          Descripcion: form.Descripcion,
          UsuarioCreo: getIdentity(),
          RutaS3: Key
        }
        uploadFile(archivo[0], URL)
        const newArchivo = await http.post('/maestros/archivo', body)
        const listaMaquinaria = form.ListaMaquinaria.map(i => i.IdMaquinaria)
        const requests = listaMaquinaria.map(i => http.post('/maestros/archivo_maquinaria', {
          IdMaquinaria: i,
          IdArchivo: newArchivo.IdArchivo,
          UsuarioCreo: getIdentity()
        }))
        await Promise.all(requests)
        toast.success('Registro guardado')
        onRefresh()
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {}, [])
  return <>
    <DialogTitle>{ title }</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre de archivo"
        value={form.Nombre}
        onChange={ev => changeForm('Nombre', ev.target.value)}
        error={errors.Nombre}
        helperText={errors.Nombre && 'El campo es requerido'}
      />
      <SelectZafra
        value={form.Zafra}
        onChange={value => changeForm('Zafra', value)}
        error={errors.Zafra}
        helperText={errors.Zafra && 'El campo es requerido'}
        minZafra="2022"
      />
      <SelectTipoArchivoGIO
        value={form.IdTipoArchivo}
        onChange={t => changeForm('IdTipoArchivo', t)}
        error={errors.IdTipoArchivo}
        helperText={errors.IdTipoArchivo && 'El campo es requerido'}
      />
      <SelectMaquinariaGIO
        label="Maquinaria"
        multiple
        value={form.ListaMaquinaria}
        onChange={(ev, val) => {
          changeForm('ListaMaquinaria', val)
        }}
        error={errors.ListaMaquinaria}
        helperText={errors.ListaMaquinaria && 'El campo es requerido'}
      />
      <SelectZona
        value={form.Zona}
        onSelect={val => changeForm('Zona', val)}
        error={errors.Zona}
        helperText={errors.Zona && 'El campo es requerido'}
      />
      <SelectFinca
        fullValue
        codigoZona={form.Zona}
        onSelect={val => val && changeForm('Finca', val)}
        error={errors.Finca}
        helperText={errors.Finca && 'El campo es requerido'}
      />
      <TextField
        multiline
        fullWidth
        rows={3}
        label="Descripción"
        value={form.Descripcion}
        onChange={ev => changeForm('Descripcion', ev.target.value)}
      />
      <div style={{marginTop: 10}}>
        <DropzoneArea
          filesLimit={1}
          dropzoneText="Archivo ZIP"
          onChange={files => setArchivo(files)}
          acceptedFiles={['.zip']}
          maxFileSize={2147483648}
        />
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        color="primary"
        variant="contained"
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default ArchivoTelemetriaEdit
