import React from 'react'
import {
  Typography,
  Box
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import Layout from '../../components/Layout/layout'
const NotFound = () => {
  return <Layout>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h1" component="p" style={{color: '#000'}}>
        <Search fontSize="inherit" />
      </Typography>
      <Typography variant="h2" component="h1" style={{color: '#000'}}>
        No encontrado
      </Typography>
      <Typography variant="h1" component="h2" style={{color: '#000'}}>
        404
      </Typography>
    </Box>
  </Layout>
}

export default NotFound