import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { config } from './MapaConfig'
const useStyles = makeStyles(theme => ({
  mapContainer: {
    minHeight: 600,
    width: '100%',
    position: 'relative'
  }
}))

const MapaPuntos = ({ layers, shape, lat='Latitude', lon='Longitude' }) => {
  const mapaRef = useRef(null)
  const shapeRef = useRef(null)
  const mapaBaseRef = useRef(null)
  const capasRef = useRef([])

  const classes = useStyles()
  const clear = () => {
    for (let i = 0; i < capasRef.current.length; i++) {
      capasRef.current[i].clearLayers()
    }
    capasRef.current = []
  }
  useEffect(() => {
    if (layers.length) {
      clear()
      for (const l of layers) {
        const markers = []
        const color = l['color']
        for (const p of l['puntos']) {
          if (Boolean(p[lat]) && Boolean(p[lon])) {
            markers.push(window.L.circleMarker([p[lat], p[lon]], {
              render: window.L.canvas({ padding: 0.5 }),
              color: color,
              radius: 0.5,
              fillOpacity: 1
            }).addTo(mapaRef.current))
          }
        }
        const capa = window.L.layerGroup(markers)
        capa.addTo(mapaRef.current)
        capasRef.current.push(capa)
      }
    }
  }, [layers])
  useEffect(() => {
    if (shape) {
      shapeRef.current = window.L.geoJSON(shape, {
          style: (feature) => {
          return {
            color: '#7400b8',
            fillOpacity: 0,
            weight: 1.5
          }
        }
      })
      shapeRef.current.addTo(mapaRef.current)
    }
  }, [shape])
  useEffect(() => {
    if (mapaRef.current === null) {
      mapaRef.current = window.L.map('map-points', {
        preferCanvas: true,
        zoomSnap: 0.5,
        attributionControl: false
      }).setView([14.270985610779121, -91.09580037703911], 11)
      mapaBaseRef.current = window.L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash',
        maxZoom: 18
      })
      mapaBaseRef.current.addTo(mapaRef.current)
    }
  }, [])
  return <div id="map-points" className={classes.mapContainer}></div>
}

export default MapaPuntos
