import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectZona = ({ onSelect, value=null, error=false, helperText='' }) => {
  const { zonas } = useStoreState(state => ({
    zonas: state.zonas
  }))
  const { getZonas } = useStoreActions(actions => ({
    getZonas: actions.getZonas
  }))
  const [zona, setZona] = useState('')
  const changeZona = (ev, value) => {
    setZona(value)
    onSelect(value ? value.Codigo : null)
  }
  useEffect(() => {
    if (!zonas.length) {
      getZonas()
    }
    if (!value) {
      setZona('')
      onSelect(null)
    }
  }, [value])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-zona"
        fullWidth
        options={zonas}
        defaultValue={null}
        getOptionLabel={(option) => option['Nombre'] ? option['Nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label={"Zona"}
          margin="none"
          helperText={helperText}
        />}
        value={zona}
        onChange={changeZona}
      />
    </div>
  )
}

export default SelectZona

export const SelectMultiZonas = ({ onSelect, error=false, helperText='' }) => {
  const { zonas } = useStoreState(state => ({
    zonas: state.zonas
  }))
  const { getZonas } = useStoreActions(actions => ({
    getZonas: actions.getZonas
  }))
  const [items, setItems] = useState([])
  const changeZona = (ev, value) => {
    setItems(value)
    onSelect(value)
  }
  useEffect(() => {
    if (!zonas.length) {
      getZonas()
    }
  }, [])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        fullWidth
        multiple
        options={zonas}
        defaultValue={null}
        getOptionLabel={(option) => option['nombre'] ? option['nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label={"Zona"}
          margin="none"
          helperText={helperText}
        />}
        value={items}
        onChange={changeZona}
      />
    </div>
  )
}
