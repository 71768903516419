import { thunk, action } from 'easy-peasy'
import { toast } from 'react-toastify'
import http from '../../Utils/http'

export default {
  zonas: [],
  fincas: [],
  secciones: [],
  unidades: [],
  lotes: [],
  seccionesLotes: [],
  tiposAplicacion: [],
  cosechadoras: [],
  filtroFinca: {},
  /* -------------------------------------------------------------------------- */
  setZonas: action((state, value) => {
    state.zonas = value
  }),
  setFincas: action((state, value) => {
    state.fincas = value
  }),
  setSecciones: action((state, value) => {
    state.secciones = value
  }),
  setLotes: action((state, value) => {
    state.lotes = value
  }),
  setSeccionesLotes: action((state, value) => {
    state.seccionesLotes = value
  }),
  setTiposAplicacion: action((state, value) => {
    state.tiposAplicacion = value
  }),
  setCosechadoras: action((state, value) => {
    state.cosechadoras = value
  }),
  /* -------------------------------------------------------------------------- */
  getZonas: thunk(async (actions, _, { getStoreState }) => {
    try {
      const { zonas } = getStoreState()
      const data = await http.get('/maestros/catalogo/zonas')
      if (zonas.length) return
      // const { data } = await axios.get('/zonas')
      actions.setZonas(data)
    } catch (error) {
      toast.error('Ha ocurrido un error 😅')
    }
  }),

  getFincas: thunk(async (actions, codigoZona) => {
    const data = await http.get(`/maestros/catalogo/fincas?Zona=${codigoZona}`)
    // const { axios } = getStoreState()
    // const { data } = await axios.get(`/fincas/${codigoZona}`)
    actions.setFincas(data)
  }),

  getFincasAll: thunk(async (actions) => {
    const data = await http.get('/maestros/catalogo/fincas')
    // const { axios } = getStoreState()
    // const { data } = await axios.get('/fincas')
    actions.setFincas(data)
  }),

  getSeccionesLotes: thunk(async (actions, codigoFinca) => {
    const data = await http.get(`/maestros/catalogo/lotes?Finca=${codigoFinca}`)
    // const { axios } = getStoreState()
    // const { data } = await axios.get(`/seccion-lotes/${codigoFinca}`)
    const seccionesSet = new Set(data.map(i => i.Seccion))
    actions.setSecciones(Array.from(seccionesSet))
    actions.setSeccionesLotes(data)
  }),

  getLotes: thunk(async (actions, seccion, { getStoreState }) => {
    const { seccionesLotes } = getStoreState()
    if (!seccion) {
      console.log('No seccion')
      actions.setLotes(seccionesLotes.map(i => i.Lote))
    } else {
      actions.setLotes(seccionesLotes
        .filter(i => i.Seccion === seccion)
        .map(i => i.Lote))
    }
  }),
  getTiposAplicacion: thunk(async (actions, _, { getStoreState }) => {
    const { axios } = getStoreState()
    const { data } = await axios.get(`/protocolos`)
    actions.setTiposAplicacion(data)
  }),
  getCosechadoras: thunk(async (actions, _, { getStoreState }) => {
    const { axios } = getStoreState()
    const { data } = await axios.get('/cosecha/cosechadoras')
    actions.setCosechadoras(data)
  })
}
