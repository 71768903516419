import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Layout from '../../components/Layout/layout'
import { Box } from '@material-ui/core'

const Home = () => {
  const history = useHistory()
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('authData'))
    if (!user) {
      history.push('/login')
    }
  }, [])
  return <Layout>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{background: '#202E3C', padding: 40}}
    >
      <img src="./static/logo.png" />
      <img src="./static/tractor.svg" height="400" style={{color: '#fff'}} />
    </Box>
  </Layout>
}

export default Home
