import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Api from '../../Utils/api';

const SelectLote = ({ onSelect, codigoSeccion, codigoFinca=null, error=false, helperText='' }) => {
  const { lotes } = useStoreState(state => ({
    lotes: state.lotes
  }))
  const { getLotes } = useStoreActions(actions => ({
    getLotes: actions.getLotes
  }))
  const [lote, setLote] = useState('')

  const changeLote = (ev, value) => {
    setLote(value)
    onSelect(value)
  }

  useEffect(() => {
    if (codigoSeccion) {
      getLotes(codigoSeccion)
    }
    if (codigoFinca) {
      getLotes(null)
    }
    onSelect(null)
    setLote('')
  }, [codigoSeccion, codigoFinca])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-lote"
        fullWidth
        options={lotes}
        defaultValue={null}
        getOptionLabel={(option) => option}
        renderInput={(params) =>
          <TextField
            error={error}
            {...params}
            fullWidth
            label={"Unidad de manejo"}
            margin="none"
            helperText={helperText}
          />
        }
        value={lote}
        onChange={changeLote}
      />
    </div>
  )
}

export const SelectMultiLote = ({ values, onSelect, codigoSeccion }) => {
  const { lotes } = useStoreState(state => ({
    lotes: state.lotes
  }))
  const { getLotes } = useStoreActions(actions => ({
    getLotes: actions.getLotes
  }))

  const changeLote = (ev, value) => {
    onSelect(value.sort())
  }

  useEffect(() => {
    if (codigoSeccion) {
      getLotes(codigoSeccion)
    }
  }, [codigoSeccion])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-lotes"
        multiple
        fullWidth
        options={lotes}
        defaultValue={null}
        getOptionLabel={(option) => option}
        renderInput={(params) => <TextField
          {...params}
          fullWidth
          label={"Unidades de manejo"}
          margin="none"
        />}
        value={values || []}
        onChange={changeLote}
      />
    </div>
  )
}

export const SelectMultiLoteAntiguo = ({ values, onSelect, zafra, finca, ums }) => {
  const [lotes, setLotes] = useState([]);

  useEffect(() => {
    if (zafra && finca && ums && ums.length) {
      getLotesAntiguos();
    } else {
      setLotes([])
    }
  }, [zafra, finca, ums])

  const getLotesAntiguos = () => {
    const params = { finca, zafra: zafra.codigo, ums: ums.toString() };
    Api.get("lotes-antiguos", params).then(response => {
      setLotes(response || []);
    }).catch(() => setLotes([]));
  }

  const changeLote = (ev, value) => {
    onSelect(value.sort())
  }

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-lotes-antiguos"
        multiple
        fullWidth
        options={lotes}
        defaultValue={null}
        getOptionLabel={(option) => option}
        renderInput={(params) => <TextField {...params} fullWidth label={"Lotes antiguos"} margin="none" />}
        value={values || []}
        onChange={changeLote}
      />
    </div>
  )
}

export default SelectLote
