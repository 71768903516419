import { action, thunk } from 'easy-peasy'
import http from '../Utils/http'
import { toast } from 'react-toastify'

export default {
  dataCatalogs: [],
  setDataCatalogs: action((state, payload) => {
    state.dataCatalogs = payload
  }),
  getDataCatalogs: thunk(async (actions, payload) => {
    try {
      const data = await http.get('/maestros/dataset/data_catalog')
      actions.setDataCatalogs(data)
    } catch (error) {
      toast.error('Error al obtener los datos')
    }
  }),
}
