import React, { useState } from 'react'
import {
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import LoadingButton from '../Utils/LoadingButton'
import http from '../../Utils/http'
import axios from 'axios'
import { toast } from 'react-toastify'
import { DropzoneArea } from 'material-ui-dropzone'
import { getIdentity } from '../../Utils/auth'
import SelectZafra from '../Selects/SelectZafra'

const ShapefileCreate = ({ onClose, onRefresh }) => {
  const [form, setForm] = useState({
    Nombre: '',
    Descripcion: '',
    EPSG: '32615',
    Zafra: '',
    zona: '',
    finca: '',
    codigoFinca: '',
    um: ''
  })
  const [errors, setErrors] = useState({})
  const [shape, setShape] = useState(null)
  const [loading, setLoading]= useState(false)
  const handleChange = (event) => {
    const { name, value } = event.target
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }))
  }
  const validate = () => {
    let tempErrors = {};
    if (!form.Nombre) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    if (!form.EPSG) {
      tempErrors.EPSG = 'Este campo es requerido';
    }
    if (!form.Zafra) {
      tempErrors.Zafra = 'Este campo es requerido';
    }
    if (!form.zona) {
      tempErrors.zona = 'Este campo es requerido';
    }
    if (!form.finca) {
      tempErrors.finca = 'Este campo es requerido';
    }
    if (!form.codigoFinca) {
      tempErrors.codigoFinca = 'Este campo es requerido';
    }
    if (!form.um) {
      tempErrors.um = 'Este campo es requerido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const getUploadData = async () => {
    const data = await http.get('/maestros/shapefile/carga')
    const { Links, Prefix } = data
    return [Links[0], Links[1], Prefix]
  }
  const uploadFile = async (file, signedData) => {
    const { url, fields } = signedData
    const form = new FormData()
    for (const key in fields) {
      form.append(key, fields[key])
    }
    form.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    await axios.post(url, form, config)
  }
  const submitForm = async () => {
    try {
      if (validate()) {
        setLoading(true)
        const [linkShape, linkConfig, prefix] = await getUploadData()
        const { zona, finca, codigoFinca, um, ...body } = form
        const config = {
          epsg: parseInt(form.EPSG),
          config: {zona, finca, codigo: codigoFinca, um}
        }
        const jsonString = JSON.stringify(config);
        const blob = new Blob([jsonString], { type: "application/json" })
        const upload = [uploadFile(blob, linkConfig), uploadFile(shape[0], linkShape)]
        await Promise.all(upload)
        body['Estado'] = 'pendiente'
        body['Prefix'] = prefix
        body['Zafra'] = parseInt(body['Zafra'].Codigo)
        body['EsPrincipal'] = true
        body['Tipo'] = 'poligonos'
        body['UsuarioCreo'] = getIdentity()
        const newShape = await http.post('/maestros/shapefile', body)
        await http.post('/maestros/shapefile/procesar', {IdShapefile: newShape['IdShapefile']})
        toast.success('Registro guardado')
        onRefresh()
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  return <>
    <DialogTitle>
      Crear shapefile
    </DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre"
        name="Nombre"
        value={form.Nombre}
        onChange={handleChange}
        error={Boolean(errors.Nombre)}
        helperText={errors.Nombre}
      />
      <TextField
        fullWidth
        label="Descripción"
        name="Descripcion"
        value={form.Descripcion}
        onChange={handleChange}
        error={Boolean(errors.Descripcion)}
        helperText={errors.Descripcion}
      />
      <TextField
        fullWidth
        label="EPSG"
        name="EPSG"
        type="number"
        value={form.EPSG}
        onChange={handleChange}
        error={Boolean(errors.EPSG)}
        helperText={errors.EPSG}
      />
      <SelectZafra
        minZafra='2022'
        value={form.Zafra}
        onChange={value => handleChange({target: {name: 'Zafra', value}})}
        error={Boolean(errors.Zafra)}
        helperText={errors.Zafra}
      />
      <TextField
        fullWidth
        label="Columna zona"
        name="zona"
        value={form.zona}
        onChange={handleChange}
        error={Boolean(errors.zona)}
        helperText={errors.zona}
      />
      <TextField
        fullWidth
        label="Columna código de finca"
        name="codigoFinca"
        value={form.codigoFinca}
        onChange={handleChange}
        error={Boolean(errors.codigoFinca)}
        helperText={errors.codigoFinca}
      />
      <TextField
        fullWidth
        label="Columna nombre de finca"
        name="finca"
        value={form.finca}
        onChange={handleChange}
        error={Boolean(errors.finca)}
        helperText={errors.finca}
      />
      <TextField
        fullWidth
        label="Columna unidad de manejo"
        name="um"
        value={form.um}
        onChange={handleChange}
        error={Boolean(errors.um)}
        helperText={errors.um}
      />
      <DropzoneArea
        filesLimit={1}
        dropzoneText="Shapefile"
        onChange={files => setShape(files)}
        acceptedFiles={['.zip']}
        maxFileSize={20000000}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default ShapefileCreate
