import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import LoadingButton from '../Utils/LoadingButton';
import { toast } from 'react-toastify';
import SelectTipoMaquinaria from './SelectTipoMaquinaria';
import SelectArea from './SelectArea';
import SelectClasificacion from '../Admin/SelectClasificacion';

const OperacionEdit = ({ title, onSubmit, onClose, onRefresh, values=null }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    Codigo: '',
    Nombre: '',
    Descripcion: '',
    DebeUsar: '',
    EsAutomatico: false,
    Clasificacion: null,
    TipoMaquinaria: null,
    Area: null
  })
  const [initials, setInitials] = useState({
    tipo: null,
    clasificacion: null,
    area: null
  })
  const [errors, setErrors] = useState({})
  const changeForm = (prop, value) => {
    setForm({
      ...form,
      [prop]: value
    })
  }
  const validate = () => {
    let tempErrors = {};
    if (!form.Codigo) {
      tempErrors.Codigo = 'Este campo es requerido';
    }
    if (!form.Nombre.trim()) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    if (!form.Clasificacion) {
      tempErrors.Clasificacion = 'Este campo es requerido';
    }
    if (!form.TipoMaquinaria) {
      tempErrors.TipoMaquinaria = 'Este campo es requerido';
    }
    if (!form.Area) {
      tempErrors.Area = 'Este campo es requerido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const submit = async () => {
    if (validate()) {
      try {
        setLoading(true)
        const {
          Nombre,
          Codigo,
          Descripcion,
          DebeUsar,
          EsAutomatico
        } = form
        await onSubmit({
          Nombre,
          Descripcion,
          DebeUsar,
          EsAutomatico,
          Codigo: parseInt(Codigo),
          IdArea: form.Area.IdArea,
          IdClasificacionOperacion: form.Clasificacion.IdClasificacionOperacion,
          IdTipoMaquinaria: form.TipoMaquinaria.IdTipoMaquinaria
        })
        onRefresh()
        toast.success('Registro guardado 😄')
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (values) {
      const { Codigo, Nombre, Descripcion, DebeUsar, EsAutomatico, ...rest } = values
      setForm({
        ...form,
        Codigo,
        Nombre,
        Descripcion,
        DebeUsar,
        EsAutomatico
      })
      setInitials({
        area: rest.IdArea,
        clasificacion: rest.IdClasificacionOperacion,
        tipo: rest.IdTipoMaquinaria
      })
    }
  }, [values])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Codigo"
        type="number"
        value={form.Codigo}
        onChange={ev => changeForm('Codigo', ev.target.value)}
        error={Boolean(errors.Codigo)}
        helperText={errors.Codigo && 'El campo es requerido'}
      />
      <TextField
        fullWidth
        label="Nombre"
        value={form.Nombre}
        onChange={ev => changeForm('Nombre', ev.target.value)}
        error={Boolean(errors.Nombre)}
        helperText={errors.Nombre}
      />
      <TextField
        fullWidth
        multiline
        rows={3}
        label="Descripción"
        value={form.Descripcion}
        onChange={ev => changeForm('Descripcion', ev.target.value)}
      />
      <TextField
        fullWidth
        multiline
        rows={3}
        label="Debe usar"
        value={form.DebeUsar}
        onChange={ev => changeForm('DebeUsar', ev.target.value)}
      />
      <FormControlLabel
        control={<Switch
          checked={form.EsAutomatico}
          onChange={ev => changeForm('EsAutomatico', ev.target.checked)}
        />}
        label="Es automatico"
      />
      <SelectArea
        initial={initials.area}
        value={form.Area}
        onChange={value => setForm({...form, Area: value})}
        error={Boolean(errors.Area)}
        helperText={errors.Area}
      />
      <SelectTipoMaquinaria
        initial={initials.tipo}
        value={form.TipoMaquinaria}
        onChange={value => changeForm('TipoMaquinaria', value)}
        error={Boolean(errors.TipoMaquinaria)}
        helperText={errors.TipoMaquinaria}
      />
      <SelectClasificacion
        initial={initials.clasificacion}
        value={form.Clasificacion}
        onChange={value => changeForm('Clasificacion', value)}
        error={Boolean(errors.Clasificacion)}
        helperText={errors.Clasificacion}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={submit}
        loading={loading}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default OperacionEdit
