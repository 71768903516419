import React, { useState, useEffect } from 'react'
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectTipoArchivoGIO = ({error=false, helperText='', value, onChange}) => {
  const { tiposArchivo } = useStoreState(state => ({
    tiposArchivo: state.tiposArchivo
  }))
  const { getTiposArchivo } = useStoreActions(actions => ({
    getTiposArchivo: actions.getTiposArchivo
  }))
  // const [value, setValue] = useState('')
  useEffect(() => {
    getTiposArchivo()
  }, [])
  return <FormControl
    fullWidth
    error={error}
  >
    <InputLabel>Tipo de archivo</InputLabel>
    <Select
      error={error}
      onChange={ev => {
        // setValue(ev.target.value)
        // onChange(tiposArchivo.find(i => i.IdTipoArchivo == ev.target.value))
        onChange(ev.target.value)
      }}
      value={value}
    >
      {
        tiposArchivo.map((i, idx) => <MenuItem
          key={idx}
          value={i.IdTipoArchivo}
        >
          {i.Nombre}
        </MenuItem> )
      }
    </Select>
    {
      helperText && <FormHelperText>{helperText}</FormHelperText>
    }
  </FormControl>
}

export default SelectTipoArchivoGIO
