import 'devextreme/dist/css/dx.light.css'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StoreProvider } from 'easy-peasy';
import GlobalStore from './globalStore'
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "./styles/main.css";


/* Este código comentado sirve para autenticación con AWS Cognito utilizando API Gateway     
    
import Amplify from "aws-amplify";
import config from "./config";


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.env[config.env.environment].cognito.REGION,
    userPoolId: config.env[config.env.environment].cognito.USER_POOL_ID,
    identityPoolId: config.env[config.env.environment].cognito.IDENTITY_POOL_ID,
    userPoolWebClientId:
      config.env[config.env.environment].cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: config.env.environment,
        endpoint: config.env[config.env.environment].apiGateway.URL,
        region: config.env[config.env.environment].apiGateway.REGION,
      },
    ],
  },
});

*/

// Tema Material UI
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5c6d7e',
      main: '#34495e',
      dark: '#243341',
      contrastText: '#fff',
    },
    secondary: {
      light: '#cc6055',
      main: '#c0392b',
      dark: '#86271e',
      contrastText: '#fff',
    },
    error: {
      light: '#eb6f63',
      main: '#e74c3c',
      dark: '#a1352a',
    },
    warning: {
      light: '#f5af41',
      main: '#f39c12',
      dark: '#aa6d0c',
    },
    info: {
      light: '#5399c7',
      main: '#2980b9',
      dark: '#1c5981',
    },
    success: {
      light: '#52be7f',
      main: '#27ae60',
      dark: '#1b7943',
    }
  },
});

ReactDOM.render(
  <StoreProvider store={GlobalStore}>
    <ToastContainer />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StoreProvider>
  , document.getElementById("root")
);

serviceWorker.unregister();
