
import React from 'react';
import NumberFormat from 'react-number-format';

export const RenderNumber = ({ value, decimalScale, className, prefix, suffix }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix}
            suffix={suffix}
            displayType="text"
        />
    );
};

export const RenderCurrency = ({ value, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix="Q "
            displayType="text"
        />
    );
};