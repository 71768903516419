import React from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)


const MultiChart = ({ data, title }) => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: title
      }
    },
    responsive: true
  }
  return <Chart options={options} type='bar' data={data} />
}

export default MultiChart
