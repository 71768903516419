import { action, thunk } from 'easy-peasy'
import axios from 'axios'

const URL_VARIABLE = `${process.env.REACT_APP_AWS}/variable`

export default {
  variables: [],
  setVariables: action((state, payload) => {
    state.variables = payload
  }),
  getVariables: thunk(async (actions, params) => {
    const { data: { body }} = await axios.get(URL_VARIABLE)
    actions.setVariables(body)
  })
}
