import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core'
import { useStoreState } from 'easy-peasy'
import { toast } from 'react-toastify'
import axios from 'axios'

import LoadingButton from '../../components/Utils/LoadingButton'
import SelectZafra from '../../components/Selects/SelectZafra'
import SelectTipoArchivoGIO from '../../components/Selects/SelectTipoArchivoGIO'
import SelectGIO from '../../components/Selects/SelectGIO'
import { getIdentity } from '../../Utils/auth'
import SelectZona2 from '../Selects/SelectZona2'
import SelectFinca2 from '../Selects/SelectFinca2'

const URL_BASE = process.env.REACT_APP_AWS

const ArchivoTelemetriaUpdate = ({ title, onClose, onSubmit, onRefresh, initial=null }) => {
  const { tiposArchivo } = useStoreState(state => ({
    tiposArchivo: state.tiposArchivo
  }))
  const [form, setForm] = useState({
    Nombre: '',
    Zafra: null,
    IdTipoArchivo: '',
    ListaGIO: [],
    Zona: null,
    Descripcion: '',
    Finca: null
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    Nombre: false,
    Zafra: false,
    IdTipoArchivo: false,
    ListaGIO:  false,
    Zona: false,
    Finca: false
  })
  const [zafra, setZafra] = useState(null)
  const [zona, setZona] = useState(null)
  const [finca, setFinca] = useState(null)
  const changeForm = (prop, value) => {
    const tmp = {...form}
    tmp[prop] = value
    setForm(tmp)
  }
  const validate = () => {
    const errs = {
      Nombre: form.Nombre.trim().length === 0,
      Zafra: form.Zafra === null,
      IdTipoArchivo: form.IdTipoArchivo.trim().length == 0,
      ListaGIO: form.ListaGIO.length === 0,
      Zona: form.Zona === null,
      Finca: form.Finca === null
    }
    setErrors(errs)
    for (const key in errs) {
      if (errs[key]) {
        return false
      }
    }
    return true
  }
  const submitForm = async () => {
    try {
      if (validate()) {
        setLoading(true)
        const TipoArchivo = tiposArchivo.find(i => i.IdTipoArchivo === form.IdTipoArchivo)
        const body = {
          Nombre: form.Nombre,
          NombreFinca: form.Finca.nombre,
          CodigoFinca: form.Finca.codigo,
          Zona: parseInt(form.Zona.codigo),
          Zafra: parseInt(form.Zafra.codigo),
          IdTipoArchivo: form.IdTipoArchivo,
          ListaGIO: form.ListaGIO.map(i => ({IdGIO: i.IdGIO, Codigo: i.Codigo})),
          Descripcion: form.Descripcion,
          ModificadoPor: getIdentity(),
          TipoArchivo,
          RutaS3: initial.RutaS3,
          IdArchivo: initial.IdArchivo
        }
        await axios.put(`${URL_BASE}/archivo/${initial.IdArchivo}`, body)
        onRefresh()
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setForm({...initial, Zona: null})
    setZafra(initial.Zafra.toString())
    setZona(initial.Zona.toString())
    setFinca(initial.CodigoFinca)
  }, [])
  return <>
    <DialogTitle>{ title }</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre de archivo"
        value={form.Nombre}
        onChange={ev => changeForm('Nombre', ev.target.value)}
        error={errors.Nombre}
        helperText={errors.Nombre && 'El campo es requerido'}
      />
      <SelectZafra
        defaultValue={zafra}
        minZafra='2022'
        value={form.Zafra}
        onChange={value => changeForm('Zafra', value)}
        error={errors.Zafra}
        helperText={errors.Zafra && 'El campo es requerido' }
      />
      <SelectTipoArchivoGIO
        value={form.IdTipoArchivo}
        onChange={t => changeForm('IdTipoArchivo', t)}
        error={errors.IdTipoArchivo}
        helperText={errors.IdTipoArchivo && 'El campo es requerido'}
      />
      <SelectGIO
        label="GIO"
        multiple
        value={form.ListaGIO}
        onChange={(ev, val) => {
          changeForm('ListaGIO', val)
        }}
        error={errors.ListaGIO}
        helperText={errors.ListaGIO && 'El campo es requerido'}
      />
      <SelectZona2
        defaultValue={zona}
        value={form.Zona}
        onChange={val => changeForm('Zona', val)}
        error={errors.Zona}
        helperText={errors.Zona && 'El campo es requerido'}
      />
      <SelectFinca2
        fullValue
        defaultValue={finca}
        codigoZona={form.Zona ? form.Zona['codigo'] : null}
        value={form.Finca}
        onChange={val => changeForm('Finca', val)}
        error={errors.Finca}
        helperText={errors.Finca && 'El campo es requerido'}
      />
      <TextField
        multiline
        fullWidth
        rows={3}
        label="Descripción"
        value={form.Descripcion}
        onChange={ev => changeForm('Descripcion', ev.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        color="primary"
        variant="contained"
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default ArchivoTelemetriaUpdate
