import React from 'react'
import {
  Typography,
  Box
} from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import Layout from '../../components/Layout/layout'
const Unauthorized = () => {
  return <Layout>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h1" component="p" style={{color: '#000'}}>
        <Lock fontSize="inherit" />
      </Typography>
      <Typography variant="h2" component="h1" style={{color: '#000'}}>
        No autorizado
      </Typography>
      <Typography variant="h1" component="h2" style={{color: '#000'}}>
        401
      </Typography>
    </Box>
  </Layout>
}

export default Unauthorized