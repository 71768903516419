import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  ListItemIcon,
  IconButton,
  Box,
  Badge,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import { Navigation } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Alert } from '@material-ui/lab'

const estados = [
  {
    estado: 'ok',
    texto: 'Operativo'
  },
  {
    estado: 'alarma',
    texto: 'Alarma'
  },
  {
    estado: 'detenido',
    texto: 'Detenido'
  },
  {
    estado: 'offline',
    texto: 'Apagado'
  },
  {
    estado: 'maniobra',
    texto: 'Maniobra'
  },
]

const useStyles = makeStyles(theme => ({
  maquinaria: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icono: {
    cursor: 'pointer'
  }
}))

const NavegacionMapa = ({ items, onClose, mover }) => {
  const classes = useStyles()
  const { tiposMaquinaria } = useStoreState(state => ({
    tiposMaquinaria: state.tiposMaquinaria
  }))
  const { getTiposMaquinaria } = useStoreActions(actions => ({
    getTiposMaquinaria: actions.getTiposMaquinaria
  }))
  const [conteos, setConteos] = useState([])
  const [maq, setMaq] = useState([])
  const [selected, setSelected] = useState(null)
  const getIcono = (item, alarmaDefault=false) => {
    const iconDefault = 'marker2_alert.svg'
    switch (item.estado) {
      case 'offline':
        return `./icons/marker_offline_${item.maquinariaTipo}.svg`
      case 'alarma':
        return `./icons/${item.maquinariaTipo}/${!alarmaDefault ? item.alarmas[0].Icono : iconDefault}`
      case 'detenido':
        return `./icons/marker_stop_${item.maquinariaTipo}.svg`
      case 'maniobra':
        return `./icons/marker_maniobra_${item.maquinariaTipo}.svg`
      default:
        return `./icons/marker_online_${item.maquinariaTipo}.svg`
    }
  }
  const selectTipoMaquinaria = (tipoEstado) => {
    const tmp = items.filter(i => i.maquinariaTipo === tipoEstado.maquinariaTipo && i.estado === tipoEstado.estado)
    setMaq(tmp)
    setSelected(tipoEstado)
  }
  const getLabel = (item) => {
    let label = ''
    if (item.estado === 'alarma') {
      label = item.alarmas.map(i => i.Label).join(', ')
    }
    if (['detenido', 'ok', 'offline', 'maniobra'].includes(item.estado)) {
      label = Boolean(item.operacion)
        ? `${item.operacion.nombre_operacion} (${item.operacion.codigo_operacion})`
        : ''
    }
    return label ? ` - ${label}` : ''
  }

  useEffect(() => {
    if (tiposMaquinaria.length) {
      const tmp = []
      for (const t of tiposMaquinaria) {
        const tipo = t.Nombre.toLowerCase()
        const counts = {}
        for (const e of estados) {
          const key = e.estado
          counts[key] = 0
          for (const i of items) {
            if (i.estado === key && i.maquinariaTipo === tipo) {
              counts[key] += 1
            }
          }
        }
        const res =  Object.entries(counts).map(([key, value]) => ({
          maquinariaTipo: tipo,
          estado: key,
          conteo: value,
          texto: estados.find(i => i.estado === key).texto
        })).map(i => ({...i, icono: getIcono(i, true)}))
        tmp.push(res)
      }
      setConteos(tmp)
    }
    if (selected) {
      const tmp = items
        .filter(i => i.maquinariaTipo === selected.maquinariaTipo && i.estado === selected.estado)
      setMaq(tmp)
    }
  }, [items, tiposMaquinaria])
  useEffect(() => {
    if (!tiposMaquinaria.length) {
      getTiposMaquinaria({filtro: 'principal'})
    }
  }, [])
  return <>
    <DialogContent>
      {
        (!Boolean(tiposMaquinaria.length) && !Boolean(conteos.length)) &&
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
      }
      {
        conteos.map((lista, idx) => <Box key={idx} display="flex" justifyContent="space-between" style={{padding: 10}}>
          {
            lista.map((i, idx2) => <div key={idx2} className={classes.maquinaria}>
              <Badge badgeContent={i.conteo} color="error" showZero>
                <img
                  height={40}
                  width={40}
                  src={i.conteo > 0 ? i.icono : i.icono.replace('_alert', '')}
                  onClick={() => selectTipoMaquinaria(i)}
                  className={classes.icono}
                />
              </Badge>
              <Typography variant="caption">
                {i.texto}
              </Typography>
            </div>)
          }
        </Box>)
      }
      <List style={{maxHeight: '30vh', overflow: 'auto'}}>
        {
          maq.map((i, idx) => <ListItem key={idx} >
            <ListItemAvatar>
              <img height={40} width={40} src={getIcono(i)} />
            </ListItemAvatar>
            <ListItemText
              primary={i.codigo}
              secondary={`${i.maquinariaTipo.toUpperCase()}${getLabel(i)}`}
            />
            <ListItemIcon>
              <IconButton onClick={() => mover(i)}>
                <Navigation />
              </IconButton>
            </ListItemIcon>
          </ListItem>)
        }
      </List>
      {
        (!Boolean(selected) && !Boolean(maq.length)) &&
          <Alert severity="info" variant="filled">
            Selecciona un tipo de maquinaria
          </Alert>
      }
      {
        (Boolean(selected) && !Boolean(maq.length)) &&
          <Alert severity="info" variant="filled">
            No hay elementos para mostrar
          </Alert>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </>
}

export default NavegacionMapa
