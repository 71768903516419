import React from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const items = ['Cosechadora', 'Tractor']
const SelectTipoMaq = ({
    value,
    onChange,
    error=false,
    helperText='',
    disabled=false,
    multiple=false
  }) => {
  return <Autocomplete
    fullWidth
    options={items}
    disabled={disabled}
    getOptionLabel={option => option}
    multiple={multiple}
    renderInput={params => <TextField
      {...params}
      fullWidth
      error={error}
      label="Tipo de maquinaria"
      margin="none"
      helperText={helperText}
    />}
    value={value}
    onChange={(ev, value) => onChange(value)}
  />
}

export default SelectTipoMaq