import React, { useState, useEffect } from 'react'
import {
  Button,
  Switch,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel
} from '@material-ui/core'
import { toast } from 'react-toastify'
import LoadingButton from '../Utils/LoadingButton'

const ClasificacionEdit = ({ title, values, onSubmit, onRefresh, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    Nombre: '',
    EsProductivo: false
  })

  const [errors, setErrors] = useState({})
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }
  const changeChecked = (ev) => {
    const { name, checked} = ev.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }))
  }
  const validate = () => {
    let tempErrors = {};
    if (!formData.Nombre.trim()) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const submitForm = async () => {
    try {
      setLoading(true)
      if (validate()) {
        await onSubmit({
          ...formData,
          Nombre: formData.Nombre.trim()
        })
        onRefresh()
        toast.success('Registro guardado')
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (values) {
      setFormData({
        Nombre: values.Nombre,
        EsProductivo: values.EsProductivo
      })
    }
  },[])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <form onSubmit={submitForm}>
        <TextField
          fullWidth
          name="Nombre"
          label="Nombre"
          value={formData.Nombre}
          onChange={handleChange}
          error={Boolean(errors.Nombre)}
          helperText={errors.Nombre && errors.Nombre}
        />
        <FormControlLabel
          control={
            <Switch
              name="EsProductivo"
              checked={formData.EsProductivo}
              onChange={changeChecked}
            />
          }
          label="Es productivo"
        />
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        variant="contained"
        loading={loading}
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default ClasificacionEdit