export const isSignedIn = async () => {
  let signedIn = { response: false, token: "" }

  try {
    // Retreive the credentials
    const credentials = localStorage.getItem("userData");

    if (credentials) {
      let objCredentials = JSON.parse(credentials);

      signedIn["response"] = true;
      signedIn["token"] = objCredentials.token;
      signedIn["usuarioAD"] = objCredentials.usuarioAD;
      signedIn["tipo"] = objCredentials.tipo;
      signedIn["roles"] = objCredentials.roles;
    }
  } catch (error) {
    signedIn["message"] = "Error al obtener los datos de sesión!" + error
  }
  return signedIn
}

export const signIn = async (authInfo) => {
  if (!authInfo.error) {
    try {
      // Store the credentials
      if (authInfo.token) {
        localStorage.setItem("userData", JSON.stringify(authInfo));
      } else {
        return false
      }
    } catch (error) {
      return null;
    }
    return true;
  } else {
    await signOut()
    return false
  }
}

export const hasPermiso = (permiso, listaPermisos) => {
  if (Array.isArray(permiso)) {
    for (const i of permiso) {
      if (listaPermisos.includes(i)) {
        return true
      }
    }
    return false
  } else {
    return listaPermisos.includes(permiso)
  }
}
export const isLoggedIn = () => {
  try {
    const authData = JSON.parse(localStorage.getItem('authData'))
    if (authData.Token) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const signOut = async () => {
  try {
    localStorage.setItem("userData", "")
    localStorage.clear()
  } catch (error) {
    alert("ERROR CERRAR SESION" + error)
  }
  return true
}

export const getIdentity = () => {
  const identity = JSON.parse(localStorage.getItem('authData'))
  return identity.DPI
}

export const filterOptions = (items, permisos) => {
  const includes = (permiso) => {
    if (Array.isArray(permiso)) {
      for (const i of permiso) {
        if (permisos.includes(i)) {
          return true
        }
      }
      return false
    } else {
      return permisos.includes(permiso)
    }
  }
  const itemsTmp = []
  for (const item of items) {
    if (item.type === 'single' && (!item.permiso || includes(item.permiso))) {
      itemsTmp.push(item)
    }
    if (item.type === 'primarysub') {
      const subTmp = []
      for (const sub  of item.subitems) {
        if (sub.type === 'single' && (!sub.permiso || includes(sub.permiso))) {
          subTmp.push(sub)
        }
        if (sub.type === 'secondarysub') {
          const subsubTmp = []
          for (const subsub of sub.subitems) {
            if (!sub.permiso || includes(sub.permiso)) {
              subsubTmp.push(subsub)
            }
          }
          subTmp.push({
            ...sub,
            subitems: subsubTmp
          })
        }
      }
      itemsTmp.push({
        ...item,
        subitems: subTmp
      })
    }
  }
  return itemsTmp
}
