import React, { useState, useEffect } from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SelectVariable = ({
    value,
    onChange,
    error=false,
    helperText='',
    label='',
    disabled=false
  }) => {
    const { variablesConfig } = useStoreState(state => ({
      variablesConfig: state.variablesConfig
    }))
    const { getVariablesConfig } = useStoreActions(actions => ({
      getVariablesConfig: actions.getVariablesConfig
    }))
  const [vars, setVars] = useState([])
  useEffect(() => {
    if (!Object.values(variablesConfig).length) {
      getVariablesConfig()
    } else {
      setVars(Object.values(variablesConfig)
        .reduce((acc, x) => [...acc, ...x], []))
    }
  }, [variablesConfig])
  return <FormControl
    fullWidth
  >
    <Autocomplete
      fullWidth
      options={vars}
      disabled={disabled}
      getOptionLabel={option => option['label']}
      renderInput={params => <TextField
        {...params}
        fullWidth
        error={error}
        label={label}
        margin="none"
        helperText={helperText}
      />}
      value={value}
      onChange={onChange}
    />
  </FormControl>
}

export default SelectVariable