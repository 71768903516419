import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectArea = ({ value, onChange, error, helperText, initial=null }) => {
  const { areasAgricola } = useStoreState(state => ({
    areasAgricola: state.areasAgricola
  }))
  const { getAreasAgricola } = useStoreActions(actions => ({
    getAreasAgricola: actions.getAreasAgricola
  }))
  useEffect(() => {
    getAreasAgricola()
  }, [])
  useEffect(() => {
    if (initial && areasAgricola.length) {
      const item = areasAgricola.find(i => i.IdArea == initial)
      onChange(item)
    }
  }, [initial, areasAgricola])
  return <Autocomplete
    fullWidth
    defaultValue={null}
    options={areasAgricola}
    value={value}
    onChange={(ev, value) => onChange(value)}
    getOptionLabel={i => i.Nombre}
    renderInput={(params) => <TextField
      {...params}
      fullWidth
      label="Área agrícola"
      error={error}
      helperText={helperText}
    />}
  />
}

export default SelectArea
