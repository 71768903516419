import { action } from 'easy-peasy'
import { toast } from 'react-toastify'
import axios from 'axios'

const API = process.env.REACT_APP_API
const API_AWS = process.env.REACT_APP_AWS
const user = JSON.parse(localStorage.getItem('userData'))
const headers = {}
const headersAWS = {}
if (user && user.token) {
  headers['Authorization'] = `Bearer ${user.token}`
  headersAWS['authorizationToken'] = `Bearer ${user.token}`
}
const instance = axios.create({ baseURL: API, headers })
const instanceAWS = axios.create({ baseURL: API_AWS, headersAWS })
instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.setItem('userData', '')
    localStorage.clear()
    window.location.href = '/'
  }
  return error;
})
export default {
  axios: instance,
  axiosAWS: instanceAWS,
  setAuthHeader: action((state, value) => {
    state.axios.defaults.headers['Authorization'] = value
    state.axiosAWS.defaults.headers['authorizationToken'] = value
  }),
  clearAuthHeader: action((state) => {
    delete state.axios.defaults.headers.Authorization
    delete state.axiosAWS.defaults.headers.authorizationToken
  }),
  showError: action(() => {
    toast('Ha ocurrido un error', { type: toast.TYPE.ERROR })
  }),
  showMsg: action((_, msg) => {
    toast(msg.text, { type: toast.TYPE[msg.type] })
  })
}
