// -----------------------------------------------------------------------------
// Mapa de ubicacion en tiempo real
// -----------------------------------------------------------------------------
import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Fab
} from '@material-ui/core'
import { Close, Explore, GpsFixed, Search, Stop } from '@material-ui/icons'
import { blue, green, teal } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core'
import { useStoreState, useStoreActions } from 'easy-peasy'
import useInterval from '../../hooks/useInterval'
import dayjs from 'dayjs'
import http from '../../Utils/http'
import BarraDatos from './BarraDatos'
import { toast } from 'react-toastify'
import { getDatosEnTiempoReal, getLocation, getSettings, getShapes, isMobile } from '../../Utils/functions'
import LoadingBackdrop from '../Utils/LoadingBackdrop'
import NavegacionMapa from './NavegacionMapa'
import BusquedaMapa from './BusquedaMapa'

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '85vh',
    width: '100%',
    position: 'relative'
  },
  mapDetail: {
    position: 'absolute',
    height: '100%',
    width: '30%',
    top: 0,
    zIndex: 500,
    padding: theme.spacing(2),
    transition: 'all 0.3s',
    borderRadius: 0,
    overflowY: 'auto'
  },
  mapDetailClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 500
  },
  mapDetailHide: {
    right: '-30%'
  },
  mapDetailShow: {
    right: 0
  },
  btnNavegar: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    background: green[500],
    color: '#FFF',
    zIndex: 500
  },
  btnBuscar: {
    position: 'absolute',
    bottom: theme.spacing(9),
    left: theme.spacing(1),
    background: blue[500],
    color: '#FFF',
    zIndex: 500
  },
  btnLocation: {
    position: 'absolute',
    bottom: theme.spacing(16),
    left: theme.spacing(1),
    background: teal[500],
    color: '#FFF',
    zIndex: 500
  }
}))

const detalleDefault = {
  cosechadora: '--',
  latitud: '--',
  longitud: '--',
  parametros: {},
  hasAlarma: false,
  alarmas: []
}

const MapaUbicacionTiempoReal = () => {
  const classes = useStyles()
  const { zafraActual, maquinaria, alarmasMaquinaria } = useStoreState(state => ({
    axios: state.axios,
    variables: state.variables,
    alarmasGIO: state.alarmasGIO,
    zafraActual: state.zafraActual,
    maquinaria: state.maquinaria,
    gios: state.gios,
    alarmasMaquinaria: state.alarmasMaquinaria
  }))
  const {
    getZafraActual,
    getMaquinaria,
    getGios,
    setAlarmasMaquinaria
  } = useStoreActions(actions => ({
    getVariables: actions.getVariables,
    getZafraActual: actions.getZafraActual,
    getMaquinaria: actions.getMaquinaria,
    getGios: actions.getGios,
    setAlarmasMaquinaria: actions.setAlarmasMaquinaria
  }))
  const mapa = useRef(null)
  const shape = useRef(null)
  const baseMap = useRef(null)
  const puntos = useRef([])
  const markersLayer = useRef(null)
  const labelsLayer = useRef(null)
  const selected = useRef(null)
  const [configs, setConfigs] = useState(null)
  const [listaVariables, setListaVariables] = useState([])
  const [operaciones, setOperaciones] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [horometros, setHorometros] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [showNavegacion, setShowNavegacion] = useState(false)
  const [showBuscar, setShowBuscar] = useState(false)
  const [showDetailMobile, setShowDetailMobile] = useState(false)
  const [detail, setDetail] = useState({
    cosechadora: '--',
    latitud: '--',
    longitud: '--',
    parametros: {},
    hasAlarma: false,
    alarmas: []
  })
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState({
    activate: false,
    latitude: null,
    longitude: null
  })
  const settings = getSettings()
  const classDetail = showDetail ? classes.mapDetailShow : classes.mapDetailHide
  const getShape = async () => {
    const GeoJSON = await getShapes(zafraActual.Codigo)
    shape.current = window.L.geoJSON(GeoJSON, {
        style: (feature) => {
        return {
          color: '#faa307',
          fillOpacity: 0,
          weight: 1
        }
      }
    })
    // -------------------------------------------------------------------------
    // Agregar popup a cada poligono
    // -------------------------------------------------------------------------
    const centroids = []
    shape.current.eachLayer((layer) => {
      if (settings.find(i => i.nombre == 'mostrar_um').valor) {
        const centroid = layer.getBounds().getCenter()
        centroids.push(window.L.marker(centroid, {
          icon: new window.L.divIcon({
            className: 'map-label',
            html: `<span>${layer.feature.properties.um}</span>`
          })
        }))
      }
      if (settings.find(i => i.nombre == 'mostrar_props').valor) {
        const tpl = `
        <b>UM: </b>${layer.feature.properties.um}<br>
        <b>Finca: </b>${layer.feature.properties.finca}<br>
        <b>Área: </b>${layer.feature.properties.area}ha
        `
        layer.bindPopup(tpl, {maxWidth: 200, className: 'custom'})
      }
    })
    if (centroids.length) {
      labelsLayer.current = window.L.layerGroup(centroids)
    }
    shape.current.addTo(mapa.current)
  }
  const clearMap = () => {
    if (markersLayer.current !== null) {
      markersLayer.current.clearLayers()
    }
  }
  const parametroNum = (parametro, valor, variable) => {
    let condicionInferior = false
    let condicionSuperior = false
    if (parametro.TieneAlerta) {
      if ((parametro.InferiorIncluyente && valor >= parametro.Inferior) ||
        (!parametro.InferiorIncluyente && valor > parametro.Inferior)) {
        condicionInferior = true
      }
      if ((parametro.SuperiorIncluyente && valor <= parametro.Superior) ||
        (!parametro.SuperiorIncluyente && valor < parametro.Superior)) {
        condicionSuperior = true
      }
    }
    return {
      variable: { ...parametro, Variable: variable },
      alarma: condicionInferior && condicionSuperior
    }
  }
  const parametroStr = (parametro, valor, variable) => {
    let condicion = false
    if (parametro.TieneAlerta) {
      if (parametro.Valor === valor) {
        condicion = true
      }
    }
    return {
      variable: { ...parametro, Variable: variable },
      alarma: condicion
    }
  }
  const parametroBool = (parametro, valor, variable) => {
    let condicion = false
    if (parametro.TieneAlerta) {
      if (parametro.Valor === valor) {
        condicion = true
      }
    }
    return {
      variable: { ...parametro, Variable: variable },
      alarma: condicion
    }
  }
  const getAlarmas = (alarmasConfig=[], variablesData={}, codigoGIO) => {
    if (alarmasConfig.length) {
      const alarmasGIO = alarmasConfig.find(j => j.Codigo === codigoGIO)
      if (!alarmasGIO) {
        return {
          ...variablesData,
          hasAlarma: false,
          alarmas: []
        }
      }
      const alarmas = []
      for (const alarma in alarmasGIO) {
        if (Object.hasOwnProperty.call(alarmasGIO, alarma) && alarma !== 'Codigo') {
          const variableParametros = alarmasGIO[alarma]
          for (const varParam of variableParametros) {
            const value = variablesData[alarma]
            const variable = listaVariables.find(i => i.variable === alarma)
            if (!variable) {
              continue
            }
            let alarmaResult = null
            if (variable.type === 'num') {
              alarmaResult = parametroNum(varParam, value, alarma)
            }
            if (variable.type === 'str') {
              alarmaResult = parametroStr(varParam, value, alarma)
            }
            if (variable.type === 'bool') {
              alarmaResult = parametroBool(varParam, value, alarma)
            }
            alarmaResult['variable']['Label'] = variable.label
            alarmas.push(alarmaResult)
          }
        }
      }
      return {
        hasAlarma: alarmas.some(i => i.alarma),
        alarmas: alarmas.filter(i => i.alarma).map(i => i.variable)
      }
    } else {
      return {
        hasAlarma: false,
        alarmas: []
      }
    }
  }
  const getLocations = async () => {
    const ubicaciones = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'ubicacion')
    const now = dayjs()
    const items = ubicaciones.map(locGIO => {
      const fecha = dayjs(locGIO.fecha_hora, 'YYYY-MM-DD HH:mm:ss')
      const diff = now.diff(fecha, 'seconds')
      return {
        ...locGIO,
        isOffline: diff >= 60,
        fecha_actualizacion: fecha.format('DD/MM/YY HH:mm:ss')
      }
    })
    .filter(dataGIO => Boolean(dataGIO.codigo) && Boolean(dataGIO.latitud) && Boolean(dataGIO.longitud))
    .map(dataGIO => {
      const alarmas = getAlarmas(alarmasMaquinaria, dataGIO, dataGIO.codigo)
      const horometro = horometros.find(i => i.codigo === dataGIO.codigo)
      return {
        ...dataGIO,
        ...alarmas,
        horometro: horometro ? horometro.horometro : null,
        horometro_ascensor: horometro ? horometro.horometro_ascensor : null,
        horometro_fecha: horometro ? horometro.fecha_hora : null
      }
    })
    .map(dataGIO => {
      const maq = maquinaria.find(j => j.Codigo === dataGIO.codigo)
      let gioOperacion = null
      let tecnicosGIO = {}
      if (dataGIO.hasOwnProperty('codigo_operacion')) {
        gioOperacion = {
          nombre_operacion: dataGIO.nombre_operacion,
          codigo_operacion: dataGIO.codigo_operacion,
          clasificacion_operacion: dataGIO.clasificacion_operacion,
          tiempo_transcurrido: dataGIO.tiempo_transcurrido,
          segundos_transcurridos: dataGIO.segundos_transcurridos,
          fecha_hora: dataGIO.fecha_hora
        }
      } else if (maq) {
        gioOperacion = operaciones.find(j => j.codigo_maquinaria === maq.Codigo)
      }
      if (maq) {
        tecnicosGIO = tecnicos.find(j => j.codigo === maq.Codigo) || {}
      }
      const alarmasTecnicas = getAlarmas(alarmasMaquinaria, tecnicosGIO, dataGIO.codigo)
      const tieneAlarma =  dataGIO.hasAlarma || alarmasTecnicas.hasAlarma
      const listAlarmas = [
        ...dataGIO.alarmas,
        ...alarmasTecnicas.alarmas
      ]
      let stoped = false
      if (gioOperacion) {
        const clasificacion = gioOperacion ? gioOperacion.clasificacion_operacion.toUpperCase() : null
        stoped = !['PRODUCTIVO', 'MANIOBRA'].includes(clasificacion)
        gioOperacion['tiempo_transcurrido'] = gioOperacion['tiempo_transcurrido']
          ? gioOperacion['tiempo_transcurrido'].split('.')[0]
          : '--'
      }
      return {
        ...tecnicosGIO,
        fecha_status: dayjs(tecnicosGIO['fecha_hora'], 'YYYY-MM-DD HH:mm:ss')
          .format('DD/MM/YY HH:mm:ss'),
        isStoped: stoped,
        operacion: gioOperacion,
        ...dataGIO,
        hasAlarma: tieneAlarma,
        alarmas: listAlarmas,
        maquinariaTipo: maq.TipoMaquinaria.toLowerCase()
      }
    }).map(dataGIO => {
      let estado = ''
      const operacion = dataGIO.operacion
      if (dataGIO.isOffline) {
        estado = 'offline'
      } else if (dataGIO.hasAlarma) {
        estado = 'alarma'
      } else if (operacion && dataGIO.isStoped) {
        estado = 'detenido'
      } else if (operacion && operacion.clasificacion_operacion.toUpperCase() == 'MANIOBRA') {
        estado = 'maniobra'
      } else {
        estado = 'ok'
      }
      return {
        ...dataGIO,
        estado
      }
    })
    puntos.current = items
    renderPuntos()
  }
  const renderPuntos = () => {
    clearMap()
    const markers = []
    for (const p of puntos.current) {
      if (!p.hasOwnProperty('latitud') ||
        !p.hasOwnProperty('longitud') ||
        !p['latitud'] ||
        !p['longitud']) {
        continue
      }
      let icono = window.L.icon({
        iconUrl: `./icons/marker_online_${p.maquinariaTipo}.svg`,
        iconSize:     [40, 40],
        popupAnchor:  [0, 0]
      })
      if (p.estado === 'offline') {
        icono =  window.L.icon({
          iconUrl: `./icons/marker_offline_${p.maquinariaTipo}.svg`,
          iconSize:     [40, 40],
          popupAnchor:  [0, 0]
        })
      } else if (p.estado === 'alarma') {
        icono = window.L.icon({
          iconUrl: `./icons/${p.maquinariaTipo}/${p.alarmas[0].Icono}`,
          iconSize:     [40, 40],
          popupAnchor:  [0, 0]
        })
        // icono = iconoAlert
      } else if (p.estado === 'detenido') {
        icono = window.L.icon({
          iconUrl: `./icons/marker_stop_${p.maquinariaTipo}.svg`,
          iconSize:     [40, 40],
          popupAnchor:  [0, 0]
        })
      } else if (p.estado === 'maniobra') {
        icono = window.L.icon({
          iconUrl: `./icons/marker_maniobra_${p.maquinariaTipo}.svg`,
          iconSize:     [40, 40],
          popupAnchor:  [0, 0]
        })
      }
      const marker = window.L.marker([p.latitud, p.longitud], {icon: icono})
        .on('click', () => {
          displayDetails(p.codigo)
          // if (mapa.current.scrollWheelZoom.enabled()) {
          //   mapa.current.scrollWheelZoom.disable();
          // } else if (!showDetail) {
          //   mapa.current.scrollWheelZoom.enable();
          // }
        })
      markers.push(marker)
    }
    if (location.latitude !== null && location.longitude !== null) {
      const icono = window.L.icon({
        iconUrl: `./icons/ubicacion.svg`,
        iconSize:     [40, 40],
        popupAnchor:  [0, 0]
      })
      const marker = window.L.marker([location.latitude, location.longitude], {icon: icono})
      marker.bindPopup("<b>Ubicación actual</b>").openPopup()
      markers.push(marker)
    }
    markersLayer.current = window.L.layerGroup(markers)
    markersLayer.current.addTo(mapa.current)
    if ((showDetail || showDetailMobile) && selected.current) {
      const dataGIO = puntos.current.find(i => i.codigo === selected.current)
      setDetail(Boolean(dataGIO) ? dataGIO : detalleDefault)
    }
  }
  const displayDetails = (codigoCosechadora) => {
    if (!isMobile()) {
      if (selected.current !== codigoCosechadora) {
        setShowDetail(true)
      } else {
        setShowDetail(val => !val)
      }
    } else {
      setShowDetailMobile(true)
    }
    // document.getElementById('barra-datos').scrollIntoView()
    const dataGIO = puntos.current.find(i => i.codigo === codigoCosechadora)
    setDetail(Boolean(dataGIO) ? dataGIO : detalleDefault)
    selected.current = codigoCosechadora
  }
  const getOperaciones = async () => {
    const body = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'operaciones')
    setOperaciones(body.map(i => {
      const tiempo = i.segundos_transcurrido
        ? new Date(i.segundos_transcurridos * 1000).toISOString().substr(11, 8)
        : null
      return {
        ...i,
        tiempo
      }
    }))
  }
  const getTecnicos = async () => {
    const body = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'status')
    setTecnicos(body)
  }
  const getAlarmasMaqunaria = async () => {
    const body = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'alarmas')
    setAlarmasMaquinaria(body)
  }
  const getHorometros = async () => {
    const body = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'horometros')
    setHorometros(body)
  }
  const getConfigs = async () => {
    try {
      const data = await http.get('/maestros/catalogo/configuraciones')
      setConfigs(data)
      const { parametros, tecnicos, ubicacion } = data
      setListaVariables([...parametros, ...tecnicos, ...ubicacion])
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const cerrarDialog = () => {
    setShowNavegacion(false)
    setShowBuscar(false)
  }
  const moverPunto = (punto) => {
    mapa.current.setView([punto.latitud, punto.longitud], 16)
    cerrarDialog()
    displayDetails(punto.codigo)
  }
  const refreshLocation = async () => {
    try {
      const location = await getLocation()
      const coords = location.coords
      setLocation({
        activate: true,
        latitude: coords.latitude,
        longitude: coords.longitude
      })
    } catch (error) {
      toast.error('No se pudo obtener la ubicación')
    }
  }
  const showLocation = async () => {
    if (!location.activate) {
      await refreshLocation()
      renderPuntos()
    } else {
      setLocation({
        activate: false,
        latitude: null,
        longitude: null
      })
    }
  }
  // ---------------------------------------------------------------------------
  // Effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (mapa.current === null) {
      mapa.current = window.L.map('map-location', {
        preferCanvas: true,
        zoomSnap: 0.5,
        attributionControl: false,
      }).setView([14.270985610779121, -91.09580037703911], 11)
      baseMap.current = window.L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          attribution: 'Tiles &copy; Esri &mdash',
          maxZoom: 18
        })
      baseMap.current.addTo(mapa.current)
      mapa.current.on('zoomend', () => {
        const zoomLevel = mapa.current.getZoom()
        if (labelsLayer.current != null) {
          if (zoomLevel >= 15 && !mapa.current.hasLayer(labelsLayer.current)) {
            labelsLayer.current.addTo(mapa.current)
          }
          if (zoomLevel < 15 && mapa.current.hasLayer(labelsLayer.current)) {
            labelsLayer.current.remove()
          }
        }
      })
      // window.L.control.attribution({
      //   position: 'bottomleft'
      // }).addTo(mapa.current)
    }
  }, [])
  useEffect(() => {
    (async () => {
      try {
        // Obtiene la configuracion de alarmas para todos los gios
        setLoading(true)
        const requestsDevices = [getMaquinaria(), getGios()]
        await Promise.all(requestsDevices)
        const requestsDatos = [
          getConfigs(),
          getZafraActual(),
          // getOperaciones(),
          // getTecnicos(),
          getLocations()
        ]
        await Promise.all(requestsDatos)
      } catch (error) {
        toast.error('Ha ocurrido un error 😅')
      } finally {
        setLoading(false)
      }
    })()
  }, [])
  useEffect(() => {
    if (maquinaria.length) {
      getAlarmasMaqunaria()
      getHorometros()
    }
  }, [maquinaria])
  // useEffect(() => {
  //   if (showDetail) {
  //     mapa.current.scrollWheelZoom.disable();
  //   } else {
  //     mapa.current.scrollWheelZoom.enable();
  //   }
  // }, [showDetail])
  useEffect(() => {
    if (zafraActual) {
      getShape()
    }
  }, [zafraActual])
  useEffect(() => {
    renderPuntos()
  }, [location])
  // ---------------------------------------------------------------------------
  // Invervals
  // ---------------------------------------------------------------------------
  useInterval(() => {
    getLocations()
    // getOperaciones()
  }, 5000)
  useInterval(() => {
    // getTecnicos()
    if (location.activate) {
      refreshLocation()
    }
  }, 10000)
  useInterval(() => {
    getHorometros()
  }, 60000)

  return <div id="map-location" className={classes.mapContainer}>
    <Paper className={[classes.mapDetail, classDetail]}>
      <IconButton
        className={classes.mapDetailClose}
        onClick={() => setShowDetail(v => !v)}
      >
        <Close />
      </IconButton>
      <BarraDatos dataGIO={detail} configs={configs} />
    </Paper>
    <LoadingBackdrop loading={loading} />
    <Dialog open={showDetailMobile} onClose={() => setShowDetailMobile(false)} fullWidth>
      <DialogContent>
        <BarraDatos dataGIO={detail} configs={configs} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDetailMobile(false)}>Cerrar</Button>
      </DialogActions>
    </Dialog>
    <Dialog fullWidth open={showNavegacion} onClose={cerrarDialog}>
      <NavegacionMapa
        items={puntos.current}
        onClose={cerrarDialog}
        mover={moverPunto}
      />
    </Dialog>
    <Dialog fullWidth open={showBuscar} onClose={cerrarDialog}>
      <BusquedaMapa
        items={puntos.current}
        onClose={cerrarDialog}
        mover={moverPunto}
      />
    </Dialog>
    <Fab size="medium" className={classes.btnLocation} onClick={() => showLocation()}>
      {location.activate ? <Stop /> : <GpsFixed />}
    </Fab>
    <Fab size="medium" className={classes.btnBuscar} onClick={() => setShowBuscar(true)}>
      <Search />
    </Fab>
    <Fab size="large" className={classes.btnNavegar} onClick={() => setShowNavegacion(true)}>
      <Explore />
    </Fab>
  </div>
}

export default MapaUbicacionTiempoReal
