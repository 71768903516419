import React, { useState, useEffect } from 'react'
import {
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import LoadingButton from '../Utils/LoadingButton'
import http from '../../Utils/http'
import { toast } from 'react-toastify'
import { getIdentity } from '../../Utils/auth'
import SelectZafra from '../Selects/SelectZafra'

const ShapefileUpdate = ({ values=null, onClose, onRefresh }) => {
  const [zafra, setZafra] = useState(null)
  const [form, setForm] = useState({
    Nombre: '',
    Descripcion: '',
    Zafra: ''
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading]= useState(false)
  const handleChange = (event) => {
    const { name, value } = event.target
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }))
  }
  const validate = () => {
    let tempErrors = {};
    if (!form.Nombre) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    if (!form.Zafra) {
      tempErrors.Zafra = 'Este campo es requerido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const submitForm = async () => {
    try {
      if (validate()) {
        setLoading(true)
        const body = {...form}
        body['Zafra'] = parseInt(body['Zafra'].Codigo)
        body['UsuarioModifico'] = getIdentity()
        await http.put(`/maestros/shapefile/${values.IdShapefile}`, body)
        onRefresh()
        toast.success('Registro guardado')
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (values) {
      setForm({
        Nombre: values.Nombre,
        Descripcion: values.Descripcion,
        Zafra: null
      })
      setZafra(values.Zafra)
    }
  } ,[])
  return <>
    <DialogTitle>
      Crear shapefile
    </DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre"
        name="Nombre"
        value={form.Nombre}
        onChange={handleChange}
        error={Boolean(errors.Nombre)}
        helperText={errors.Nombre}
      />
      <TextField
        fullWidth
        label="Descripción"
        name="Descripcion"
        value={form.Descripcion}
        onChange={handleChange}
        error={Boolean(errors.Descripcion)}
        helperText={errors.Descripcion}
      />
      <SelectZafra
        minZafra='2022'
        defaultValue={zafra}
        value={form.Zafra}
        onChange={value => handleChange({target: {name: 'Zafra', value}})}
        error={Boolean(errors.Zafra)}
        helperText={errors.Zafra}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default ShapefileUpdate