import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectFinca = ({ onSelect, codigoZona, error=false, helperText='', fullValue=false }) => {
  const { fincas } = useStoreState(state => ({
    fincas: state.fincas
  }))

  const { getFincas } = useStoreActions(actions => ({
    getFincas: actions.getFincas
  }))

  const [finca, setFinca] = useState('')
  const changeFinca = (ev, value) => {
    setFinca(value)
    if (value) {
      onSelect(fullValue ? value : value.Codigo)
    } else {
      onSelect(null)
    }
  }

  useEffect(() => {
    if (codigoZona) {
      getFincas(codigoZona)
      setFinca([])
    }
    onSelect(null)
    setFinca('')
  }, [codigoZona])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-finca"
        fullWidth
        options={fincas}
        defaultValue={null}
        getOptionLabel={(option) => option['Nombre'] ? option['Nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label="Finca"
          margin="none"
          helperText={helperText}
        />}
        value={finca}
        onChange={changeFinca}
      />
    </div>
  )
}

export default SelectFinca

export const SelectMultiFinca = ({ onSelect, zonas=[], error=false, helperText='' }) => {
  const { fincas } = useStoreState(state => ({
    fincas: state.fincas
  }))

  const { getFincasAll } = useStoreActions(actions => ({
    getFincasAll: actions.getFincasAll
  }))

  const [items, setItems] = useState([])
  const [selected, setSelected] = useState([])
  const changeFinca = (ev, value) => {
    if (value.length) {
      onSelect(value)
      setSelected(value)
    } else {
      onSelect([])
      setSelected([])
    }
  }

  useEffect(() => {
    getFincasAll()
  }, [])

  useEffect(() => {
    if (zonas.length) {
      const codigosZona = zonas.map(i => i.codigo)
      const tmpSelected = [...selected]
      setItems(fincas.filter(i => codigosZona.includes(i.codigoZona)))
      setSelected(tmpSelected.filter(i => codigosZona.includes(i.codigoZona)))
    } else {
      setItems(fincas)
      setSelected([])
      onSelect([])
    }
  }, [fincas, zonas])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        multiple
        fullWidth
        options={items}
        defaultValue={null}
        getOptionLabel={(option) => option['nombre'] ? option['nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label="Finca"
          margin="none"
          helperText={helperText}
        />}
        value={selected}
        onChange={changeFinca}
      />
    </div>
  )
}
