import React from 'react'
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'

const TablaTiposArchivo = ({ items=[], onDelete, onUpdate }) => {
  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell>Nombre</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Opciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          items.map((i, idx) => <TableRow key={idx}>
            <TableCell>{idx + 1}</TableCell>
            <TableCell>{i.Nombre}</TableCell>
            <TableCell>{i.Descripcion}</TableCell>
            <TableCell>
              <IconButton onClick={() => onUpdate(idx)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => onDelete(i.IdTipoArchivo)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>)
        }
      </TableBody>
    </Table>
  </TableContainer>
}

export default TablaTiposArchivo
