import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Grid,
  LinearProgress,
  Fab,
  makeStyles
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { toast } from 'react-toastify'
import http from '../../Utils/http'
import { getIdentity } from '../../Utils/auth'

import TablaTiposArchivo from '../../components/GIO/TablasTiposArchivo'
import Layout from '../../components/Layout/layout'
import TipoArchivoEdit from '../../components/GIO/TipoArchivoEdit'

const URL_TIPOS_ARCHIVO = 'https://jxsgv0fc0e.execute-api.us-east-1.amazonaws.com/v1/tipo-archivo'

const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    backgroundColor: "#69BD4B",
    color: "white"
  }
}))

const TiposArchivo = () => {
  const classes = useStyles()
  const [tiposArchivo, setTiposArchivo] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogUpdate, setDialogUpdate] = useState(false)
  const [selected, setSelected] = useState(null)
  const getTiposArchivo = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/tipo_archivo')
      // const {  data: { body } } = await axios2.get(URL_TIPOS_ARCHIVO)
      setTiposArchivo(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const deleteTipoArchivo = async (idTipoArchivo) => {
    if (window.confirm('¿Estas seguro de borrar el registro?')) {
      try {
        setLoading(true)
        await http.delete(`/maestros/tipo_archivo/${idTipoArchivo}`,
          {data: {UsuarioModifico: getIdentity()}})
        getTiposArchivo()
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setLoading(false)
      }
    }
    
  }
  const insertTipoArchivo = async (data) => {
    await http.post('/maestros/tipo_archivo', 
      { ...data, UsuarioCreo: getIdentity()})
  }
  const updateTipoArchivo = async (data) => {
    await http.put(`/maestros/tipo_archivo/${selected.IdTipoArchivo}`,
      {...data, UsuarioModifico: getIdentity()})
  }
  const showUpdate = (index) => {
    setSelected({...tiposArchivo[index]})
    setDialogUpdate(true)
  }
  const refresh = () => {
    getTiposArchivo()
    setDialogAdd(false)
    setDialogUpdate(false)
  }
  useEffect(() => {
    getTiposArchivo()
  }, [])
  return <Layout title="Tipos de archivo">
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <TablaTiposArchivo
          items={tiposArchivo}
          onDelete={deleteTipoArchivo}
          onUpdate={showUpdate}
        />
        {
          loading && <LinearProgress />
        }
      </Grid>
    </Grid>
    <Fab className={classes.fabButton} onClick={() => setDialogAdd(true)}>
      <Add />
    </Fab>
    <Dialog fullWidth maxWidth="md" open={dialogAdd}>
      <TipoArchivoEdit
        title="Agregar nuevo tipo de archivo"
        onClose={() => setDialogAdd(false)}
        onSubmit={insertTipoArchivo}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogUpdate}>
      <TipoArchivoEdit
        title="Editar tipo de archivo"
        onClose={() => setDialogUpdate(false)}
        onSubmit={updateTipoArchivo}
        onRefresh={refresh}
        initial={selected}
      />
    </Dialog>
  </Layout>
}

export default TiposArchivo