import React from 'react'
import { IconButton } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import {
  Edit,
  Delete,
  Check,
  Close
} from '@material-ui/icons'

const TablaOperaciones = ({ items, refresh, onUpdate, onDelete }) => {
  const CellOpciones = (cellData) => (
    <div>
      <IconButton onClick={() => onUpdate(cellData.data)} size="small">
        <Edit />
      </IconButton>
      <IconButton onClick={() => onDelete(cellData.data)} size="small">
        <Delete />
      </IconButton>
    </div>
  )
  const CellAutomatico = (cellData) => (
    <div>
      { cellData.data.EsAutomatico
        ? <Check style={{color: green[500]}} />
        : <Close style={{color: red[500]}} />}
    </div>
  )
  const CellProductivo = (cellData) => (
    <div>
      { cellData.data.EsProductivo
        ? <Check style={{color: green[500]}} />
        : <Close style={{color: red[500]}} />}
    </div>
  )
  return <DataGrid
    dataSource={items}
    showBorders
    showColumnLines
    rowAlternationEnabled
    alDataGridlowColumnResizing
  >
    <Column caption="Código" dataField="Codigo" />
    <Column caption="Nombre" dataField="Nombre" />
    <Column caption="Clasificación" dataField="Clasificacion" />
    <Column caption="Maquinaria" dataField="TipoMaquinaria" />
    <Column caption="Área agrícola" dataField="Area" />
    <Column caption="Productivo" cellRender={CellProductivo} />
    <Column caption="Automatico" cellRender={CellAutomatico} />
    <Column caption="Opciones" cellRender={CellOpciones} />
  </DataGrid>
}

export default TablaOperaciones
