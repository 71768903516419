import React, { useState, useEffect } from 'react'
import {
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import http from '../../Utils/http'

const TipoMaquinariaSelect = ({ value, onChange, defaultValue=null }) => {
  const [items, setItems] = useState([])
  const getItems = async () => {
    const data = await http.get('/maestros/tipo_maquinaria')
    setItems(data)
  }
  useEffect(() => {
    if (!items.length) {
      getItems()
    } else if (defaultValue) {
      onChange({target: {
        name: 'TipoMaquinaria',
        value: items.find(i => i.IdTipoMaquinaria === defaultValue)
      }})
    }
  }, [items])
  return <Autocomplete
    name="TipoMaquinaria"
    options={items}
    getOptionLabel={(option) => option.Nombre}
    renderInput={(params) => (
      <TextField {...params}  label="Tipo de maquinaria" />
    )}
    value={value}
    onChange={(ev, value) => onChange({target: {name: 'TipoMaquinaria', value}})}
  />
}

export default TipoMaquinariaSelect