import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  Button,
  Grid
} from '@material-ui/core';
import DefaultSettings from './DefaultSettings.js'
import { toast } from 'react-toastify';

const SettingsUsuario = () => {
  const [settings, setSettings] = useState(DefaultSettings)
  const saveSettings = () => {
    localStorage.setItem('settings', JSON.stringify(settings))
    window.location.reload()
    toast.success('Configuración guardada')
  }
  useEffect(() => {
    const localSettings = localStorage.getItem('settings')
    if (localSettings) {
      setSettings(JSON.parse(localSettings))
    }
  }, [])
  return (
    <Paper style={{padding: 10}}>
      <Typography variant="h6" component="p">Configuraciones de usuario</Typography>
      <Grid container spacing={1}>
      {
        settings.map((setting, index) => <Grid item xs={12} key={index}>
          {
          setting.tipo == 'bool' ?
          <FormControlLabel
            key={index}
            control={<Switch
              checked={setting.valor}
              onChange={(e) => {
                const newSettings = [...settings]
                newSettings[index].valor = e.target.checked
                setSettings(newSettings)
              }}
            />}
            label={setting.titulo}
          /> :
          <TextField
            key={index}
            label={setting.titulo}
            type={setting.tipo}
            value={setting.valor}
            onChange={(e) => {
              const newSettings = [...settings]
              newSettings[index].valor = e.target.value
              setSettings(newSettings)
            }}
          />
          }
          </Grid>
        )
      }
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        style={{marginTop: 10}}
        onClick={() => {
          saveSettings()
        }}
      >
        Guardar
      </Button>
    </Paper>
  );
}

export default SettingsUsuario;
