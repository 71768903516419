import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Fab,
  Box,
  makeStyles,
  Typography
} from '@material-ui/core'
import TablaOperaciones from '../../components/GIO/TablaOperaciones'
import Layout from '../../components/Layout/layout'
import { toast } from 'react-toastify'
import { LinearProgress } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import OperacionEdit from '../../components/GIO/OperacionEdit'
import { getIdentity } from '../../Utils/auth'
import http from '../../Utils/http'

const useStyles = makeStyles(theme => ({
  fabButton: {
    backgroundColor: "#69BD4B",
    color: "white"
  },
  title: {
    padding: 10
  }
}))

const MantenimientoOperaciones = () => {
  const classes = useStyles()
  const [operaciones, setOperaciones] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const getOperaciones = async () => {
    try {
      setLoading(true)
      const data = await http.get(`/maestros/operacion`)
      setOperaciones(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const deleteOperacion = async (data) => {
    if (window.confirm('¿Estas seguro de borrar el registro?')){
      try {
        await http.delete(`/maestros/operacion/${data.IdOperacion}`, {data:{
          UsuarioModifico: getIdentity()
        }})
        getOperaciones()
      } catch (error) {
        toast.error('Ha ocurrido un error')
        getOperaciones()
      }
    }
  }
  const editOperacion = (data) => {
    const item = operaciones.find(i => i.IdOperacion === data.IdOperacion)
    setSelected(item)
    setDialogEdit(true)
  }
  const saveOperacion = async (data) => {
    const body = {...data, UsuarioCreo: getIdentity()}
    await http.post('/maestros/operacion', body)
  }
  const updateOperacion = async (data) => {
    const body = {...data, UsuarioModifico: getIdentity()}
    await http.put(`/maestros/operacion/${selected.IdOperacion}`, body)
  }
  const refresh = () => {
    getOperaciones()
    setDialogAdd(false)
    setDialogEdit(false)
  }
  useEffect(() => {
    getOperaciones()
  }, [])
  return <Layout title="Operaciones en campo (motivos de parada)">
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.title}
    >
      <Typography variant="h5">Motivos de parada</Typography>
      <Fab
        size="small"
        className={classes.fabButton}
        onClick={() => setDialogAdd(true)}
      >
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <TablaOperaciones
      items={operaciones}
      onDelete={deleteOperacion}
      onUpdate={editOperacion}
    />
    <Dialog open={dialogAdd}>
      <OperacionEdit
        title="Agregar nueva operación (motivo de parada)"
        onClose={() => setDialogAdd(false)}
        onSubmit={saveOperacion}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog open={dialogEdit}>
      <OperacionEdit
        update
        values={selected}
        title="Modificar operación (motivo de parada)"
        onClose={() => setDialogEdit(false)}
        onSubmit={updateOperacion}
        onRefresh={refresh}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoOperaciones
