import { action, thunk } from 'easy-peasy'
import http from '../../Utils/http'

export default {
  tiposArchivo: [],
  gios: [],
  setTiposArchivo: action((state, payload) => {
    state.tiposArchivo = payload
  }),
  setGios: action((state, payload) => {
    state.gios = payload
  }),
  getTiposArchivo: thunk(async (actions, _, { getStoreState }) => {
    const { tiposArchivo } = getStoreState()
    if (!tiposArchivo.length) {
      const data = await http.get('/maestros/tipo_archivo')
      actions.setTiposArchivo(data)
    }
  }),
  getGios: thunk(async (actions, _, { getStoreState }) => {
    try {
      const { gios } = getStoreState()
      if (!gios.length) {
        const data = await http.get('/maestros/gio')
        actions.setGios(data)
      }
    } catch (error) {
      throw error
    }
  })
}