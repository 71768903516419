import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { toast } from 'react-toastify'
import LoadingButton from '../Utils/LoadingButton'

const PermisoEdit = ({ title, values, onSubmit, onRefresh, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    Nombre: '',
    Clave: ''
  })

  const [errors, setErrors] = useState({})
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }
  const validate = () => {
    let tempErrors = {};
    if (!formData.Nombre) {
      tempErrors.Nombre = 'Este campo es requerido';
    }
    if (!formData.Clave) {
      tempErrors.Clave = 'Este campo es requerido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const submitForm = async () => {
    try {
      setLoading(true)
      if (validate()) {
        await onSubmit(formData)
        onRefresh()
        toast.success('Registro guardado')
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (values) {
      setFormData({
        Nombre: values.Nombre,
        Clave: values.Clave
      })
    }
  },[])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        name="Nombre"
        label="Nombre"
        value={formData.Nombre}
        onChange={handleChange}
        error={Boolean(errors.Nombre)}
        helperText={errors.Nombre && errors.Nombre}
      />
      <TextField
        fullWidth
        name="Clave"
        label="Clave"
        value={formData.Clave}
        onChange={handleChange}
        error={Boolean(errors.Clave)}
        helperText={errors.Clave && errors.Clave}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        variant="contained"
        loading={loading}
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default PermisoEdit
