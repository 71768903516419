import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Typography,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { useStoreState, useStoreActions } from 'easy-peasy'
import useInterval from '../../hooks/useInterval'
import dayjs from 'dayjs'
import {default as axios2} from 'axios'

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '85vh',
    width: '100%',
    position: 'relative'
  },
  mapDetail: {
    position: 'absolute',
    height: '100%',
    width: '25%',
    top: 0,
    zIndex: 500,
    padding: theme.spacing(2),
    transition: 'all 0.3s',
    borderRadius: 0
  },
  mapDetailClose: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  mapDetailHide: {
    right: '-25%'
  },
  mapDetailShow: {
    right: 0
  }
}))

const CODIGOS = {'C18': 'T0502-0018', 'C19': 'T0502-0019'}
const URL_BASE = 'https://jxsgv0fc0e.execute-api.us-east-1.amazonaws.com/v1'
let VARS = ['velocidad_gps', 'rpm_motor']

const MapaUbicacion = () => {
  const classes = useStyles()
  const { axios, axiosAWS, variables } = useStoreState(state => ({
    axios: state.axios,
    axiosAWS: state.axiosAWS,
    variables: state.variables
  }))
  const { getVariables } = useStoreActions(actions => ({
    getVariables: actions.getVariables
  }))
  const mapa = useRef(null)
  const shape = useRef(null)
  const baseMap = useRef(null)
  const puntos = useRef([])
  const markersLayer = useRef(null)
  const selected = useRef(null)
  const [gios, setGios] = useState([])
  const [operaciones, setOperaciones] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [detail, setDetail] = useState({
    cosechadora: '--',
    latitud: '--',
    longitud: '--',
    parametros: {},
    hasAlarma: false,
    alarmas: []
  })
  const classDetail = showDetail ? classes.mapDetailShow : classes.mapDetailHide
  const getShape = async () => {
    const { data } = await axios.get(`/geojson/shape_22-23.json`)
    shape.current = window.L.geoJSON(data, {
        style: (feature) => {
        return {
          color: '#faa307',
          fillOpacity: 0,
          weight: 1
        }
      }
    })
    shape.current.addTo(mapa.current)
  }
  const clearMap = () => {
    if (markersLayer.current !== null) {
      markersLayer.current.clearLayers()
    }
  }
  const getLocations = async () => {
    const { data: {body} } = await axios2.get(`${URL_BASE}/ubicacion`)
    const now = dayjs()
    const items = body.map(i => {
      const fecha = dayjs(i.parametros.fecha_hora)
      const diff = now.diff(fecha, 'seconds')
      return {
        ...i,
        isOffline: diff >= 60
      }
    }).map(i => {
      if (CODIGOS.hasOwnProperty(i.cosechadora) && variables.length) {
        const alarmas = []
        for (const v of VARS) {
          const variable = variables
            .find(j => j.CodigoGIO === CODIGOS[i.cosechadora] && v === j.Nombre)
          if (!variable) continue
          const value = i.parametros[v]
          for (const p of variable.Parametros) {
            let condicionInferior = false
            let condicionSuperior = false
            if (p.TieneAlerta) {
              if ((p.InferiorIncluyente && value >= p.Inferior) ||
                (!p.InferiorIncluyente && value > p.Inferior)) {
                condicionInferior = true
              }
              if ((p.SuperiorIncluyente && value <= p.Superior) ||
                (!p.SuperiorIncluyente && value < p.Superior)) {
                condicionSuperior = true
              }
            }
            alarmas.push({
              variable: v,
              alarma: condicionInferior && condicionSuperior
            })
          }
        }
        return {
          ...i,
          hasAlarma: alarmas.some(i => i.alarma),
          alarmas: alarmas.filter(i => i.alarma).map(i => i.variable)
        }
      } else {
        return {
          ...i,
          hasAlarma: false,
          alarmas: []
        }
      }
    }).map(i => {
      const maq = gios.find(j => j.CodigoTemp === i.cosechadora)
      const op = maq ? operaciones.find(j => j.codigo_maquinaria === maq.Codigo) : null
      let operacion = null
      let stoped = false
      if (op) {
        const now = dayjs()
        const fecha = dayjs(op.fecha_hora, 'YYYY-MM-DD HH:mm:ss')
        const diff = now.diff(fecha,'minutes')
        operacion = diff <= 5 ? op : null
        stoped = operacion && operacion.tipo === 'motivo_parada'
      }
      return {
        ...i,
        isStoped: stoped,
        operacion
      }
    })
    puntos.current = items
    renderPuntos()
  }
  const renderPuntos = () => {
    clearMap()
    const iconoOnline = window.L.icon({
      iconUrl: `./icons/marker_online.svg`,
      iconSize:     [40, 40],
      popupAnchor:  [0, 0]
    })
    const iconoOffline = window.L.icon({
      iconUrl: `./icons/marker_offline.svg`,
      iconSize:     [40, 40],
      popupAnchor:  [0, 0]
    })
    const iconoAlert = window.L.icon({
      iconUrl: `./icons/marker_alert.svg`,
      iconSize:     [40, 40],
      popupAnchor:  [0, 0]
    })
    const iconoStop = window.L.icon({
      iconUrl: `./icons/marker_stop.svg`,
      iconSize:     [40, 40],
      popupAnchor:  [0, 0]
    })
    const markers = []
    for (const p of puntos.current) {
      let icono = iconoOnline
      if (p.isOffline && !p.isStoped) {
        icono = iconoOffline
      } else if (p.hasAlarma) {
        icono = iconoAlert
      } else if (p.operacion) {
        icono = iconoStop
      }
      const marker = window.L.marker([p.latitud, p.longitud], {icon: icono})
        .on('click', () => {
          displayDetails(p.cosechadora)
        })
      markers.push(marker)
    }
    markersLayer.current = window.L.layerGroup(markers)
    markersLayer.current.addTo(mapa.current)
    if (showDetail && selected.current) {
      setDetail(puntos.current.find(i => i.cosechadora === selected.current))
    }
  }
  const displayDetails = (codigoCosechadora) => {
    if (selected.current !== codigoCosechadora) {
      setShowDetail(true)
    } else {
      setShowDetail(val => !val)
    }
    setDetail(puntos.current.find(i => i.cosechadora === codigoCosechadora))
    selected.current = codigoCosechadora
  }
  const getGIOs = async () => {
    const {data:{body}} = await axios2.get(`${URL_BASE}/gio`)
    setGios(body)
  }
  const getOperaciones = async () => {
    const {data:{body}} = await axios2.get(`${URL_BASE}/operacion-live`)
    setOperaciones(body.map(i => {
      const tiempo = new Date(i.segundos_transcurridos * 1000).toISOString().substr(11, 8)
      return {
        ...i,
        tipo: 'motivo_parada',
        tiempo
      }
    }))
  }
  useEffect(() => {
    if (mapa.current === null) {
      mapa.current = window.L.map('map-location', {
        preferCanvas: true,
        zoomSnap: 0.5,
        attributionControl: false
      }).setView([14.270985610779121, -91.09580037703911], 11)
      baseMap.current = window.L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          attribution: 'Tiles &copy; Esri &mdash',
          maxZoom: 18
        })
      baseMap.current.addTo(mapa.current)
      // window.L.control.attribution({
      //   position: 'bottomleft'
      // }).addTo(mapa.current)
      getShape()
    }
    getVariables()
  }, [])
  useInterval(() => {
    getLocations()
  }, 5000)
  useInterval(() => {
    // getOperaciones()
  }, 30000)
  useEffect(() => {
    // getOperaciones()
    getGIOs()
  }, [])

  return <div id="map-location" className={classes.mapContainer}>
    <Paper className={[classes.mapDetail, classDetail]}>
      <IconButton
        className={classes.mapDetailClose}
        onClick={() => setShowDetail(v => !v)}
      >
        <Close />
      </IconButton>
      {
        detail.isOffline && <Typography variant="h6" style={{color: red['500']}}>
          Actualmente fuera de línea
        </Typography>
      }
      <Typography variant="h6">
        Cosechadora: {detail.cosechadora}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Finca: </strong>{detail.parametros.nombre_finca || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Unidad de manejo: </strong>{detail.parametros.um || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Latitud: </strong>{detail.latitud}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Longitud: </strong>{detail.longitud}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Altitud: </strong>{detail.parametros.altitud || '--'}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: detail.alarmas.includes('velocidad_gps') ? red[500] : '#000'
        }}
      >
        <strong>Velocidad: </strong>{detail.parametros.velocidad_gps || '--'} km/h
      </Typography>
      <Typography variant="subtitle1">
        <strong>{
          detail.operacion && detail.operacion.tipo === 'motivo_parada'
            ? 'Motivo de parada: '
            : 'Operación: ' }
        </strong>{
          detail.operacion
          ? `${detail.operacion.nombre_operacion}`
          : '--'
          }
      </Typography>
      <Typography variant="subtitle1">
        <strong>Tiempo transcurrido: </strong>{detail.operacion ? detail.operacion.tiempo : '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Consumo de combustible: </strong>{detail.parametros.longitud || '--'}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: detail.alarmas.includes('rpm_motor') ? red[500] : '#000'
        }}
      >
        <strong>RPM motor: </strong>{detail.parametros.rpm_motor} rpm
      </Typography>
      <Typography variant="subtitle1">
        <strong>Porcentaje torque actual: </strong>{detail.parametros.porcentaje_torque_actual || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Porcentaje torque demandado: </strong>{detail.parametros.porcentaje_torque_demandado || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Temperatura aceite motor: </strong>{detail.parametros.temperatura_aceite_motor || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Temperatura ambiente: </strong>{detail.parametros.temperatura_ambiente || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Temperatura combustible: </strong>{detail.parametros.temperatura_combustible || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Temperatura refrigerante: </strong>{detail.parametros.temperatura_refrigerante || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Presión de aceite de motor: </strong>{detail.parametros.presion_aceite_motor || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Presión de aire de ingreso: </strong>{detail.parametros.presion_aire_ingreso || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Voltaje bateria: </strong>{detail.parametros.voltaje_bateria || '--'}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Ultima actualización: </strong>
        {
          detail.parametros.fecha_hora
            ? dayjs(detail.parametros.fecha_hora).format('DD/MM/YY HH:mm:ss')
            : '--'
        }
      </Typography>
    </Paper>
  </div>
}

export default MapaUbicacion
