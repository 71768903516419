import React from 'react'
import {
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  Box,
  IconButton,
  useTheme,
  makeStyles
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import IconSelector from './IconSelector'

const ParamEdit = ({ value, onChange, errors, onDelete, index }) => {
  const theme = useTheme()
  return <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        paddingLeft: 10,
        marginTop: 10,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <Typography variant="h6">Parametro</Typography>
      <IconButton
        onClick={onDelete}
        style={{color: theme.palette.error.main}}
      >
        <Delete />
      </IconButton>
    </Box>
    <Grid container>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={value.Parametro}
          onChange={ev => onChange('Parametro', ev.target.value, index)}
          label="Parámetro"
          error={errors.Parametro}
          helperText={errors.Parametro && 'El campo es requerido'}
        />
        <FormControlLabel
          control={<Switch
            checked={value.TieneAlerta}
            onChange={ev => onChange('TieneAlerta', ev.target.checked, index)}
          />}
          label="Alertar en este parámetro"
        />
        <TextField
          fullWidth
          value={value.Inferior}
          onChange={ev => onChange('Inferior', ev.target.value, index)}
          label="Límite inferior"
          type="number"
          error={errors.Inferior}
          helperText={errors.Inferior && 'El campo es requerido'}
        />
        <FormControlLabel
          control={<Switch
            checked={value.InferiorIncluyente}
            onChange={ev => onChange('InferiorIncluyente', ev.target.checked, index)}
          />}
          label="Incluyente"
        />
        <TextField
          fullWidth
          value={value.Superior}
          onChange={ev => onChange('Superior', ev.target.value, index)}
          label="Límite superior"
          type="number"
          error={errors.Superior}
          helperText={errors.Superior && 'El campo es requerido'}
        />
        <FormControlLabel
          control={<Switch
            checked={value.SuperiorIncluyente}
            onChange={ev => onChange('SuperiorIncluyente', ev.target.checked, index)}
          />}
          label="Incluyente"
        />
      </Grid>
      <Grid item xs={6}>
        <IconSelector
          value={value.Icono}
          onChange={ev => onChange('Icono', ev.target.value, index)}
          disabled={!value.TieneAlerta}
        />
      </Grid>
    </Grid>
  </>
}

export default ParamEdit