import { action, thunk } from 'easy-peasy'
import http from '../Utils/http'

export default {
  variablesConfig: {},
  setVariablesConfig: action((state, payload) => {
    state.variablesConfig = payload
  }),
  getVariablesConfig: thunk(async (actions, _, { getStoreState }) => {
    const { variablesConfig } = getStoreState()
    if (Object.keys(variablesConfig).length > 0) return
    const body = await http.get('/maestros/catalogo/configuraciones')
    actions.setVariablesConfig(body)
  })
}
