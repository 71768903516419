export default [
  {
    "tipo": "bool",
    "nombre": "mostrar_um",
    "valor": true,
    "titulo": "Mostrar UM",
    "modulo": "mapa_live",
  },
  {
    "tipo": "bool",
    "nombre": "mostrar_props",
    "valor": true,
    "titulo": "Mostrar datos de polígonos",
    "modulo": "mapa_live",
  }
]