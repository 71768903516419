import React from 'react'
import {
  Typography
} from '@material-ui/core'

import Layout from '../../components/Layout/layout'
import MapaUbicacionTiempoReal from '../../components/GIO/MapaUbicacionTiempoReal'
import { isMobile } from '../../Utils/functions'

const MapaTiempoReal = () => {
  return <Layout title="Mapa de ubicación en tiempo real">
    {
      !isMobile() && <Typography variant="h6" component="p">Ubicación en tiempo real</Typography>
    }
    <MapaUbicacionTiempoReal />
  </Layout>
}

export default MapaTiempoReal
