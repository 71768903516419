import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Fab,
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import Layout from '../../components/Layout/layout'
import GIOTabla from '../../components/Maestros/GIOTabla'
import http from '../../Utils/http'
import { toast } from 'react-toastify'
import { getIdentity } from '../../Utils/auth'
import GIOEdit from '../../components/Maestros/GIOEdit'
import { Add } from '@material-ui/icons'

const MantenimientoGIO = () => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [dialogCreate, setDialogCreate] = useState(false)
  const getItems = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/gio')
      setItems(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const showEdit = (index) => {
    setSelected({...items[index]})
    setDialogEdit(true)
  }
  const deleteItem = async (index) => {
    if (window.confirm('¿Estas seguro de eliminar el registro?')) {
      try {
        const id  = items[index].IdGIO
        await http.delete(`/maestros/gio/${id}`, {data: {UsuarioModifico: getIdentity()}})
        getItems()
      } catch (error) {
        console.log(error)
        toast.error('Ha ocurrido un error')
      }
    }
  }
  const refresh = () => {
    getItems()
    setDialogCreate(false)
    setDialogEdit(false)
  }
  const createGIO = async (form) => {
    await http.post('/maestros/gio', {
      ...form,
      UsuarioCreo: getIdentity()
    })
  }
  const updateGIO = async (form) => {
    await http.put(`/maestros/gio/${selected.IdGIO}`, {
      ...form,
      UsuarioModifico: getIdentity()
    })
  }
  useEffect(() => {
    getItems()
  }, [])
  return <Layout title="Dispositivos GIO">
    <Box display="flex" justifyContent="space-between" style={{padding: 10}}>
      <Typography variant="h6" component="p">Lista de GIOs</Typography>
      <Fab
        size="small"
        color="secondary"
        style={{background: green[500]}}
        onClick={() => setDialogCreate(true)}
      >
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <GIOTabla
      items={items}
      onEdit={showEdit}
      onDelete={deleteItem}
    />
    <Dialog fullWidth maxWidth="md" open={dialogCreate}>
      <GIOEdit
        title="Crear GIO"
        onClose={() => setDialogCreate(false)}
        onSubmit={createGIO}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogEdit}>
      <GIOEdit
        title="Editar GIO"
        onClose={() => setDialogEdit(false)}
        values={selected}
        onSubmit={updateGIO}
        onRefresh={refresh}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoGIO
