import React, { useState, useEffect } from 'react'
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Layout from '../../components/Layout/layout'
import LoadingButton from '../../components/Utils/LoadingButton'
import { useStoreActions } from 'easy-peasy'
import { useForm } from 'react-hook-form'
import http from '../../Utils/http'
import SettingsUsuario from './SettingsUsuario'

const Cuenta = () => {
  const { showMsg, showError } = useStoreActions(actions => ({
    showMsg: actions.showMsg,
    showError: actions.showError
  }))
  const [loading, setLoading] = useState(false)
  const [hasPassword, setHasPassword] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  const onSubmit = async (data) => {
    if (data.password != data.repeatPassword) {
      showMsg({
        type: 'ERROR',
        text: 'Las constraseñas no coinciden'
      })
      return
    }
    try {
      setLoading(true)
      await http.post('/maestros/auth/change_password', {
        Password: data.password,
        RepeatPassword: data.repeatPassword
      })
      reset()
      showMsg({
        type: 'SUCCESS',
        text: 'Contraseña actualizada'
      })
    } catch (error) {
      showError()
    } finally{
      setLoading(false)
    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('authData'))
    setHasPassword(user.TienePassword || false)
  }, [])
  return <Layout title="Cuenta de usuario">
    <Grid container justify="center" spacing={3}>
      <Grid item xs={12} md={6}>
      {
        hasPassword
        ? <>
          <Typography variant="h6" component="p">Actualizar contraseña</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl margin="none" required fullWidth>
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                inputProps={{ minLength: 6 }}
                autoComplete="password"
                {...register("password")}
              />
            </FormControl>
            <FormControl margin="none" required fullWidth>
              <InputLabel htmlFor="repeatPassword">Repetir contraseña</InputLabel>
              <Input
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                inputProps={{ minLength: 6 }}
                autoComplete="repeatPassword"
                {...register("repeatPassword")}
              />
            </FormControl>
            <LoadingButton
              fullWidth
              loading={loading}
              type="submit"
              color="primary"
              variant="contained"
              style={{marginTop: '1em'}}
            >
              Guardar
            </LoadingButton>
          </form>
        </>
        : <Alert severity="info" variant="filled">No tienes opciones de configuración</Alert>
      }
      </Grid>
      <Grid item xs={12} md={6}>
        <SettingsUsuario />
      </Grid>
    </Grid>
  </Layout>
}

export default Cuenta
