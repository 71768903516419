import React from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { tipos } from './MapaConfig'

const SelectTipoMapa = ({ value, onChange, remove=[] }) => {
  const items = tipos.filter(i => !remove.includes(i['value']))
  return <FormControl fullWidth>
    <InputLabel>Tipo de mapa</InputLabel>
    <Select
      value={value}
      onChange={ev => onChange(ev.target.value)}
    >
      {items.map(i => <MenuItem value={i.value} key={i.value}>{i.label}</MenuItem>)}
    </Select>
  </FormControl>
}

export default SelectTipoMapa
