import { action, thunk } from 'easy-peasy'
import http from '../Utils/http'

export default {
  maquinaria: [],
  alarmasMaquinaria: [],
  setMaquinaria: action((state, payload) => {
    state.maquinaria = payload
  }),
  setAlarmasMaquinaria: action((state, payload) => {
    state.alarmasMaquinaria = payload
  }),
  getMaquinaria: thunk(async (actions) => {
    try {
      const body = await http.get('/maestros/maquinaria')
      actions.setMaquinaria(body)
    } catch (error) {
      throw error
    }
  })
}