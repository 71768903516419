import React, { useState } from 'react';
import { Grid, Paper, makeStyles, Tooltip, Typography, IconButton } from '@material-ui/core';
import {
  FiberManualRecord,
  ArrowDropDownCircle
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
  return {
    legendContainer: {
      display: 'flex',
      width: "fit-content",
      flexDirection: 'column',
      position: 'absolute',
      right: '1.5rem',
      top: '1.8rem',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      padding: '0 1rem',
      zIndex: 500
    },
    legendas: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem'
    },
    btn: {
      position: 'absolute',
      boxShadow: 'none',
      top: '-10px',
      right: '-10px',
      backgroundColor: '#f6f6f6',
      zIndex: '900',
    },
    item: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      marginTop: '0.5rem',
      marginBottom: '0.5rem'
    },
    icons: {
      fontSize: '14pt',
      marginRight: '0.5rem'
    },
    arrowUp: {
      transform: 'rotate(180deg)'
    },
    linea: {
      height: "0.3rem",
      width: "1.3rem",
      borderRadius: "0.5rem",
      marginRight: '0.5rem'
    }
  };
});

const LineaComponent = ({ classes, color }) => (
  <div style={{ backgroundColor: color || "#000" }} className={classes.linea}></div>
)

const Legenda = ({ linea, legendas, openModal }) => {
  const classes = useStyles();

  const [mostrar, setMostrar] = useState(true)

  return (
    <Grid container component={Paper} elevation={3} className={classes.legendContainer}>
      {(mostrar && legendas && legendas.length)
        ? <div className={classes.legendas}>
          {legendas.map((row, index) => (
            <div key={index} className={classes.item}>
              {linea ?
                <LineaComponent classes={classes} color={row.color} />
                :
                <FiberManualRecord className={classes.icons} style={{ color: row.color || "#000" }} />
              }
              <Typography color="primary">{row.label || ''}</Typography>
            </div>
          ))}
        </div>
        : undefined
      }
      <Tooltip title={mostrar ? 'Ocultar legendas' : 'Mostrar legendas'}>
        <IconButton size="small" onClick={() => setMostrar(!mostrar)} className={classes.btn}>
          {mostrar ? <ArrowDropDownCircle className={classes.arrowUp} /> : <ArrowDropDownCircle />}
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

export default Legenda;
