import { action } from 'easy-peasy';

// Actions
const setLoading = action((state, payload) => {
  state.loading = payload;
});

const setMe = action((state, payload) => {
  state.me = payload;
});

const auth = {
  loading: false,
  me: {},
  setLoading,
  setMe,
};

export default auth;