import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../../components/Layout/layout'
import http from '../../Utils/http'
import {
  LinearProgress,
  Box,
  Typography,
  Fab,
  Dialog,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Add, Edit, ListAlt } from '@material-ui/icons'
import { toast } from 'react-toastify'
import RolEdit from '../../components/Admin/RolEdit'
import { getIdentity } from '../../Utils/auth'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { useStoreState, useStoreActions } from 'easy-peasy'
import RolPermisosEdit from '../../components/Admin/RolPermisosEdit'

const useStyles = makeStyles(theme => ({
  fab: {
    background: green[500],
    color: '#FFF'
  },
  title: {
    padding: 5
  }
}))


const MantenimientoPermisos = () => {
  const classes = useStyles()
  const { roles } = useStoreState(state => ({
    roles: state.roles
  }))
  const { getRoles } = useStoreActions(actions => ({
    getRoles: actions.getRoles
  }))
  const [loading, setLoading] = useState(false)
  const [dgCrear, setDgCrear] = useState(false)
  const [dgEditar, setDgEditar] = useState(false)
  const [dgPermisos, setDgPermisos] = useState(false)
  const [selected, setSelected] = useState(null)
  const items = roles.map(i => ({...i, ListaPermisos: i.Permisos.map(j => j.Nombre).join(', ')}))
  const getItems = async () => {
    try {
      setLoading(true)
      await getRoles()
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const insertItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioCreo: getIdentity()
      }
      await http.post('/maestros/rol', body)
    } catch (error) {
      throw error
    }
  }
  const updateItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioModifico: getIdentity()
      }
      await http.put(`/maestros/rol/${selected.IdRol}`, body)
    } catch (error) {
      throw error
    }
  }
  const refresh = () => {
    getItems()
    setDgCrear(false)
    setDgEditar(false)
    setDgPermisos(false)
  }
  const editItem = useCallback((cellData) => {
    const { data } = cellData
    setSelected(data)
    setDgEditar(true)
  })
  const permisosItem = useCallback((cellData) => {
    const { data } = cellData
    setSelected(data)
    setDgPermisos(true)
  })
  const savePermisos = (permisos) => {
    console.log(permisos)
  }
  useEffect(() => {
    getItems()
  }, [])
  const CustomCell = (cellData) => (
    <div>
      <IconButton onClick={() => editItem(cellData)} size="small">
        <Edit />
      </IconButton>
      <IconButton onClick={() => permisosItem(cellData)} size="small">
        <ListAlt />
      </IconButton>
    </div>
  )
  return <Layout title="Roles">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.title}
    >
      <Typography variant="h5">Roles de usuario</Typography>
      <Fab
        size="small"
        color="inherit"
        className={classes.fab}
        onClick={() => setDgCrear(true)}
      >
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <DataGrid id="gridRoles"
      dataSource={items}
      showBorders
      showColumnLines
      rowAlternationEnabled
    >
      <Column caption="Id" dataField="IdRol" />
      <Column caption="Rol" dataField="Nombre" />
      <Column caption="Permisos" dataField="ListaPermisos" />
      <Column caption="Opciones" cellRender={CustomCell} />
    </DataGrid>
    <Dialog open={dgCrear} maxWidth="sm" fullWidth>
      <RolEdit
        title="Crear rol"
        onClose={() => setDgCrear(false)}
        onSubmit={insertItem}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog open={dgEditar} maxWidth="sm" fullWidth>
      <RolEdit
        title="Editar rol"
        onClose={() => setDgEditar(false)}
        onSubmit={updateItem}
        onRefresh={refresh}
        values={selected}
      />
    </Dialog>
    <Dialog open={dgPermisos} maxWidth="sm" fullWidth>
      <RolPermisosEdit
        values={selected}
        onClose={() => setDgPermisos(false)}
        onSubmit={savePermisos}
        onRefresh={refresh}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoPermisos