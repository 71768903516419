import React, { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  LinearProgress,
  makeStyles,
  Box,
  CircularProgress
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { toast } from 'react-toastify'
import http from '../../Utils/http'
const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1)
  },
  icono: {
    height: 40,
    width: 40
  }
}))

const VariablesShow = ({ onClose, values }) => {
  const { variablesConfig } = useStoreState(state => ({
    variablesConfig: state.variablesConfig
  }))
  const { getVariablesConfig } = useStoreActions(actions => ({
    getVariablesConfig: actions.getVariablesConfig
  }))
  const classes = useStyles()
  const [vars, setVars] = useState(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const getVariables = async (codigo) => {
    try {
      setLoading(true)
      const body = await http.get(`/maestros/alarma/${codigo}`)
      setVars(body)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (Object.values(variablesConfig).length && vars) {
      const keys = Object.keys(vars)
        .filter(i => i !== 'Codigo')
      const config = Object.values(variablesConfig)
        .reduce((acc, x) => [...acc, ...x], [])
      setItems(keys.map(k => {
        const variable = config.find(c => c.variable === k)
        return {
          parametros: vars[k].map(v => {
            let valor = ''
            if (variable['type'] === 'num') {
              valor = `Rango: ${v.Inferior} - ${v.Superior}`
            } else {
              valor = `Valor: ${v.Valor}`
            }
            return {
              ...v,
              valor
            }
          }),
          variable: variable['label'],
        }
      }))
    }
  }, [vars, variablesConfig])
  useEffect(() => {
    if (!Object.values(variablesConfig).length) {
      getVariablesConfig()
    }
    getVariables(values.Codigo)
  }, [])
  return <>
    <DialogTitle>Configuración de variables</DialogTitle>
    <DialogContent>
      {
        loading && <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      }
      {
        (!loading && !items.length) && <Alert severity="info" variant="filled">
          No hay registros disponibles
        </Alert>
      }
      <div className={classes.container}>
        {
          items.map((i, idx) => <Paper key={idx} style={{padding: 5}}>
            <Typography variant="h6">{i.variable}</Typography>
            <List>
              {
                i.parametros.map((j, idx2) => <ListItem>
                  <ListItemAvatar>
                    <img src={`./icons/cosechadora/${j.Icono}`} className={classes.icono} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${j.Parametro} - Alarma: ${j.TieneAlerta ? '✔' : '❌'}`}
                    secondary={j.valor}
                  />
                </ListItem>)
              }
            </List>
          </Paper>)
        }
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </>
}

export default VariablesShow
