import React from 'react';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalConfirm = ({ open, cerrarDialogo, titulo, descripcion, handleAction }) => {

    return (
        <Dialog
            open={open}
            onClose={cerrarDialogo}
            aria-labelledby={titulo}
            aria-describedby={descripcion}
        >
            <DialogTitle id="titulo">{titulo}</DialogTitle>
            <DialogContent>
                <DialogContentText id="descripcion">
                    {descripcion}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cerrarDialogo} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleAction} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirm;
