import React, { Fragment, useEffect, useState } from 'react'
import { useStoreState } from "easy-peasy"
import { toast } from "react-toastify"
import dayjs from 'dayjs'
import {default as axios2} from 'axios'
import http from '../../Utils/http'

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper
} from '@material-ui/core';

import SelectZona from '../../components/Selects/SelectZona';
import SelectFinca from '../../components/Selects/SelectFinca';
import SelectSeccion from '../../components/Selects/SelectSeccion';
import { SelectMultiLote } from '../../components/Selects/SelectLote';
import SelectZafra from '../../components/Selects/SelectZafra';
import { SelectCalendarioRange } from '../../components/Selects/SelectFechas';

import { RenderNumber } from '../../components/Utils/TextFormats'
import { CSVToJSONLatLon } from '../../Utils/functions'
import { MapContainer, FilterDivider } from '../../components/Maps'
import Layout from "../../components/Layout/layout"
import MapaPuntos from '../../components/GIO/MapaPuntos'
import { config } from '../../components/GIO/MapaConfig';
import { tipoColores, leyendasTipo, tipoParametros } from '../../components/GIO/MapaConfig'
import SelectTipoMapa from '../../components/GIO/SelectTipoMapa'
import { getShapes } from '../../Utils/functions'

const MapasCosecha = () => {
  const { axios } = useStoreState(state => ({
    axios: state.axios
  }))
  const [fechas, setFechas] = useState([])
  const [zona, setZona] = useState(null)
  const [finca, setFinca] = useState(null)
  const [seccion, setSeccion] = useState(null)
  const [lotes, setLotes] = useState([])
  const [zafra, setZafra] = useState(null)
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [puntos, setPuntos] = useState([])
  const [tipo, setTipo] = useState('')
  const [shape, setShape] = useState(null)
  const [colores, setColores] = useState([])
  const [estadisticos, setEstadisticos] = useState([])
  const [loading, setLoading] = useState(false)
  const tablaParams = tipoParametros[tipo]
  const leyendas = leyendasTipo[tipo]

  const getFechas = async () => {
    const params = {
      Zafra: zafra.Codigo,
      Tipo: tipo,
      Finca: finca,
      Lotes: []
    }
    const body = await http.post('maestros/mapa_cosecha/Fechas', params)
    setFechas(body)
  }

  const getShape = async () => {
    const GeoJSON = await getShapes(zafra.Codigo, finca, lotes)
    setShape(GeoJSON)
  }

  const getPuntos = async (links) => {
    const requests = links.map(i => axios2.get(i, {responseType: 'text'}))
    const responses = await Promise.all(requests)
    const pts = responses.map(r => CSVToJSONLatLon(r.data))
      .reduce((acc, r) => [...acc, ...r], [])
    return pts
  }

  const getMapa = async () => {
    if (!zafra) {
      toast('Selecciona una zafra', { type: toast.TYPE.ERROR })
    } else if (!finca) {
      toast('Selecciona una finca', { type: toast.TYPE.ERROR })
    } else if (!tipo) {
      toast('Selecciona un tipo de mapa', { type: toast.TYPE.ERROR })
    } else {
      getShape()
      const inicia = fechaInicio ? dayjs(fechaInicio).format('YYYY-MM-DD') : null
      const termina = fechaFin ? dayjs(fechaFin).format('YYYY-MM-DD') :
        fechaInicio ? dayjs(fechaInicio).format('YYYY-MM-DD') : null
      const params = {
        Zafra: zafra.Codigo,
        FechaInicia: inicia,
        FechaTermina: termina,
        Tipo: tipo,
        Finca: finca,
        Lotes: lotes
      }
      try {
        setLoading(true)
        getEstadisticos(params)
        const tipoConfig = config[tipo]
        const body = await http.post('maestros/mapa_cosecha/Puntos', params)
        const layers = []
        for (const key in body) {
          if (body[key] instanceof Array) {
            const pts = await getPuntos(body[key])
            layers.push({
              parametro: key,
              color: tipoColores[tipo][key],
              puntos: pts
            })
          }
        }
        setPuntos(layers)
        setColores(tipoConfig.colors)
      } catch (error) {
        console.log(error)
        toast('Error al cargar el mapa', { type: toast.TYPE.ERROR })
      } finally {
        setLoading()
      }
    }
  }

  const getEstadisticos = async (params) => {
    const turnos = {
        1: 'Día',
        2: 'Noche',
        3: 'Mixto'
      }
    const body = await http.post('maestros/mapa_cosecha/Resultados', params)
    const items = body.map(i => ({
      ...i,
      Fecha: dayjs(i.Fecha).format('DD-MM-YYYY'),
      Turno: turnos[i.Turno]
    }))
    setEstadisticos(items)
  }

  useEffect(() => {
    if (zafra && finca) {
      getFechas()
    }
  }, [zafra, finca, lotes])

  return (
    <Layout
      title="Cosecha"
      subtitle="Mapa de consumo de combustible"
      link="/cosecha-consumo-combustible"
    >
      <MapContainer
        action={getMapa}
        loading={loading}
        buttonLabel="Generar Mapa"
        legendas={leyendas}
        pdfDownload={() => {}}
        activeBtnDownload={false}
        toolbar={
          <Fragment>
            <FilterDivider titulo="Filtros">
              <Grid container className="filtro">
                <SelectTipoMapa value={tipo} onChange={val => setTipo(val)} />
              </Grid>
              <Grid container className="filtro">
                <SelectZafra value={zafra} onChange={val => setZafra(val)} minZafra={2021} />
              </Grid>
              <Grid container className="filtro">
                <SelectZona onSelect={val => setZona(val)} />
              </Grid>
              <Grid container className="filtro">
                <SelectFinca onSelect={val => setFinca(val)} codigoZona={zona} />
              </Grid>
              <Grid container className="filtro">
                <SelectSeccion onSelect={val => setSeccion(val)} codigoFinca={finca} />
              </Grid>
              <Grid container className="filtro">
                <SelectMultiLote values={lotes} onSelect={val => setLotes(val)} codigoSeccion={seccion} />
              </Grid>
              <Grid container className="filtro">
                <SelectCalendarioRange
                  fechaInicio={fechaInicio}
                  fechaFin={fechaFin}
                  setFechas={dates => {
                    const [start, end] = dates;
                    setFechaInicio(start);
                    setFechaFin(end);
                  }}
                  fechas={fechas}
                  disabled={!zafra}
                  onChangeYear={year => getFechas(year)}
                  onClear={() => {
                    setFechaInicio(null)
                    setFechaFin(null)
                  }}
                />
              </Grid>
            </FilterDivider>
          </Fragment>
        }
      >
        <div id="map-container">
          <MapaPuntos
            shape={shape}
            layers={puntos}
            colores={colores}
          />
        </div>
        <Grid container spacing={0} className="tabla-mapa-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Semana</TableCell>
                  <TableCell>Turno</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Cosechadora</TableCell>
                  {
                    tablaParams.headers.map(i => <TableCell key={i}>{i}</TableCell>)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {estadisticos.map((row, index) =>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.Semana}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.Turno}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.Fecha}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.Cosechadora}
                      </Typography>
                    </TableCell>
                    {
                      tablaParams.params.map((i, idx) => <TableCell key={idx}>
                        <Typography gutterBottom variant="subtitle1">
                          <RenderNumber value={row.Porcentajes[i]} decimalScale={2} suffix=" %" />
                        </Typography>
                      </TableCell>)
                    }
                    {/* <TableCell>
                      <Typography gutterBottom variant="subtitle1">
                        <RenderNumber value={row.baja} decimalScale={2} suffix=" %" />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography gutterBottom variant="subtitle1">
                        <RenderNumber value={row.media} decimalScale={2} suffix=" %" />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography gutterBottom variant="subtitle1">
                        <RenderNumber value={row.alta} decimalScale={2} suffix=" %" />
                      </Typography>
                    </TableCell> */}
                  </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </MapContainer>
    </Layout>
  )
}

export default MapasCosecha