import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  ListItemIcon,
  IconButton,
  makeStyles,
  DialogTitle
} from '@material-ui/core'
import { Navigation } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import SelectMaquinariaGIO from '../Selects/SelectMaquinariaGIO'
import { Alert } from '@material-ui/lab'

const estados = [
  {
    estado: 'ok',
    texto: 'Operativo'
  },
  {
    estado: 'alarma',
    texto: 'Alarma'
  },
  {
    estado: 'detenido',
    texto: 'Detenido'
  },
  {
    estado: 'offline',
    texto: 'Apagado'
  }
]

const useStyles = makeStyles(theme => ({
  maquinaria: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icono: {
    cursor: 'pointer'
  }
}))

const BusquedaMapa = ({ items, onClose, mover }) => {
  const classes = useStyles()
  const { tiposMaquinaria } = useStoreState(state => ({
    tiposMaquinaria: state.tiposMaquinaria
  }))
  const { getTiposMaquinaria } = useStoreActions(actions => ({
    getTiposMaquinaria: actions.getTiposMaquinaria
  }))
  const [maq, setMaq] = useState([])
  const [search, setSearch] = useState(null)
  const getIcono = (item, alarmaDefault=false) => {
    const iconDefault = 'marker2_alert.svg'
    switch (item.estado) {
      case 'offline':
        return `./icons/marker_offline_${item.maquinariaTipo}.svg`
      case 'alarma':
        return `./icons/${item.maquinariaTipo}/${!alarmaDefault ? item.alarmas[0].Icono : iconDefault}`
      case 'detenido':
        return `./icons/marker_stop_${item.maquinariaTipo}.svg`
      default:
        return `./icons/marker_online_${item.maquinariaTipo}.svg`
    }
  }

  useEffect(() => {
    if (search) {
      setMaq([...items].filter(i => i.codigo == search.Codigo))
    }
  }, [items])
  useEffect(() => {
    if (search) {
      const filtered = items.filter(i => i.codigo == search.Codigo)
      setMaq(filtered)
    } else {
      setMaq([...items])
    }
  }, [search])
  useEffect(() => {
    if (!tiposMaquinaria.length) {
      getTiposMaquinaria({filtro: 'principal'})
    }
  }, [])
  return <>
    <DialogTitle>Busqueda</DialogTitle>
    <DialogContent>
      <SelectMaquinariaGIO
        label="Buscar maquinaria"
        value={search}
        onChange={(ev, val) => setSearch(val)}
      />
      <List style={{maxHeight: '30vh', overflow: 'auto'}}>
        {
          maq.map((i, idx) => <ListItem key={idx} >
            <ListItemAvatar>
              <img height={40} width={40} src={getIcono(i)} />
            </ListItemAvatar>
            <ListItemText
              primary={i.codigo}
              secondary={i.maquinariaTipo.toUpperCase()}
            />
            <ListItemIcon>
              <IconButton onClick={() => mover(i)}>
                <Navigation />
              </IconButton>
            </ListItemIcon>
          </ListItem>)
        }
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </>
}

export default BusquedaMapa