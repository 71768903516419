import React, { Fragment, useState } from 'react'
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper
} from '@material-ui/core';
import { toast } from "react-toastify"
import axios from 'axios'

import http from '../../Utils/http'
import SelectZona from '../../components/Selects/SelectZona';
import SelectFinca from '../../components/Selects/SelectFinca';
import SelectSeccion from '../../components/Selects/SelectSeccion';
import { SelectMultiLote } from '../../components/Selects/SelectLote';
import SelectZafra from '../../components/Selects/SelectZafra';
import { CSVToJSONLatLon } from '../../Utils/functions'
import { MapContainer, FilterDivider } from '../../components/Maps'
import Layout from "../../components/Layout/layout"
import MapaPuntosLineas from '../../components/Ferti/MapaPuntosLineas'
import { colores } from '../../components/GIO/MapaFertiConfig'
import RangosFerti from '../../components/Ferti/RangosFerti'
import { getShapes } from '../../Utils/functions'

const MapaFertilizacion = () => {
  const [zona, setZona] = useState(null)
  const [finca, setFinca] = useState(null)
  const [seccion, setSeccion] = useState(null)
  const [lotes, setLotes] = useState([])
  const [zafra, setZafra] = useState(null)
  const [layers, setLayers] = useState({
    puntos: [],
    lineas: []
  })
  const [shape, setShape] = useState(null)
  const [loading, setLoading] = useState(false)
  const [resultados, setResultados] = useState([])

  const getShape = async () => {
    const GeoJSON = await getShapes(zafra.Codigo, finca, lotes)
    setShape(GeoJSON)
  }

  const getPuntos = async (links) => {
    const requests = links.map(i => axios.get(i, {responseType: 'text'}))
    const responses = await Promise.all(requests)
    const pts = responses.map(r => CSVToJSONLatLon(r.data))
      .reduce((acc, r) => [...acc, ...r], [])
    return pts
  }
  const getLineas = async (links) => {
    const requests = links.map(i => axios.get(i))
    const responses = await Promise.all(requests)
    const lns = responses.map(r => r.data)
    return lns
  }

  const getPuntosFerti = async (bodyRequest) => {
    const body = await http.post('/maestros/archivo_ferti/puntos', bodyRequest)
    const layers = []
    const pts = await getPuntos(body)
    for (let i=0; i < 4; i++) {
      const items = []
      for (const p of pts) {
        if (p['Rango'] === i) {
          items.push(p)
        }
      }
      layers.push({
        puntos: items,
        color: colores[i]
      })
    }
    return layers
  }
  const getLineasFerti = async (bodyRequest) => {
    const body = await http.post('/maestros/archivo_ferti/lineas', bodyRequest)
    const lns = await getLineas(body)
    return lns
  }

  const getColor = (rango) => {
    return colores[rango]
  }

  const getMapa = async () => {
    if (!zafra) {
      toast('Selecciona una zafra', { type: toast.TYPE.ERROR })
    } else if (!finca) {
      toast('Selecciona una finca', { type: toast.TYPE.ERROR })
    } else {
      getShape()
      const body = {
        zafra: parseInt(zafra.Codigo),
        finca: parseInt(finca),
        um: lotes
      }
      try {
        setLoading(true)
        getResultados(body)
        const layersPuntos = await getPuntosFerti(body)
        const layersLineas = await getLineasFerti(body)
        setLayers({
          puntos: layersPuntos,
          lineas: layersLineas
        })
      } catch (error) {
        toast.error('Error al cargar el mapa')
      } finally {
        setLoading()
      }
    }
  }

  const getResultados = async (bodyRequest) => {
    const body = await http.post('/maestros/archivo_ferti/resultados', bodyRequest)
    setResultados(body)
  }

  return (
    <Layout
      title="Cosecha"
      subtitle="Mapa de fertilización"
      link="/mapa-fertilizacion"
    >
      <MapContainer
        action={getMapa}
        loading={loading}
        buttonLabel="Generar Mapa"
        legendas={[]}
        pdfDownload={() => {}}
        activeBtnDownload={false}
        toolbar={
          <Fragment>
            <FilterDivider titulo="Filtros">
              {/* <Grid container className="filtro">
                <SelectTipoMapa value={tipo} onChange={val => setTipo(val)} />
              </Grid> */}
              <Grid container className="filtro">
                <SelectZafra value={zafra} onChange={val => setZafra(val)} minZafra={2021} />
              </Grid>
              <Grid container className="filtro">
                <SelectZona onSelect={val => setZona(val)} />
              </Grid>
              <Grid container className="filtro">
                <SelectFinca onSelect={val => setFinca(val)} codigoZona={zona} />
              </Grid>
              <Grid container className="filtro">
                <SelectSeccion onSelect={val => setSeccion(val)} codigoFinca={finca} />
              </Grid>
              <Grid container className="filtro">
                <SelectMultiLote values={lotes} onSelect={val => setLotes(val)} codigoSeccion={seccion} />
              </Grid>
            </FilterDivider>
          </Fragment>
        }
      >
        <div id="map-container">
          <MapaPuntosLineas
            shape={shape}
            layers={layers}
            colorFunc={getColor}
            lat='Latitud'
            lon='Longitud'
          />
        </div>
        <Grid container spacing={0} className="tabla-mapa-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>UM</TableCell>
                  <TableCell>Area aplicada</TableCell>
                  <TableCell>Dosis programada</TableCell>
                  <TableCell>Dosis real</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Rangos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultados.map((row, index) =>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.UM}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {(row.Area / 10000).toFixed(2)} Ha
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.DosisProgramada.toFixed(2)} kg/Ha
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {row.DosisReal.toFixed(2)} kg/Ha
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography gutterBottom variant="subtitle1">
                        {(row.DosisReal * (row.Area / 10000)).toFixed(2)} kg
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <RangosFerti min={row.R1Min} max={row.R3Max} valor={row.DosisReal} />
                    </TableCell>
                  </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </MapContainer>
    </Layout>
  )
}

export default MapaFertilizacion