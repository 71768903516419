import React from 'react';
import {
  Grid,
  Paper,
  makeStyles,
  IconButton,
  Tooltip,
  ButtonGroup
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LoadingButton from '../Utils/LoadingButton';
import ButtonDrop from '../Utils/ButtonDrop';
import Legenda from './Legenda';

const useStyles = makeStyles((theme) => {
  return {
    mapContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: '100%',
      maxWidth: '100%',
      /* total - navbar - paddings */
      height: 'calc(100vh - 64px - 48px)',
      maxHeight: 'calc(100vh - 64px - 48px)',
      overflowY: 'hidden',
      overflowX: 'hidden',
      [theme.breakpoints.up("md")]: {
        flexDirection: 'row',
      },
    }
  };
});

// Layout para los mapas
const MapContainer = ({
  children,
  toolbar,
  buttonLabel,
  loading,
  openModal,
  linea,
  legendas = [],
  action,
  activeBtnDownload,
  pdfDownload,
  hasInfo=true,
  hasLegendas=true
}) => {
  const classes = useStyles()
  return (
    <Grid container component={Paper} elevation={3} className="map-container">
      <Grid item xs={12} md={8} className="maps-area">
        {
          hasInfo && <Tooltip title={'Más información'}>
            <IconButton size="small" onClick={openModal} className="btn-flotante">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        }
        <div className="maps-area-container">
          {children}
        </div>
        {
          hasLegendas && <Legenda linea={linea} legendas={legendas} />
        }
      </Grid>
      <Grid item xs={12} md={4} className="maps-toolbar">
        <div className="maps-filters">
          <div style={{ width: "100%" }}>
            {toolbar && toolbar}
          </div>
        </div>
        <div className="maps-button">
          <ButtonGroup fullWidth>
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              onClick={action}
              fullWidth
              style={{ display: "flex" }}
            >
              {buttonLabel || 'Generar'}
            </LoadingButton>
            <ButtonDrop
              active={activeBtnDownload}
              pdfDownload={pdfDownload}
            />
          </ButtonGroup>
        </div>
      </Grid>
    </Grid>
  )
}

export default MapContainer;
