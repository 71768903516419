import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'
import LoadingButton from '../Utils/LoadingButton'
import { getIdentity } from '../../Utils/auth'

const TipoArchivoEdit = ({ title, onClose, onSubmit, onRefresh, initial=null }) => {
  const [nombre, setNombre] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    Nombre: false
  })
  const validate = () => {
    const errs = {
      Nombre: nombre.trim().length == 0
    }
    console.log(errs)
    setErrors(errs)
    for (const key in errs) {
      if (errs[key]) {
        return false
      }
    }
    return true
  }
  const submitForm = async () => {
    try {
      if (validate()) {
        setLoading(true)
        await onSubmit({
          Nombre: nombre,
          Descripcion: descripcion
        })
        onRefresh()
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (initial) {
      setNombre(initial.Nombre)
      setDescripcion(initial.Descripcion)
    }
  }, [])
  return <>
    <DialogTitle>{ title }</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre"
        value={nombre}
        onChange={ev => setNombre(ev.target.value)}
        error={errors.Nombre}
        helperText={errors.Nombre ? 'El campo es requerido' : ''}
      />
      <TextField
        multiline
        fullWidth
        rows={3}
        label="Descripción"
        value={descripcion}
        onChange={ev => setDescripcion(ev.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        color="primary"
        variant="contained"
        onClick={submitForm}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default TipoArchivoEdit
