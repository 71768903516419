import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import http from '../../Utils/http'

const SelectZafra = ({
    value,
    onChange,
    minZafra=null,
    error=false,
    helperText='',
    selectCurrent=false,
    defaultValue=null
  }) => {
  const [options, setOptions] = useState([]);
  const getZafras = async () => {
    try {
      const data = await http.get('/maestros/catalogo/zafras')
      const items = minZafra ? data.filter(i => parseInt(i.Codigo) >= parseInt(minZafra)) : data
      setOptions(items.map(i => ({
        ...i,
        inicia: dayjs(i.FechaInicio),
        termina: dayjs(i.FechaTermina)
      })))
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
    // Api.get("zafras").then(response => {
    //   const items = minZafra ? response.filter(i => parseInt(i.codigo) >= minZafra) : response
    //   setOptions(items.map(i => ({
    //     ...i,
    //     inicia: dayjs(i.fecha_inicial),
    //     termina: dayjs(i.fecha_final)
    //   })))
    // }).catch(() => setOptions([]));
  }
  const changeZafra = (ev, value) => {
    onChange(value);
  }
  useEffect(() => {
    getZafras()
  }, [])
  useEffect(() => {
    if (selectCurrent) {
      const today = dayjs()
      const fecha = options.find(i => today.isAfter(i.FechaInicio) && today.isBefore(i.FechaTermina))
      if (fecha) {
        onChange(fecha)
      }
    }
    if (defaultValue && options.length) {
      const item = options.find(i => i.Codigo == defaultValue)
      onChange(item ? item : null)
    }
  }, [options, defaultValue])
  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-zafra"
        fullWidth
        options={options}
        defaultValue={null}
        getOptionLabel={(option) => option['Nombre'] ? option['Nombre'] : '' }
        renderInput={(params) => <TextField
          {...params}
          fullWidth
          label="Zafra"
          margin="none"
          error={error}
          helperText={helperText}
        />}
        value={value}
        onChange={changeZafra}
      />
    </div>
  )
}

export default SelectZafra;
