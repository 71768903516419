import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectFinca2 = ({
    value,
    onChange,
    codigoZona,
    error=false,
    helperText='',
    fullValue=false,
    defaultValue=null
  }) => {
  const { fincas } = useStoreState(state => ({
    fincas: state.fincas
  }))
  const { getFincas } = useStoreActions(actions => ({
    getFincas: actions.getFincas
  }))
  const [loaded, setLoaded] = useState(false)
  const changeFinca = (ev, value) => {
    if (value) {
      onChange(fullValue ? value : value.Codigo)
    } else {
      onChange(null)
    }
  }

  useEffect(() => {
    if (codigoZona) {
      getFincas(codigoZona)
    }
    onChange(null)
  }, [codigoZona])

  useEffect(() => {
    if (defaultValue && !loaded) {
      const item = fincas.find(i => i.Codigo == defaultValue)
      if (item) {
        setLoaded(true)
        onChange(item)
      }
    }
  }, [fincas])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        id="select-finca"
        fullWidth
        options={fincas}
        defaultValue={null}
        getOptionLabel={(option) => option['Nombre'] ? option['Nombre'] : ''}
        // onInputChange={(e, value) => cargarOpciones(value)}
        renderInput={(params) => <TextField
          error={error}
          {...params}
          fullWidth
          label="Finca"
          margin="none"
          helperText={helperText}
        />}
        value={value}
        onChange={changeFinca}
      />
    </div>
  )
}

export default SelectFinca2
