import React, { useState, useEffect } from 'react'
import {
  IconButton
} from '@material-ui/core'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import {Edit, Delete } from '@material-ui/icons'

const TablaGIO = ({ items=[], onDelete, onEdit }) => {
  const CellOpciones = (dataCell) => {
    return <>
      <IconButton onClick={() => onEdit(dataCell.rowIndex)}>
        <Edit />
      </IconButton>
      <IconButton onClick={() => onDelete(dataCell.rowIndex)}>
        <Delete />
      </IconButton>
    </>
  }
  return <DataGrid
    dataSource={items}
    showBorders
    showColumnLines
    rowAlternationEnabled
    allowColumnResizing
  >
    <Column caption="Id" dataField="IdGIO" />
    <Column caption="Código" dataField="Codigo" />
    <Column caption="Codigo GIO" dataField="CodigoGIO" />
    <Column caption="Token" dataField="Token" />
    <Column caption="Opciones" cellRender={CellOpciones} />
  </DataGrid>
}

export default TablaGIO
