import React, { useState, useEffect } from "react";
import { Typography } from '@material-ui/core'
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, hasPermiso } from "./Utils/auth";
import { useStoreState, useStoreActions } from "easy-peasy";

/* import { Auth } from "aws-amplify"; */

export const ProtectedRoute = ({ component: Component, permiso = null, ...rest }) => {
  const { path } = rest
  const { permisos } = useStoreState(state => ({
    permisos: state.permisos
  }))
  const { getPermisos } = useStoreActions(actions => ({
    getPermisos: actions.getPermisos
  }))
  const [authenticated, setAuthenticated] = useState(true)
  const [authorized, setAuthorized] = useState(true)
  const loading = !permisos.length

  const checkUser = () => {
    try {
      const loggedIn = isLoggedIn()
      setAuthenticated(loggedIn)
      if (permisos.length) {
        const tienePermiso = hasPermiso(permiso, permisos)
        if (permiso !== null && !tienePermiso) {
          setAuthorized(false)
        }
      }
    } catch (e) {
      setAuthenticated(false)
    }
  }
  useEffect(() => {
    checkUser()
  }, [path])
  useEffect(() => {
    checkUser()
    getPermisos()
  }, [permisos])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Typography variant="h5" component="p">Cargando...</Typography>
        } else if (!loading && !authenticated) {
          return <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              }
            }}
          />
        } else if (!loading && !authorized) {
          return <Redirect
            to={{
              pathname: "/unauthorized",
              state: {
                from: props.location,
              }
            }}
          />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}
