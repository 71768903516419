import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker'
import esLocale from "date-fns/locale/es";
import { Grid, FormControl, Box, FormHelperText, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import 'date-fns';


export const SelectFechasRange = ({ date_start, date_end, changeStart, changeEnd }) => (
  <div style={{ width: "100%" }}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Grid container justify="space-around">
        <Grid item sm={12} xs={12} md={6}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="none"
            id="date_start"
            label="Fecha inicial"
            value={date_start ? new Date(date_start) : null}
            invalidDateMessage="Fecha invalida"
            onChange={changeStart}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="none"
            id="date_end"
            label="Fecha final"
            value={date_end ? new Date(date_end) : null}
            invalidDateMessage="Fecha invalida"
            onChange={changeEnd}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  </div>
)

export const SelectFecha = ({ date, onChange, label, error = false, helperText = '', ...props }) => (
  <div style={{ width: "100%" }}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDatePicker
        error={error}
        fullWidth
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="none"
        id="date_start"
        label={label || 'Fecha'}
        value={date ? new Date(date) : null}
        onChange={onChange}
        clearable
        helperText={helperText}
        {...props}
      />
    </MuiPickersUtilsProvider>
  </div>
)

export const SelectCalendario = ({ fecha, fechas = [], setFecha, onChangeYear, disabled, error = false, ...props }) => {

  registerLocale('es', esLocale);

  const filterFechas = (fecha) => {
    const date = dayjs(fecha).startOf('day').format('YYYY-MM-DD')
    return fechas.find(i => i === date)
  }

  const yearChange = (param) => {
    const year = dayjs(param).get('year')
    onChangeYear(year)
  }

  return (
    <FormControl error={error} fullWidth>
      <Box display="flex" justifyContent="center" width="100%">
        <DatePicker
          inline
          showMonthDropdown
          showYearDropdown
          locale="es"
          filterDate={filterFechas}
          selected={fecha}
          onYearChange={yearChange}
          onChange={val => setFecha(val)}
          disabled={disabled}
        />
      </Box>
      <FormHelperText>{error ? 'La fecha es requerida' : ''}</FormHelperText>
    </FormControl>
  )
}

export const SelectCalendarioRange = ({
    fechaInicio,
    fechaFin,
    fechas = [],
    setFechas,
    onChangeYear,
    disabled,
    error = false,
    onClear,
    ...props
  }) => {

  registerLocale('es', esLocale);

  const filterFechas = (fecha) => {
    const date = dayjs(fecha).startOf('day').format('YYYY-MM-DD')
    return fechas.find(i => i === date)
  }

  const yearChange = (param) => {
    const year = dayjs(param).get('year')
    onChangeYear(year)
  }

  return <>
    <FormControl error={error} fullWidth>
      <Box display="flex" justifyContent="center" width="100%">
        <DatePicker
          inline
          selectsRange
          showMonthDropdown
          showYearDropdown
          locale="es"
          filterDate={filterFechas}
          selected={fechaInicio}
          startDate={fechaInicio}
          endDate={fechaFin}
          onYearChange={yearChange}
          onChange={dates => setFechas(dates)}
          disabled={disabled}
        />
      </Box>
      <FormHelperText>{error ? 'La fecha es requerida' : ''}</FormHelperText>
    </FormControl>
    <Box display="flex" justifyContent="center" width="100%">
      <Button size="small" onClick={onClear}>Limpiar fechas</Button>
    </Box>
  </>
}
