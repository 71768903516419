import React from 'react'
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: '1rem',
    marginRight: '0.5rem',
  },
  cardContainer: {
    borderRadius: 10,
    padding: '0.8rem',
    background: '#eeeeee'
  }
}))

const NumberCard = ({ icon, number, title, description, textColor="#fff", color="default", percentage=false}) => {
  const classes = useStyles()
  const getColor = () => {
    const colors = {
      'red': 'tomato',
      'pink': '#e91e63',
      'purple': '#9c27b0',
      'indigo': '#3f51b5',
      'blue': '#2196f3',
      'cyan': '#00bcd4',
      'teal': '#009688',
      'green': 'forestgreen',
      'lime': '#cddc39',
      'yellow': 'gold',
      'amber': '#ffc107',
      'orange': '#ff9800',
      'brown': '#795548',
      'grey': '#9e9e9e',
      'default': '#202e3c'
    }
    return colors[color] || '#202e3c'
  }
  return <Box display="flex" alignItems="center" className={classes.cardContainer}>
    <div className={classes.iconContainer} style={{background: getColor(), color: textColor, fontSize: '3rem'}}>
      { icon }
    </div>
    <div className="ms-3">
      <Box display="flex" alignItems="center" className="d-flex align-items-center">
        <Typography variant="h5">
          {number !== null ? number.toFixed(2) : '--'}{percentage && '%'}
        </Typography>
        <Typography variant="body1" component="span" style={{marginLeft: '0.5rem'}}>{title}</Typography>
      </Box>
      <Typography variant="caption">{description}</Typography>
    </div>
  </Box>
}

export default NumberCard