import React from 'react'
import {
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar
} from '@material-ui/icons'
import {
  red, amber, green
} from '@material-ui/core/colors'

const Signal = ({ value }) => {
  let icon
  if (value <= 0) {
    icon = <SignalWifi0Bar style={{color: red[700]}} />
  } else if (value >= 1 && value < 2) {
    icon = <SignalWifi1Bar style={{color: red[500]}} />
  } else if (value >= 2 && value < 3) {
    icon = <SignalWifi2Bar style={{color: amber[500]}} />
  } else if (value >= 3 && value < 4) {
    icon = <SignalWifi3Bar style={{color: green[500]}} />
  } else if (value >= 4) {
    icon = <SignalWifi4Bar style={{color: green[500]}} />
  }
  return icon
}

export default Signal