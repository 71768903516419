import { action, thunk } from 'easy-peasy'
import http from '../Utils/http'

export default {
  roles: [],
  permisos: [],
  listaPermisos: [],
  setRoles: action((state, payload) => {
    state.roles = payload
  }),
  setPermisos: action((state, payload) => {
    state.permisos = payload
  }),
  setListaPermisos: action((state, payload) => {
    state.listaPermisos = payload
  }),
  getRoles: thunk(async (actions) => {
    try {
      const body = await http.get('/maestros/rol')
      actions.setRoles(body)
    } catch (error) {
      throw error
    }
  }),
  getPermisos: thunk(async (actions, _, { getStoreState }) => {
    try {
      const { permisos } = getStoreState()
      if (!permisos.length) {
        const body = await http.get('/maestros/auth/permisos')
        actions.setPermisos(body)
      }
    } catch (error) {
      throw error
    }
  }),
  getListaPermisos: thunk(async (actions) => {
    try {
      const body = await http.get('/maestros/permiso')
      actions.setListaPermisos(body)
    } catch (error) {
      throw error
    }
  })
}
