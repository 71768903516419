import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Fab,
  Typography,
  Dialog,
  makeStyles,
  Icon,
  IconButton,
  Paper,
  DialogContent,
  DialogActions,
  DialogTitle,
  LinearProgress
} from '@material-ui/core';
import Layout from '../../components/Layout/layout';
import http from '../../Utils/http'
import { toast } from 'react-toastify';
import { getIdentity } from '../../Utils/auth';
import { Delete, GetApp } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useInterval from '../../hooks/useInterval';
import ExportacionCrear from './ExportacionCrear';
import dayjs from 'dayjs';
import axios from 'axios';
import LoadingBackdrop from '../../components/Utils/LoadingBackdrop';
import { da } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  btnExportar: {
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    }
  },
  btnDescargar: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
}));

const MantenimientoExportar = () => {
  const classes = useStyles();
  const { dataCatalogs } = useStoreState(state => ({dataCatalogs: state.dataCatalogs}));
  const { getDataCatalogs } = useStoreActions(actions => ({getDataCatalogs: actions.getDataCatalogs}));
  const [items, setItems] = useState([]);
  const [dgCrear, setDgCrear] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [dgDescargar, setDgDescargar] = useState(false);

  const getExportaciones = async () => {
    try {
      setLoading(true);
      const usuario = getIdentity();
      const data = await http.get(`/maestros/dataset?UsuarioCreo=${usuario}`);
      setItems(data.map(i => ({
        ...i,
        DBNombre: getNombreTabla(i.DBTabla) || 'Desconocido',
        FechaInicia: dayjs(i.FechaInicia).format('DD/MM/YYYY'),
        FechaTermina: dayjs(i.FechaTermina).format('DD/MM/YYYY'),
        FechaCreo: dayjs(i.FechaCreo).format('DD/MM/YYYY')
      })));
    } catch (error) {
      toast.error('Error al obtener los datos')
    } finally {
      setLoading(false);
    }
  }
  const getNombreTabla = (DBTabla) => {
    for (const dc of dataCatalogs) {
      for (const tabla of dc.db_tablas) {
        if (tabla.db_tabla_nombre === DBTabla) {
          return tabla.db_tabla_label;
        }
      }
    }
  }
  const downloadDataset = async (id) => {
    try {
      setDownloading(true);
      const usuario = getIdentity();
      const presigned_url = await http.get(`/maestros/dataset/download?IdQuery=${id}`);
      const { data } = await axios.get(presigned_url, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `dataset_${usuario}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      toast.error('Error al obtener los datos')
    } finally {
      setDownloading(false);
    }
  }
  const getPresignedUrl = async (id) => {
    try {
      const presigned_url = await http.get(`/maestros/dataset/download?IdQuery=${id}`);
      setUrl(presigned_url);
      setDgDescargar(true);
    } catch (error) {
      toast.error('Error al obtener los datos')
    }
  }
  const deleteDataset = async (id) => {
    try {
      if (!window.confirm('¿Está seguro de eliminar el registro?')) return;
      setDeleting(true);
      await http.delete(`/maestros/dataset/${id}`,{
        data: {UsuarioModifico: getIdentity()}
      });
      getExportaciones();
      toast.success('Registro eliminado correctamente');
    } catch (error) {
      toast.error('Error al eliminar el registro');
    } finally {
      setDeleting(false);
    }
  }
  const CellOpciones = (cellData) => (
    <div>
      <IconButton
        onClick={() => getPresignedUrl(cellData.data.IdQuery)}
        disabled={cellData.data.Estado !== 'Listo'}
        size="small"
      >
        <GetApp />
      </IconButton>
      <IconButton
        onClick={() => deleteDataset(cellData.data.IdDataset)}
        size="small"
      >
        <Delete />
      </IconButton>
    </div>
  )

  useEffect(() => {
    getDataCatalogs();
  }, []);
  useEffect(() => {
    if (dataCatalogs.length > 0) {
      getExportaciones();
    }
  }, [dataCatalogs]);
  useInterval(() => {
    if (items.some(i => i.Estado === 'Espera')){
      getExportaciones();
    }
  }, 10000);

  return (
    <Layout title="Exportación de datos">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: 10 }}
      >
        <Typography variant="h4">Exportaciones</Typography>
        <Fab
          onClick={() => setDgCrear(true)}
          className={classes.btnExportar}
          aria-label="add"
        >
          <GetApp />
        </Fab>
      </Box>
      <LinearProgress hidden={!loading} />
      <Paper style={{ padding: 10 }}>
        <DataGrid
          dataSource={items}
        >
          <Column caption="Base de datos" dataField="DBNombre" />
          <Column caption="Estado" dataField="Estado" />
          <Column caption="Periodo inicial" dataField="FechaInicia" />
          <Column caption="Periodo final" dataField="FechaTermina" />
          <Column caption="Fecha creación" dataField="FechaCreo" />
          <Column caption="Opciones" cellRender={CellOpciones} />
        </DataGrid>
      </Paper>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dgCrear}
        onClose={() => setDgCrear(false)}
      >
        <ExportacionCrear
          onClose={() => setDgCrear(false)}
          onRefresh={getExportaciones}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dgDescargar}
        onClose={() => setDgDescargar(false)}
      >
        <DialogTitle>Descargar</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" style={{ padding: 10 }}>
            <Button
              fullWidth
              startIcon={<GetApp />}
              color="primary"
              href={url}
              size="large"
            >
              Descargar
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDgDescargar(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop loading={deleting} />
    </Layout>
  );
}

export default MantenimientoExportar;
