import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreState, useStoreActions } from 'easy-peasy'

const SelectSeccion = ({ onSelect, codigoFinca }) => {
    const { secciones } = useStoreState(state => ({
        secciones: state.secciones
    }))
    const { getSeccionesLotes } = useStoreActions(actions => ({
        getSeccionesLotes: actions.getSeccionesLotes
    }))
    const [seccion, setSeccion] = useState('')

    const changeSeccion = (ev, value) => {
        setSeccion(value)
        onSelect(value)
    }

    useEffect(() => {
        if (codigoFinca) {
            getSeccionesLotes(codigoFinca)
        }
        onSelect(null)
        setSeccion('')
    }, [codigoFinca])

    return (
        <div style={{ width: "100%" }}>
            <Autocomplete
                id="select-seccion"
                fullWidth
                options={secciones}
                defaultValue={null}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} fullWidth label={"Seccion"} margin="none" />}
                value={seccion}
                onChange={changeSeccion}
            />
        </div>
    )
}

export default SelectSeccion