import { createStore } from 'easy-peasy'

// Modulos
import auth from './modules/auth'
import usuarios from './modules/usuarios'
import filtros from './modules/filtros'
import axios from './modules/axios'
import aereas from './modules/aereas'
import GIO from './modules/GIO'
import variables from './modules/variables'
import configs from './configs'
import permisos from './permisos'
import mantenimientos from './mantenimientos'
import utils from './utils'
import maquinaria from './maquinaria'
import datasets from './datasets'
// import maquinaria from './modules/maquinaria'
// import extractores from './modules/extractores'
// import maestroDosis from './modules/maestro_dosis'
// import cosecha from './modules/cosecha'
// import siembra from './modules/siembra'
// import suelos from './modules/suelos'
// import fertilizacion from './modules/fertilizacion'
// import plagas from './modules/plagas'
// import gasolina from './modules/gasolina'
// import mapa from './modules/mapa'

const globalModel = {
  // maquinaria,
  // extractores,
  // maestroDosis,
  // cosecha,
  // siembra,
  // suelos,
  // fertilizacion,
  // plagas,
  // gasolina,
  // mapa,
  auth,
  usuarios,
  ...axios,
  ...filtros,
  ...aereas,
  ...GIO,
  ...variables,
  ...configs,
  ...permisos,
  ...mantenimientos,
  ...utils,
  ...maquinaria,
  ...datasets
}

const GlobalStore = createStore(globalModel)
export default GlobalStore
