import React from 'react';

import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GetApp, PictureAsPdf, Description } from '@material-ui/icons';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const ButtonDrop = ({ active, pdfDownload, csvDownload }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickPDF = () => {
        pdfDownload();
        handleClose();
    }

    return (
        <React.Fragment>
            <Button
                title="Descargar"
                disabled={!active}
                color="default"
                variant="contained"
                onClick={handleClick}
                style={{ display: "flex" }}
            >
                <GetApp />
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={onClickPDF}>
                    <ListItemIcon>
                        <PictureAsPdf fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Reporte PDF" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Description fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="CSV" />
                </MenuItem>
            </StyledMenu>
        </React.Fragment>
    )
}

export default ButtonDrop;
