import React from 'react'
import {
  Typography,
  makeStyles
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { colores } from '../GIO/MapaFertiConfig'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  fuera: {
    color: colores[0]
  },
  bajo: {
    color: colores[1]
  },
  medio: {
    color: colores[2]
  },
  alto: {
    color: colores[3]
  }
}))

const RangosFerti = ({ min, max, valor }) => {
  const classes = useStyles()
  return <div className={classes.container}>
    <div className={classes.item}>
      <Typography variant="subtitle1">
        <FiberManualRecord fontSize="small" className={classes.fuera} /> Fuera de rango
      </Typography>
    </div>
    <div className={classes.item}>
      <Typography variant="subtitle1">
        <FiberManualRecord fontSize="small" className={classes.bajo} /> {min.toFixed(2)} kg/Ha - {(valor * 0.95).toFixed(2)} kg/Ha
      </Typography>
    </div>
    <div className={classes.item}>
      <Typography variant="subtitle1">
        <FiberManualRecord fontSize="small" className={classes.medio} /> {(valor * 0.95).toFixed(2)} kg/Ha - {(valor * 1.05).toFixed(2)} kg/Ha
      </Typography>
    </div>
    <div className={classes.item}>
      <Typography variant="subtitle1">
        <FiberManualRecord fontSize="small" className={classes.alto} /> {(valor * 1.05).toFixed(2)} kg/Ha - {max.toFixed(2)} kg/Ha
      </Typography>
    </div>
  </div>
}

export default RangosFerti
