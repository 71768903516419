import React from 'react'
import {
  makeStyles
} from '@material-ui/core'
import './IconSelector.css'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: 5
  },
  icon: {
    height: 32,
    width: 'auto'
  }
}))

const items = [
  'marker1.svg',
  'marker1_alert.svg',
  'marker2.svg',
  'marker2_alert.svg',
  'marker3.svg',
  'marker3_alert.svg',
  'marker4.svg',
  'marker4_alert.svg',
  'marker5.svg',
  'marker5_alert.svg',
  'marker6.svg',
  'marker6_alert.svg',
  'marker7.svg',
  'marker7_alert.svg',
  'marker8.svg',
  'marker8_alert.svg'
]
const IconSelector = ({tipo='cosechadora', value='marker3.svg', onChange, disabled=false}) => {
  const classes = useStyles()
  return <form className={classes.container}>
    {
      items.map((i, idx) =><label key={idx}>
        <input
          type="radio"
          name={`icon-${idx}`}
          value={i}
          checked={value == i}
          onChange={onChange}
          disabled={disabled}
        />
        <img
          key={idx}
          className={classes.icon}
          src={`/icons/${tipo}/${i}`}
        />
    </label>)
    }
  </form>
}

export default IconSelector
