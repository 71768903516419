import http from "./http"
import axios from "axios"
import DefaultSettings from "../pages/Usuarios/DefaultSettings"
// Esto ya no se usa en el mapa de producto aplicado
export const generateGradient = (colores = []) => {
  if (colores.length === 0 ) {
    return '#f4f6fa'
  }
  if (colores.length === 1) {
    return colores[0]
  }
  const paradas = []
  for (let i = 0; i < colores.length; i++) {
    const porcentaje = Math.ceil(100 * (i + 1) / colores.length)
    paradas.push(`${colores[i]} ${porcentaje}%`)
  }
  return `linear-gradient(90deg,${paradas.join(',')})`
}

export const CSVToJSON = (data, delimiter = ',') => {
  const fnl = data.indexOf('\n')
  const titles = data.slice(0, fnl).split(delimiter)
  if (fnl + 1 === data.length)
    return []
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter);
      return titles.reduce(
        (obj, title, index) => ((obj[title] = values[index]), obj),
        {}
      )
    })
}
export const CSVToJSONLatLon = (data, delimiter = ',') => {
  const fnl = data.indexOf('\n')
  const titles = data.slice(0, fnl).split(delimiter)
  if (fnl + 1 === data.length)
    return []
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter);
      return titles.reduce(
        (obj, title, index) => ((obj[title] = parseFloat(values[index])), obj),
        {}
      )
    })
}

export const CSVToJSON2 = (data, delimiter = ',', removeMark=false) => {
  const fnl = data.indexOf('\n')
  if (fnl + 1 === data.length)
    return []
  const titles = data.slice(0, fnl).split(delimiter).map(i => i.replace(/\"/gi, ''))
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter).map(i => parseFloat(i.replace(/\"/gi, '')));
      return titles.reduce(
        (obj, title, index) => ((obj[title] = values[index]), obj),
        {}
      )
    })
}

export const mergeGeoJSON = (geojsonList) => {
  if (!Array.isArray(geojsonList) || geojsonList.length === 0) {
    return null
  }
  const mergedFeatures = []
  geojsonList.forEach(geojson => {
    if (geojson && geojson.features && Array.isArray(geojson.features)) {
      mergedFeatures.push(...geojson.features)
    }
  })
  const mergedGeoJSON = {
    type: "FeatureCollection",
    features: mergedFeatures
  }
  return mergedGeoJSON
}

const getLink = async (query) => {
  const data = await http.get(`/maestros/shapefile/descarga?${query}`)
  return data
}

const getGeoJSON = async (links=[]) => {
  const requests = links.map(i => axios.get(i))
  const responses = await Promise.all(requests)
  const jsons = responses.map(i => i.data)
  return mergeGeoJSON(jsons)
}

export const getShapes = async (zafra=2022, finca=null, um=[]) => {
  if (um.length) {
    const reqLinks = um.map(i => getLink(`Zafra=${zafra}&Um=${i}`))
    const resLinks = await Promise.all(reqLinks)
    return await getGeoJSON(resLinks)
  }
  if (finca) {
    const link = await getLink(`Zafra=${zafra}&Finca=${finca}`)
    return await getGeoJSON([link])
  }
  const link = await getLink(`Zafra=${zafra}`)
  return await getGeoJSON([link])
}

export const dividirArrayEnGrupos = (arr, n) => {
  var grupos = []
  for (var i = 0; i < arr.length; i += n) {
    grupos.push(arr.slice(i, i + n))
  }
  return grupos
}

export const getDatosEnTiempoReal = async (deviceList=[], tipoDatos='ubicacion') => {
  const chunks = dividirArrayEnGrupos(deviceList, 10)
  const requests = chunks.map(i => http.post('/maestros/catalogo/live', {
    Maquinas: i,
    TipoDatos: tipoDatos
  }))
  const responses = await Promise.all(requests)
  return [].concat(...responses)
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const getLocation = async () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
        {
          enableHighAccuracy: true,
          timeout: 10000,
        }
      )
    })
  } else {
    return null
  }
}

export const getSettings = () => {
  const settings = JSON.parse(localStorage.getItem('settings'))
  return settings || DefaultSettings
}
