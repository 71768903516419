import React, { Fragment, useState } from 'react';
import { Grid, Typography, Collapse, makeStyles } from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
    return {
        gridContainer: {
            display: 'flex',
            width: '100%',
            borderBottom: '2px solid #f6f6f6',
            padding: '1rem 1rem'
        },
        filtersContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        titulo: {
            fontWeight: "bold"
        },
        btn: {
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        collapse: { width: "100%" },
        drawerOption: { color: theme.palette.primary.main },
    };
});

const FilterDivider = ({ children, titulo, collapsable }) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);

    const toogleCollapse = () => {
        setCollapse(!collapse)
    }

    return (
        <Grid container className={classes.gridContainer}>
            {collapsable ? (
                <Fragment>
                    <div onClick={toogleCollapse} className={classes.btn}>
                        <Typography variant="subtitle1" className={classes.titulo}>{titulo || 'Filtros'}</Typography>
                        {collapse
                            ? <ExpandLess className={classes.drawerOption} />
                            : <ExpandMore className={classes.drawerOption} />
                        }
                    </div>
                    <Collapse in={collapse} timeout="auto" className={classes.collapse} unmountOnExit>
                        <div className="filtros-seccion">
                            {children}
                        </div>
                    </Collapse>
                </Fragment>
            ) : (
                <Fragment>
                    <Typography variant="subtitle1" className={classes.titulo}>{titulo || 'Filtros'}</Typography>
                    <div className="filtros-seccion">
                        {children}
                    </div>
                </Fragment>
            )}
        </Grid>
    )
}

export default FilterDivider;