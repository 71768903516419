import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout/layout'
import http from '../../Utils/http'
import {
  LinearProgress,
  Box,
  Typography,
  Fab,
  Dialog,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Add, Edit} from '@material-ui/icons'
import { toast } from 'react-toastify'
import PermisoEdit from '../../components/Admin/PermisoEdit'
import { getIdentity } from '../../Utils/auth'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'

const useStyles = makeStyles(theme => ({
  fab: {
    background: green[500],
    color: '#FFF'
  },
  title: {
    padding: 5
  }
}))


const MantenimientoPermisos = () => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [dgCrear, setDgCrear] = useState(false)
  const [dgEditar, setDgEditar] = useState(false)
  const [selected, setSelected] = useState(null)
  const getItems = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/permiso')
      setItems(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const insertItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioCreo: getIdentity()
      }
      await http.post('/maestros/permiso', body)
    } catch (error) {
      throw error
    }
  }
  const updateItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioModifico: getIdentity()
      }
      await http.put(`/maestros/permiso/${selected.IdPermiso}`, body)
    } catch (error) {
      throw error
    }
  }
  const refresh = () => {
    getItems()
    setDgCrear(false)
    setDgEditar(false)
  }
  const editItem = (cellData) => {
    const { data } = cellData
    setSelected(data)
    setDgEditar(true)
  }
  useEffect(() => {
    getItems()
  }, [])
  const CustomCell = (cellData) => (
    <div>
      <IconButton onClick={() => editItem(cellData)} size="small">
        <Edit />
      </IconButton>
    </div>
  )
  return <Layout title="Permisos">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.title}
    >
      <Typography variant="h5">Permisos</Typography>
      <Fab
        size="small"
        color="inherit"
        className={classes.fab}
        onClick={() => setDgCrear(true)}
      >
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <DataGrid id="gridPermisos"
      dataSource={items}
      showBorders
      showColumnLines
      rowAlternationEnabled
    >
      <Column caption="Id" dataField="IdPermiso" />
      <Column caption="Permiso" dataField="Nombre" />
      <Column caption="Clave" dataField="Clave" />
      <Column caption="Opciones" cellRender={CustomCell} />
    </DataGrid>
    <Dialog open={dgCrear}>
      <PermisoEdit
        title="Crear permiso"
        onClose={() => setDgCrear(false)}
        onSubmit={insertItem}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog open={dgEditar}>
      <PermisoEdit
        title="Editar permiso"
        onClose={() => setDgEditar(false)}
        onSubmit={updateItem}
        onRefresh={refresh}
        values={selected}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoPermisos
