import { action } from 'easy-peasy';

// Actions
const setLoading = action((state, payload) => {
  state.isLoading = payload;
});

const setData = action((state, payload) => {
  state.data = payload;
});

const setDetalle = action((state, payload) => {
  state.detalle = payload;
});

const setPage = action((state, payload) => {
  state.page = payload;
});

const usuarios = {
  isLoading: false,
  data: {
    total: 0,
    results: [],
    pages: 1
  },
  page: 1,
  detalle: {},
  setLoading,
  setData,
  setPage,
  setDetalle
};

export default usuarios;