import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import api from '../../Utils/api';
import './styles.css';

const Map = ({
    filters,
    data,
    colors,
    showLayers,
    isLotes,
    customLabels,
    setLoading,
}) => {
    const svgRef = useRef();
    const projRef = useRef();
    const pathRef = useRef();

    const [shapes, setShapes] = useState([]);
    const [legendas, setLegendas] = useState([]);
    const [layers, setLayers] = useState([]);
    const [textLabels, setTextLabels] = useState([]);

    const initRefs = (center) => {
        const height = svgRef.current.clientHeight;
        const width = svgRef.current.clientWidth;

        projRef.current = d3.geoMercator()
            .center(center)
            .scale(900000)
            .translate([width / 2, height / 2])

        pathRef.current = d3.geoPath().projection(projRef.current)
        const map = d3.select("#map")
        const shapes = d3.select("#shapes")
        const zoom_actions = (ev) => {
            shapes.attr("transform", ev.transform);
        }
        const zoom_handler = d3.zoom().on("zoom", zoom_actions)
        zoom_handler(map)
    }

    const getShapes = () => {
        const data = {}
        if (filters.zona !== null) data.zona = filters.zona;
        if (filters.finca !== null) data.finca = filters.finca;
        if (filters.ums.length) data.ums = filters.ums;
        api.post('shapes', data).then(data => {
            initRefs(data.center);
            const shape = data.features.map(i => {
                const proj = projRef.current([i.properties.Longitude, i.properties.Latitude]);
                return ({
                    label: i.properties.Label,
                    x: proj[0],
                    y: proj[1]
                })
            });
            setShapes(data.features.map(i => `${pathRef.current(i)}`));
            setLegendas(shape);
        })
    }

    const getLayers = () => {
        const _layers = [];
        const capas = data || [];
        for (const item of capas) {
            if (item.features) {
                _layers.push(item.features.map(i => `${pathRef.current(i)}`))
            } else {
                _layers.push([])
            }
        }
        setLayers(_layers);
    }

    useEffect(() => {
        if (filters) {
            getShapes();
        }
    }, [filters])

    useEffect(() => {
        if (data && projRef && projRef.current) {
            getLayers();
        }
    }, [data])
    
    useEffect(() => {
        if (isLotes && customLabels && customLabels.length && projRef && projRef.current) {
            const _lables = customLabels.map(item => {
                const proj = projRef.current([item.Longitude, item.Latitude]);
                return ({
                    label: item.Label,
                    x: proj[0],
                    y: proj[1]
                })

            })
            setTextLabels(_lables);
        }
    }, [customLabels, projRef])

    const showLayer = (index) => {
        if (showLayers && showLayers.length)
            return showLayers[index]
        return true;
    }

    return (
        <Fragment>
            <svg id="map" ref={svgRef}>
                <g id="shapes">
                    {shapes.map((i, idx) =>
                        <path
                            key={idx}
                            d={i}
                            fill="#f4f6fa"
                            stroke="#000"
                        />
                    )}
                    {layers.map((layer, index) => {
                        if (showLayer(index)) {
                            return (
                                layer.map((i, idx) =>
                                    <path
                                        key={idx}
                                        d={i}
                                        fill={colors[index] || "#fffd98"}
                                    />
                                )
                            )
                        }
                    })}
                    {legendas.map((i, idx) =>
                        <text key={idx} x={i.x} y={i.y} fontFamily="Verdana" fontSize={'6'} fill="black">
                            {i.label}
                        </text>
                    )}
                    {isLotes && ( textLabels.map((i, idx) => 
                        <text key={idx} x={i.x} y={i.y} fontFamily="Verdana" fontSize={'4'} fill="black">
                            {i.label}
                        </text>
                    ))}
                </g>
            </svg>
        </Fragment>
    )
}

export default Map;
