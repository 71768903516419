import React, { useState, useEffect, useCallback } from 'react'
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent
} from '@material-ui/core'
import { List } from 'devextreme-react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import LoadingButton from '../Utils/LoadingButton'
import { toast } from 'react-toastify'
import http from '../../Utils/http'
import { getIdentity } from '../../Utils/auth'

const RolPermisosEdit = ({ values, onClose, onSubmit, onRefresh }) => {
  const {listaPermisos} = useStoreState(state => ({
    listaPermisos: state.listaPermisos
  }))
  const {getListaPermisos} = useStoreActions(actions => ({
    getListaPermisos: actions.getListaPermisos
  }))
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const handleSelectionChange = useCallback(({ name, value }) => {
    if (name === 'selectedItemKeys') {
      setSelected(value)
    }
  })
  const submit = async () => {
    try {
      setLoading(true)
      const activos = values.Permisos.map(i => i.IdPermiso) // Permisos asignados
      const checked = listaPermisos.filter(i => selected.includes(i.IdPermiso)) // Permisos seleccionados
      const nuevos = checked.filter(i => !activos.includes(i.IdPermiso)).map(i => i.IdPermiso) // Permisos por insertar
      const unchecked = activos.filter(i => !selected.includes(i) && !nuevos.includes(i)) // Permisos que se deseleccionaron
      const borrar = values.Permisos.filter(i => unchecked.includes(i.IdPermiso)) // Permisos por borrar
      const requests_borrar = borrar
        .map(i => http.delete(`/maestros/rol_permiso/${i.IdRolPermiso}`,{
          data: {UsuarioModifico: getIdentity()}
        }))
      const requests_crear = nuevos.map(i => http.post('/maestros/rol_permiso', {
        IdRol: values.IdRol,
        IdPermiso: i,
        UsuarioCreo: getIdentity()
      }))
      await Promise.all(requests_borrar)
      await Promise.all(requests_crear)
      onRefresh()
      toast.success('Registros guardados')
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!listaPermisos.length) {
      getListaPermisos()
    } else {
      const ids = values.Permisos.map(i => i.IdPermiso)
      const checked = listaPermisos.filter(i => i.Activo)
        .map(i => i.IdPermiso)
        .filter(i => ids.includes(i))
      setSelected(checked)
    }
  }, [listaPermisos])
  return <>
    <DialogTitle>Permisos por rol</DialogTitle>
    <DialogContent>
      <List
        dataSource={listaPermisos}
        selectionMode="multiple"
        selectedItemKeys={selected}
        selectByClick
        showSelectionControls={true}
        onOptionChanged={handleSelectionChange}
        keyExpr="IdPermiso"
        displayExpr="Nombre"
      >
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={submit}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default RolPermisosEdit
