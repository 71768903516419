import React from "react"
import { HashRouter as Router, Switch, Route } from "react-router-dom"
import { ProtectedRoute } from "./protected-route"
import { useStoreActions } from 'easy-peasy'
// =============================================================================
// CSS
// =============================================================================
import "react-datepicker/dist/react-datepicker.css"
import 'react-datasheet/lib/react-datasheet.css'

// =============================================================================
// Autenticacion y usuarios
// =============================================================================
import Login from "./pages/Login"
import { signOut } from "./Utils/auth"
import Cuenta from "./pages/Usuarios/Cuenta"
import NotFound from './pages/Extras/NotFound'
import Unauthorized from './pages/Extras/Unauthorized'
import Home from './pages/Home/Home'

// =============================================================================
// Aplicaciones aereas
// =============================================================================
// import ArchivosAereosCRUD from './pages/AplicacionesAereas/ArchivosAereosCRUD'
// import MapaAlturas from './pages/AplicacionesAereas/MapaAlturas'
// import MapaDosis from './pages/AplicacionesAereas/MapaDosis'
// import MapaAreaAplicada from './pages/AplicacionesAereas/MapaAreaAplicada'
// import MapaProductoAplicado from './pages/AplicacionesAereas/MapaProductoAplicado'
// import DashboardDosis from './pages/Dashboards/DashboardDosis'
// import DashboardHectareas from './pages/Dashboards/DashboardHectareas'
// import DashboardNave from "./pages/Dashboards/DashboardNave"
// import DashboardEficiencia from "./pages/Dashboards/DashboardEficiencia"
// import DashboardCostos from "./pages/Dashboards/DashboardCostos"
// import DashboardAreaAplicada from "./pages/Dashboards/DashboardAreaAplicada"
// import DashboardCostosHa from "./pages/Dashboards/DashboardCostosHa"

// =============================================================================
// Cosecha
// =============================================================================
// import ArchivosCosecha from './pages/Cosecha/ArchivosCosecha'
// import DashboardVelocidades from './pages/Dashboards/DashboardVelocidades'
// import DashboardPA from './pages/Dashboards/DashboardPA'
// import PilotosFrentes from './pages/Cosecha/PilotosFrentes'

// =============================================================================
// Fertilización
// =============================================================================
// import {
//   VelocidadFertilizacion,
//   FertilizacionCalidadGPS
// } from './pages/Fertilizacion';
// import { DosisRealFertilizacion, PAFertilizacion } from './pages/Fertilizacion';

// =============================================================================
// Control de plagas
// =============================================================================
// import { DataDashboard } from "./pages/Plagas"
// import {
//   AplicacionInsecticida,
//   AplicacionInsecticidaTerrestre,
//   Hongos,
//   LaboresCulturales
// } from './pages/Plagas'
// import DashboardChinche from './pages/Dashboards/Chinche'
// import DashboardPlagas from "./pages/Dashboards/DashboardPlagas"
// import MapaInfestacion from "./pages/Plagas/MapaInfestacion"
// import CargarBoletas from "./pages/Plagas/CargarBoletas"
// import PlagaForm from './pages/PlagaForm'
// import MapasGeograficos from "./pages/GeoSistemas/MapasGeograficos"
// import ArchivosFertilizacion from "./pages/GIO/ArchivosFertilizacion"
// import Variables from "./pages/GIO/Alarmas/Variables"
// import DispositivosGIO from "./pages/GIO/DispositivosGIO"
import GIODasboardV2 from "./pages/GIO/GIODasboardV2"
import MapasCosecha from "./pages/Cosecha/MapasCosecha"
import MapaTiempoReal from "./pages/GIO/MapaTiempoReal"
import TiposArchivo from "./pages/GIO/TiposArchivo"
import ArchivosTelemetria from "./pages/GIO/ArchivosTelemetria"
import DashboardOperaciones from "./pages/GIO/DashboardOperaciones"
import MapaFertilizacion from "./pages/Fertilizacion/MapaFertilizacion"
import MantenimientoGIO from "./pages/Maestros/MantenimientoGIO"
import MantenimientoMaquinaria from "./pages/Maestros/MantenimientoMaquinaria"
import MantenimientoRoles from "./pages/Admin/MantenimientoRoles"
import MantenimientoPermisos from "./pages/Admin/MantenimientoPermisos"
import MantenimientoTiposMaquinaria from "./pages/Admin/MantenimientoTiposMaquinaria"
import MantenimientoAreasAgricola from "./pages/Admin/MantenimientoAreasAgricola"
import MantenimientoClasificaciones from "./pages/Admin/MantenimientoClasificaciones"
import MantenimientoOperaciones from "./pages/Admin/MantenimientoOperaciones"
import MantenimientoUsuarios from "./pages/Admin/MatenimientoUsuarios"
import MantenimientoShapefiles from "./pages/Admin/MantenimientoShapefiles"
import MapasGIO from "./pages/GIO/MapasGIO"
import DashboardQuicksight from "./pages/GIO/DashboardQuicksight"
import MantenimientoExportar from "./pages/Exportar/MantenimientoExportar"

function App() {
  const { clearAuthHeader, setPermisos } = useStoreActions(actions => ({
    clearAuthHeader: actions.clearAuthHeader,
    setPermisos: actions.setPermisos
  }))
  const logingOut = async () => {
    clearAuthHeader()
    await signOut();
    const env = process.env.NODE_ENV;
    localStorage.removeItem('authData')
    setPermisos([])
    if (env === 'development') {
      window.location.href = "/";
    } else {
      window.location.href = "/web";
    }
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={logingOut} />
        <Route exact path="/home" component={Home} />
        <ProtectedRoute exact path="/cuenta" component={Cuenta} />

        {/* Aplicaciones aereas */}
        {/* <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/mapa-alturas"
          component={MapaAlturas}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/mapa-dosis"
          component={MapaDosis}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/archivos-aereos"
          component={ArchivosAereosCRUD}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/mapa-producto-aplicado"
          component={MapaProductoAplicado}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-dosis"
          component={DashboardDosis}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-hectareas"
          component={DashboardHectareas}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-nave"
          component={DashboardNave}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-eficiencia"
          component={DashboardEficiencia}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-costos"
          component={DashboardCostos}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-area"
          component={DashboardAreaAplicada}
        />
        <ProtectedRoute
          exact
          permiso="aplicaciones aereas"
          path="/dashboard-costos-ha"
          component={DashboardCostosHa}
        /> */}

        {/* Plagas */}
        {/* <Route exact path="/mapa-insecticida" component={AplicacionInsecticida} />
        <Route exact path="/mapa-insecticida-terrestre" component={AplicacionInsecticidaTerrestre} />
        <Route exact path="/mapa-hongos" component={Hongos} />
        <Route exact path="/mapa-labores-culturales" component={LaboresCulturales} />
        <Route exact path="/plaga-subir" component={PlagaForm} />
        <Route exact path="/plagas-infestacion" component={MapaInfestacion} />
        <Route exact path="/dashboard-chinche" component={DashboardChinche} />
        <Route exact path="/dashboard-plagas" component={DashboardPlagas} />
        <Route exact path="/data-plagas" component={DataDashboard} />
        <Route exact path="/data-infestacion" component={CargarBoletas} /> */}

        {/* Fertilización */}
        {/* <Route exact path="/mapa-fertilizacion-dosis" component={DosisRealFertilizacion} />
        <Route exact path="/mapa-fertilizacion-velocidades" component={VelocidadFertilizacion} />
        <Route exact path="/mapa-fertilizacion-calidad-gps" component={FertilizacionCalidadGPS} />
        <Route exact path="/mapa-fertilizacion-pa" component={PAFertilizacion} /> */}

        {/* Cosecha */}
        {/* <Route exact path="/cosecha-archivos" component={ArchivosCosecha} />
        <Route exact path="/cosecha-pilotos" component={PilotosFrentes} />
        <Route exact path="/area-aplicada" component={MapaAreaAplicada} />
        <Route exact path="/dashboard-velocidades" component={DashboardVelocidades} />
        <Route exact path="/dashboard-PA" component={DashboardPA} />
        <Route exact path="/data-operadores" component={Operadores} />
        <Route exact path="/archivos-fertilizacion" component={ArchivosFertilizacion} /> */}
        {/* <ProtectedRoute
          exact
          path="/gios"
          permiso="admin_gios"
          component={DispositivosGIO}
        /> */}
        <ProtectedRoute
          exact
          path="/mapas-cosecha"
          permiso={["mapa_cosecha", "anonimo"]}
          component={MapasCosecha}
        />

        {/* GIO */}
        <ProtectedRoute
          exact
          path="/mapa-ubicacion"
          permiso={["mapa_tiempo_real", "anonimo"]}
          component={MapaTiempoReal}
        />
        <ProtectedRoute
          exact
          path="/mapas-gio"
          permiso={["mapa_gio", "anonimo"]}
          component={MapasGIO}
        />
        <ProtectedRoute
          exact
          path="/dashboard-operaciones"
          permiso="admin_operaciones"
          component={DashboardQuicksight}
        />
        <ProtectedRoute
          exact
          path="/mapa-fertilizacion"
          permiso="mapa_fertilizacion"
          component={MapaFertilizacion}
        />
        <ProtectedRoute
          exact
          path="/admin/archivos-telemetria"
          permiso="admin_archivos"
          component={ArchivosTelemetria}
        />
        <ProtectedRoute
          exact
          path="/admin/tipos-archivos"
          permiso="admin_tipos_archivos"
          component={TiposArchivo}
        />
        <ProtectedRoute
          exact
          path="/admin/operaciones"
          permiso="admin_operaciones"
          component={MantenimientoOperaciones}
        />
        <ProtectedRoute
          exact
          path="/admin/gios"
          permiso="admin_gios"
          component={MantenimientoGIO}
        />
        <ProtectedRoute
          exact
          path="/admin/maquinaria"
          permiso="admin_maquinaria"
          component={MantenimientoMaquinaria}
        />
        <ProtectedRoute
          exact
          path="/admin/roles"
          permiso="autenticacion_roles"
          component={MantenimientoRoles}
        />
        <ProtectedRoute
          exact
          path="/admin/permisos"
          permiso="autenticacion_permisos"
          component={MantenimientoPermisos}
        />
        <ProtectedRoute
          exact
          path="/admin/usuarios"
          permiso="autenticacion_usuarios"
          component={MantenimientoUsuarios}
        />
        <ProtectedRoute
          exact
          path="/admin/tipos_maquinaria"
          permiso="admin_tipos_maquinaria"
          component={MantenimientoTiposMaquinaria}
        />
        <ProtectedRoute
          exact
          path="/admin/areas_agricola"
          permiso="admin_areas_agricolas"
          component={MantenimientoAreasAgricola}
        />
        <ProtectedRoute
          exact
          path="/admin/clasificaciones"
          permiso="admin_clasificaciones"
          component={MantenimientoClasificaciones}
        />
        <ProtectedRoute
          exact
          path="/admin/exportaciones"
          permiso="descarga_datos"
          component={MantenimientoExportar}
        />
        <ProtectedRoute
          exact
          path="/admin/shapefiles"
          permiso="admin_shapes"
          component={MantenimientoShapefiles}
        />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route path="*" component={NotFound} />
      </Switch >
    </Router >
  );
}

//export default withAuthenticator(App, true);
export default App;
