import React, { useEffect } from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SelectGIO = ({
    value,
    onChange,
    error=false,
    helperText='',
    label='',
    multiple=false,
    disabled=false
  }) => {
  const { gios } = useStoreState(state => ({
    gios: state.gios
  }))
  const { getGios } = useStoreActions(actions => ({
    getGios: actions.getGios
  }))
  useEffect(() => {
    getGios()
  }, [])
  return <FormControl
    fullWidth
  >
    <Autocomplete
      fullWidth
      multiple={multiple}
      options={gios}
      disabled={disabled}
      getOptionLabel={option => option['Codigo']}
      renderInput={params => <TextField
        {...params}
        fullWidth
        error={error}
        label={label}
        margin="none"
        helperText={helperText}
      />}
      value={value}
      onChange={onChange}
    />
  </FormControl>
}

export default SelectGIO